import { Ban, CheckCircle, Circle, RotateCcw } from "lucide-react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { checksHooks } from "../../../api";
import { ICheckOkNotOkViewModel } from "../../../application/viewModels/CheckOkNotOkViewModel";
import { OperatorRoutePath } from "../../../data";
import {
  CheckState,
  CommentType,
} from "../../../infrastructure/api/common/types";
import CheckStateCard, {
  ActionContainer,
  AnswerBadge,
  AnswerContainer,
  PrimaryActionButton,
  SecondaryActionButton,
} from "./CheckStateCard";
import CheckPopupBase from "./index";

interface ICheckPopupOkNotOkProps {
  check: ICheckOkNotOkViewModel;
  onFinish: () => void;
  signed: boolean;
  sectionTitle: string;
  checklistId: number;
  groupUid: string;
}

export const CheckPopupOkNotOk = observer(
  ({
    check,
    onFinish,
    signed,
    sectionTitle,
    checklistId,
    groupUid,
  }: ICheckPopupOkNotOkProps): JSX.Element => {
    const { mutate, isLoading: isLoadingValue } = checksHooks.usePut(
      "/checklists/groups/checks/ok-nok/value",
    );
    const { mutate: mutateInApplicable, isLoading: isLoadingInApplicable } =
      checksHooks.usePost("/checklists/groups/checks/ok-nok/in-applicable");

    const navigation = useNavigate();
    const [searchParams] = useSearchParams();
    const { t } = useTranslation();

    const handleAccept = async () => {
      mutate(
        {
          checkState: CheckState.Good,
          checklistId: checklistId,
          checkUid: check.uid,
          groupUid,
        },
        {
          onSuccess: async () => {
            await check.accept();

            onFinish();
          },
        },
      );
    };

    function handleReset() {
      mutate(
        {
          checkState: CheckState.Empty,
          checklistId: checklistId,
          checkUid: check.uid,
          groupUid,
        },
        {
          onSuccess: async () => {
            check.reset();
            onFinish();
          },
        },
      );
    }

    const handleReject = async () => {
      navigation(
        `${OperatorRoutePath.CheckActivityBase}/${
          CommentType.Rejected
        }/?projectId=${searchParams.get("projectId")}&check-popup=open`,
      );
    };

    const handleRecover = async () => {
      navigation(
        `${OperatorRoutePath.CheckActivityBase}/${
          CommentType.Recovered
        }/?projectId=${searchParams.get("projectId")}&check-popup=open`,
      );
    };

    function handleCheckIsInApplicable() {
      mutateInApplicable(
        {
          checklistId: checklistId,
          checkUid: check.uid,
          groupUid,
        },

        {
          onSuccess: async () => {
            // await check.accept();
            check.handleInApplicable();

            onFinish();
          },
        },
      );
    }

    return (
      <CheckPopupBase
        activities={check.activities}
        instructionId={check.instructionId}
        signed={signed}
        check={check}
      >
        <CheckStateCard
          signed={signed}
          check={check}
          sectionTitle={sectionTitle}
          isLoading={isLoadingValue || isLoadingInApplicable}
        >
          {check.state !== CheckState.Empty && (
            <AnswerContainer>
              {check.state === CheckState.Good && (
                <AnswerBadge
                  lastTouchedBy={check.lastTouchedBy}
                  lastTouchedAt={check.lastTouchedAt}
                  checklistAccepted={signed}
                  onReset={handleReset}
                >
                  <CheckCircle className="w-5 h-5 mr-2" />
                  {t("inspectionPage.checkPopup.okNokCheck.accepted")}
                </AnswerBadge>
              )}

              {check.state === CheckState.InApplicable && (
                <AnswerBadge
                  lastTouchedBy={check.lastTouchedBy}
                  lastTouchedAt={check.lastTouchedAt}
                  checklistAccepted={signed}
                  onReset={handleReset}
                >
                  <Circle className="w-5 h-5 mr-2" />
                  {t("inspectionPage.checkPopup.inApplicableCheckButton")}
                </AnswerBadge>
              )}

              {check.state === CheckState.Bad && (
                <AnswerBadge
                  lastTouchedBy={check.lastTouchedBy}
                  lastTouchedAt={check.lastTouchedAt}
                  checklistAccepted={signed}
                  deleteLabel="Herstellen"
                  onReset={handleRecover}
                >
                  <Ban className="w-5 h-5 mr-2" />
                  {t("inspectionPage.checkPopup.okNokCheck.rejected")}
                </AnswerBadge>
              )}

              {check.state === CheckState.Recovered && (
                <AnswerBadge
                  canDelete={false}
                  lastTouchedBy={check.lastTouchedBy}
                  lastTouchedAt={check.lastTouchedAt}
                  checklistAccepted={signed}
                  onReset={() => check.reset()}
                >
                  <RotateCcw className="w-5 h-5 mr-2" />
                  {t("inspectionPage.checkPopup.okNokCheck.recovered")}
                </AnswerBadge>
              )}
            </AnswerContainer>
          )}

          {check.state !== CheckState.Bad &&
            !signed &&
            check.state === CheckState.Empty && (
              <div className="w-full">
                <ActionContainer>
                  <PrimaryActionButton
                    onClick={handleAccept}
                    disabled={check.loading}
                  >
                    {t("inspectionPage.checkPopup.okNokCheck.acceptButton")}
                  </PrimaryActionButton>
                  <SecondaryActionButton
                    onClick={handleReject}
                    disabled={check.loading}
                  >
                    {t("inspectionPage.checkPopup.okNokCheck.rejectButton")}
                  </SecondaryActionButton>
                </ActionContainer>
                {check.canBeInApplicable && (
                  <div className="px-4 pb-4">
                    <div className="border-t-2 border-dashed pt-4">
                      <SecondaryActionButton
                        onClick={handleCheckIsInApplicable}
                        disabled={check.loading}
                      >
                        {t("inspectionPage.checkPopup.inApplicableCheckButton")}
                      </SecondaryActionButton>
                    </div>
                  </div>
                )}
              </div>
            )}
        </CheckStateCard>
      </CheckPopupBase>
    );
  },
);
