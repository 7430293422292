import { HubConnection } from "@microsoft/signalr";
import { ActivityEntity } from "../../core/domain/entities/checklist/ActivityEntity";
import { ChecklistEntity } from "../../core/domain/entities/checklist/ChecklistEntity";
import { ChecklistSearchItemEntity } from "../../core/domain/entities/checklist/ChecklistSearchItemEntity";
import { PhotoEntity } from "../../core/domain/entities/checklist/PhotoEntity";
import { WorkInstructionEntity } from "../../core/domain/entities/checklist/WorkInstructionEntity";
import { IContentResult, IResult } from "../../core/results/Result";
import {
  SuccessfulContentResult,
  SuccessfulResult,
} from "../../core/results/successful/SuccessfulResult";
import {
  UnsuccessfulContentResult,
  UnsuccessfulResult,
} from "../../core/results/unsuccessful/UnsuccessfulResult";
import { myContainer } from "../../inversify.config";
import { CommentType } from "../api/common/types";
import {
  ICreateChecklistRequest,
  ICreateCommentPhotoRequest,
  ICreateCommentRequest,
  IGetChecklistsByPartialSerialNumberRequest,
  IGetWorkInstructionByIdRequest,
  ISetCheckValueBaseRequest,
  ISignChecklistRequest,
  ISignUndoChecklistRequest,
} from "../api/endpoints/common/request.types";
import { ISetCheckValueResponse } from "../api/endpoints/common/response.types";
import { ChecklistMapper } from "../api/mappers/ChecklistMapper";
import { CommentMapper } from "../api/mappers/CommentMapper";
import { PhotoMapper } from "../api/mappers/PhotoMapper";
import { WorkInstructionMapper } from "../api/mappers/WorkInstructionMapper";
import { ChecklistService } from "../services/checklists/ChecklistService";
import { SignalRService } from "../services/signalR/SignalRService";

export class ChecklistRepository {
  private _service: ChecklistService = myContainer.get(ChecklistService);
  private _signalRService: SignalRService = myContainer.get(SignalRService);

  public async joinChecklist(
    id: number,
    updateCallback: (checklistEntity: ChecklistEntity) => void,
  ): Promise<IResult> {
    const startResult = await this._signalRService.start(
      `${process.env.REACT_APP_API_URL}/hubs/inspections`,
    );

    if (startResult.isNotSuccessful) {
      return new UnsuccessfulResult(startResult.failure);
    }

    const connectedResult = await this._signalRService.invoke(
      "JoinChecklist",
      `${id}`,
    );

    if (connectedResult.isNotSuccessful) {
      return new UnsuccessfulResult(connectedResult.failure);
    }

    this._signalRService.onCallback<any>("UpdateCheck", (check) => {
      updateCallback(check);
    });

    return connectedResult;
  }

  public async leaveChecklist(serial: string): Promise<IResult> {
    const connectedResult = await this._signalRService.invoke(
      "LeaveChecklist",
      serial,
    );

    if (connectedResult.isNotSuccessful) {
      return new UnsuccessfulResult(connectedResult.failure);
    }

    return connectedResult;
  }

  public async getById(
    uid: string,
    language?: string,
  ): Promise<IContentResult<ChecklistEntity>> {
    const response = await this._service.getById(uid, language);
    if (response.isNotSuccessful || !response.content)
      return new UnsuccessfulContentResult(response.failure);

    const checklist: ChecklistEntity = ChecklistMapper.fromDTO(
      response.content,
    );

    return new SuccessfulContentResult(checklist);
  }

  public async getBySerial(
    serial: string,
  ): Promise<IContentResult<ChecklistEntity>> {
    const response = await this._service.getBySerial(serial);
    if (response.isNotSuccessful || !response.content)
      return new UnsuccessfulContentResult(response.failure);

    const checklist: ChecklistEntity = ChecklistMapper.fromDTO(
      response.content,
    );

    return new SuccessfulContentResult(checklist);
  }

  public async getByTimestamp(
    amount: number,
  ): Promise<IContentResult<ChecklistSearchItemEntity[]>> {
    const response = await this._service.getByTimestamp(amount);

    if (response.isNotSuccessful || !response.content)
      return new UnsuccessfulContentResult(response.failure);

    const checklistSearches: ChecklistSearchItemEntity[] =
      response.content.results.map((c) =>
        ChecklistSearchItemEntity.existing(c),
      );

    return new SuccessfulContentResult(checklistSearches);
  }

  public async getByPartialSerialNumber(
    request: IGetChecklistsByPartialSerialNumberRequest,
  ): Promise<IContentResult<ChecklistSearchItemEntity[]>> {
    const response = await this._service.getByPartialSerialNumber(request);

    if (response.isNotSuccessful || !response.content)
      return new UnsuccessfulContentResult(response.failure);

    const checklistSearches: ChecklistSearchItemEntity[] =
      response.content.results.map((c) =>
        ChecklistSearchItemEntity.existing(c),
      );
    return new SuccessfulContentResult(checklistSearches);
  }

  public async checkSetValue<T>(
    request: ISetCheckValueBaseRequest<T>,
  ): Promise<IContentResult<ISetCheckValueResponse>> {
    return await this._service.checkSetValue(request);
  }

  public async createComment(
    checklistId: number,
    groupUid: string,
    checkUid: string,
    message: string,
  ): Promise<IContentResult<ActivityEntity>> {
    const request: ICreateCommentRequest = {
      checklistId,
      groupUid,
      checkUid,
      message,
    };

    const {
      isNotSuccessful,
      failure,
      content: { commentUid },
    } = await this._service.createComment(request);

    if (isNotSuccessful) return new UnsuccessfulContentResult(failure);

    //@ts-ignore
    const userId = window.Clerk.user.id;

    const activity = CommentMapper.fromDTO({
      uid: commentUid,
      message,
      author: {
        value: userId,
        type: 1,
      },
      photos: [],
      createdAt: `${new Date()}`,
      updatedAt: `${new Date()}`,
      type: CommentType.Info,
    });

    return new SuccessfulContentResult(activity);
  }

  public async createCommentPhoto(
    checklistUid: string,
    checklistId: number,
    groupUid: string,
    checkUid: string,
    commentUid: string,
    photos: File[],
  ): Promise<IContentResult<PhotoEntity[]>> {
    const request: ICreateCommentPhotoRequest = {
      checkUid,
      checklistId,
      commentUid,
      photos,
      groupUid,
    };

    const response = await this._service.createCommentPhoto(request);
    if (response.isNotSuccessful || !response.content)
      return new UnsuccessfulContentResult(response.failure);

    const photosEntities: PhotoEntity[] = PhotoMapper.fromDTOs(
      response.content.results,
    );
    return new SuccessfulContentResult(photosEntities);
  }

  public async getInstructionById(
    request: IGetWorkInstructionByIdRequest,
  ): Promise<IContentResult<WorkInstructionEntity>> {
    const response = await this._service.getInstructionById(request);

    if (response.isNotSuccessful || !response.content) {
      return new UnsuccessfulContentResult(response.failure);
    }

    return new SuccessfulContentResult(
      WorkInstructionMapper.fromDTO(response.content),
    );
  }

  public async signChecklist(request: ISignChecklistRequest): Promise<IResult> {
    const response = await this._service.signChecklist(request);

    // if (response.isNotSuccessful) {
    //   return new UnsuccessfulResult(response.failure);
    // }

    return new SuccessfulResult();
  }

  public async signUndoChecklist(
    request: ISignUndoChecklistRequest,
  ): Promise<IResult> {
    const response = await this._service.signUndoChecklist(request);

    if (response.isNotSuccessful) {
      return new UnsuccessfulResult(response.failure);
    }

    return new SuccessfulResult();
  }

  public async createChecklist(
    serialNumber: string,
    productLabel: string,
  ): Promise<IContentResult<string>> {
    const request: ICreateChecklistRequest = {
      productLabel: productLabel,
      serialNumber: serialNumber,
    };

    const response = await this._service.createChecklist(request);

    if (response.isNotSuccessful || !response.content) {
      return new UnsuccessfulContentResult(response.failure);
    }

    const serial: string = response.content.checklistSerialNumber;

    return new SuccessfulContentResult(serial);
  }

  get signalRConnection(): HubConnection | undefined {
    return this._signalRService._connection;
  }
}
