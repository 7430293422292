import { makeObservable, observable, runInAction } from "mobx";
import { CheckBaseViewModel, ICheckBaseViewModel } from "./CheckBaseViewModel";

import { ActivityEntity } from "../../core/domain/entities/checklist/ActivityEntity";
import { PhotoEntity } from "../../core/domain/entities/checklist/PhotoEntity";
import { CheckOkNotOkEntity } from "../../core/domain/entities/checklist/check/CheckOkNotOkEntity";
import { CheckState, CommentType } from "../../infrastructure/api/common/types";
import { ActivityViewModel } from "./ActivityViewModel";
import { PhotoViewModel } from "./PhotoViewModel";

export interface ICheckOkNotOkViewModel extends ICheckBaseViewModel {
  rejectionMessages: { [x: string]: string };
  manualRejectionMessagesAllowed: boolean;

  accept: () => Promise<void>;
  reject: (
    commentUid: string,
    checklistId: number,
    message: string,
    photos?: File[],
  ) => Promise<void>;
  recover: (
    commentUid: string,
    checklistId: number,
    message: string,
    photos?: File[],
  ) => Promise<void>;
  reset: () => Promise<void>;
  handleInApplicable: () => Promise<void>;
}

export enum CheckTypes {
  okNotOk = 1,
  inputNumber,
  yesNo,
  inputSingleLine,
  scan,
  checklist,
  singleOption,
  signature = 8,
  photo = 9,
}

export enum ChecklistCheckYesNoStates {
  idle,
  yes,
  no,
}

export enum ChecklistCheckScanStates {
  idle,
  hasValue,
}

export enum ChecklistCheckInputStates {
  idle,
  hasValue,
}

export class CheckOkNotOkViewModel
  extends CheckBaseViewModel
  implements ICheckOkNotOkViewModel
{
  public rejectionMessages: { [x: string]: string } = {};
  public manualRejectionMessagesAllowed: boolean = false;
  public selectedRejectionMessage: string | null = null;

  constructor(
    check: CheckOkNotOkEntity,
    checklistUid: string,
    groupUid: string,
  ) {
    super(check, checklistUid, groupUid);

    this.rejectionMessages = check.rejectionMessages;
    this.manualRejectionMessagesAllowed = check.isUserRejectionMessageAllowed;
    this.selectedRejectionMessage =
      this.rejectionMessages && check.selectedRejectionMessage
        ? this.rejectionMessages[check.selectedRejectionMessage ?? ""]
        : null;

    this.finished = this.isFinished();

    makeObservable(this, {
      rejectionMessages: observable,
      manualRejectionMessagesAllowed: observable,
    });
  }

  public async accept(): Promise<void> {
    runInAction(() => {
      this.state = CheckState.Good;
      this.finished = true;

      const x = { state: { selectedUser: { id: "" } } };

      const userId = //@ts-ignore
        window.Clerk.user.id === process.env.REACT_APP_ADMIN_USER_ID
          ? JSON.parse(
              localStorage.getItem("user-storage") || JSON.stringify(x),
            ).state.loggedInUser?.userId
          : //@ts-ignore
            window.Clerk.user.id;

      this.lastTouchedBy = {
        value: userId,
        type: 1,
      };
      this.lastTouchedAt = new Date();
    });

    // const tempState = this.state;

    // await this.update(
    //   {
    //     checkState: CheckState.Good,
    //   },
    //   () => {
    //     this.state = CheckState.Good;
    //   },
    //   () => {
    //     this.state = tempState;
    //   },
    // );
  }

  public async reject(
    commentUid: string,
    checklistId: number,
    message: string,
    photos?: File[],
  ): Promise<void> {
    const tempState = this.state;

    //TODO-translations: this should be cleaner, message is now compared message should actually be the key.
    const rejectionMessageIndex = Object.values(
      this.rejectionMessages,
    ).findIndex((x) => x === message);

    const rejectionMessageKey = Object.keys(this.rejectionMessages)[
      rejectionMessageIndex
    ];

    const request = rejectionMessageKey
      ? {
          checkState: CheckState.Bad,
          selectedRejectionMessage: rejectionMessageKey,
        }
      : {
          checkState: CheckState.Bad,
          message,
        };

    if (photos && photos.length > 0) {
      await this.addPhotosToComment(checklistId, commentUid, photos);
    }

    //note: this fixes a race condition where signalR already added the new activity so adding it here is not needed.
    if (this.activities.find((a) => a.uid === commentUid)) {
      return;
    }

    runInAction(() => {
      this.state = CheckState.Bad;

      const x = { state: { selectedUser: { id: "" } } };

      const userId = //@ts-ignore
        window.Clerk.user.id === process.env.REACT_APP_ADMIN_USER_ID
          ? JSON.parse(
              localStorage.getItem("user-storage") || JSON.stringify(x),
            ).state.loggedInUser?.userId
          : //@ts-ignore
            window.Clerk.user.id;

      this.lastTouchedBy = {
        value: userId,
        type: 1,
      };
      this.lastTouchedAt = new Date();

      const activityViewModel = new ActivityViewModel(
        ActivityEntity.new({
          message,
          author: null,
          commentType: CommentType.Rejected,
          createdAt: `${new Date()}`,
          updatedAt: `${new Date()}`,
          photos:
            photos?.map((p) => PhotoEntity.new(URL.createObjectURL(p))) ?? [],
        }),
      );

      this.activities = [
        {
          ...activityViewModel,
          photos:
            photos?.map(
              (p) =>
                new PhotoViewModel(PhotoEntity.new(URL.createObjectURL(p))),
            ) ?? [],
        },
        ...this.activities,
      ];
    });

    // await this.update<ISetCheckValueOkNotOkRequest>(
    //   request,
    //   () => {
    //     this.state = CheckState.Bad;

    //     runInAction(() => {
    //       const activityViewModel = new ActivityViewModel(
    //         ActivityEntity.new({
    //           message,
    //           author: null,
    //           commentType: CommentType.Rejected,
    //           createdAt: `${new Date()}`,
    //           updatedAt: `${new Date()}`,
    //           photos:
    //             photos?.map((p) => PhotoEntity.new(URL.createObjectURL(p))) ??
    //             [],
    //         }),
    //       );

    //       this.activities = [
    //         {
    //           ...activityViewModel,
    //           photos:
    //             photos?.map(
    //               (p) =>
    //                 new PhotoViewModel(PhotoEntity.new(URL.createObjectURL(p))),
    //             ) ?? [],
    //         },
    //         ...this.activities,
    //       ];
    //     });
    //   },
    //   () => {
    //     this.state = tempState;
    //   },
    // );
  }

  public async recover(
    commentUid: string,
    checklistId: number,
    message: string,
    photos?: File[],
  ): Promise<void> {
    if (photos && photos.length > 0) {
      await this.addPhotosToComment(checklistId, commentUid, photos);
    }

    //note: this fixes a race condition where signalR already added the new activity so adding it here is not needed.
    if (this.activities.find((a) => a.uid === commentUid)) {
      return;
    }

    runInAction(() => {
      this.state = CheckState.Recovered;
      this.finished = true;

      const x = { state: { selectedUser: { id: "" } } };

      const userId = //@ts-ignore
        window.Clerk.user.id === process.env.REACT_APP_ADMIN_USER_ID
          ? JSON.parse(
              localStorage.getItem("user-storage") || JSON.stringify(x),
            ).state.loggedInUser?.userId
          : //@ts-ignore
            window.Clerk.user.id;

      this.lastTouchedBy = {
        value: userId,
        type: 1,
      };
      this.lastTouchedAt = new Date();

      const activityViewModel = new ActivityViewModel(
        ActivityEntity.new({
          message,
          author: null,
          commentType: CommentType.Recovered,
          createdAt: `${new Date()}`,
          updatedAt: `${new Date()}`,
          photos:
            photos?.map((p) => PhotoEntity.new(URL.createObjectURL(p))) ?? [],
        }),
      );

      this.activities = [
        {
          ...activityViewModel,
          photos:
            photos?.map(
              (p) =>
                new PhotoViewModel(PhotoEntity.new(URL.createObjectURL(p))),
            ) ?? [],
        },
        ...this.activities,
      ];
    });

    // const tempState = this.state;

    // await this.update<ISetCheckValueOkNotOkRequest>(
    //   {
    //     checkState: CheckState.Recovered,
    //     message,
    //   },
    //   () => {
    //     this.state = CheckState.Recovered;

    //     runInAction(() => {
    //       const activityViewModel = new ActivityViewModel(
    //         ActivityEntity.new({
    //           message,
    //           author: null,
    //           commentType: CommentType.Recovered,
    //           createdAt: `${new Date()}`,
    //           updatedAt: `${new Date()}`,
    //           photos:
    //             photos?.map((p) => PhotoEntity.new(URL.createObjectURL(p))) ??
    //             [],
    //         }),
    //       );

    //       this.activities = [
    //         {
    //           ...activityViewModel,
    //           photos:
    //             photos?.map(
    //               (p) =>
    //                 new PhotoViewModel(PhotoEntity.new(URL.createObjectURL(p))),
    //             ) ?? [],
    //         },
    //         ...this.activities,
    //       ];
    //     });
    //   },
    //   () => {
    //     this.state = tempState;
    //   },
    // );
  }

  public async reset(): Promise<void> {
    runInAction(() => {
      this.state = CheckState.Empty;
      this.finished = false;
      this.lastTouchedBy = null;
    });
  }
}
