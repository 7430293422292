import { ChecklistRepository } from "../../../infrastructure/repositories/ChecklistRepository";
import { PhotoEntity } from "../../domain/entities/checklist/PhotoEntity";
import { CommentEntity } from "../../domain/entities/checklist/check/CommentEntity";
import { IContentResult } from "../../results/Result";
import { UnsuccessfulContentResult } from "../../results/unsuccessful/UnsuccessfulResult";

export class CreateCommentPhotosCommand {
  private _checklistRepository: ChecklistRepository = new ChecklistRepository();

  public async handle(
    checklistUid: string,
    checklistId: number,
    groupUid: string,
    checkUid: string,
    commentUid: string,
    photos: File[],
  ): Promise<IContentResult<PhotoEntity[]>> {
    const checklistResult = await this._checklistRepository.getById(
      `${checklistId}`,
    );

    if (checklistResult.isNotSuccessful) {
      return new UnsuccessfulContentResult<PhotoEntity[]>(
        checklistResult.failure,
      );
    }

    const activity: CommentEntity | undefined = checklistResult.content?.groups
      .find((s) => s.uid === groupUid)
      ?.checks.find((c) => c.uid === checkUid)
      ?.activities.find((a) => a.uid === commentUid);

    if (!activity) throw Error("Comment does not exist in this checklist.");

    return await this._checklistRepository.createCommentPhoto(
      checklistUid,
      checklistId,
      groupUid,
      checkUid,
      commentUid,
      photos,
    );
  }
}
