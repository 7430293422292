import { Circle, Loader2, QrCode } from "lucide-react";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { checksHooks2 } from "../../../api";
import { ICheckScanViewModel } from "../../../application/viewModels/CheckScanViewModel";
import { CheckState } from "../../../infrastructure/api/common/types";
import { useScanner } from "../../hooks/useMemor10Scanner";
import ScannerPopup from "../../molecules/ScannerPopup";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../ui/dialog";
import { Input } from "../../ui/input";
import CheckStateCard, {
  ActionContainer,
  AnswerBadge,
  AnswerContainer,
  PrimaryActionButton,
  SecondaryActionButton,
} from "./CheckStateCard";
import CheckPopupBase from "./index";

interface ICheckPopupScanProps {
  check: ICheckScanViewModel;
  onFinish: () => void;
  signed: boolean;
  sectionTitle: string;
  checklistId: number;
  groupUid: string;
}

export const CheckPopupScan = observer(
  ({
    check,
    onFinish,
    signed,
    sectionTitle,
    checklistId,
    groupUid,
  }: ICheckPopupScanProps): JSX.Element => {
    const { mutate, isLoading: isLoadingValue } = checksHooks2.usePut(
      "/checklists/groups/checks/barcode/value",
    );
    const { mutate: mutateReset, isLoading: isLoadingReset } =
      checksHooks2.usePut("/checklists/groups/checks/barcode/value/reset");
    const { mutate: mutateInApplicable, isLoading: isLoadingInApplicable } =
      checksHooks2.usePost("/checklists/groups/checks/barcode/in-applicable");

    const {
      scan,
      scanResult,
      scanMethod,
      reset,
      overrideScanMethod,
      setDefaultScanMethod,
      resetCamera,
      scanning,
    } = useScanner("checkPopupScanReader");
    const [scanActive, setScanActive] = useState(false);
    const [value, setValue] = useState<string | undefined>(undefined);

    const handleScannerScan = async () => {
      if (scanMethod === "text") {
        setScanActive(true);
        return;
      }

      if (scanMethod === "camera") {
        setScanActive(true);
      }

      try {
        await scan();
      } catch (e: unknown) {
        const { message } = e as Error;

        alert(`${message}`);
      }
    };

    const handleScan = async (value: string) => {
      mutate(
        {
          barcode: value,
          checklistId: checklistId,
          checkUid: check.uid,
          groupUid,
        },
        {
          onSuccess: async (data) => {
            console.log({ data });

            const state = (data as any).state;

            // await check.store(value, Number(state));

            await check.scan(value, Number(state));

            resetCamera();

            if (check.finished) {
              onFinish();
            }
          },
        },
      );
    };

    const handleReset = () => {
      mutateReset(
        {
          checklistId,
          checkUid: check.uid,
          groupUid,
        },
        {
          onSuccess: () => {
            reset();
            check.reset();
          },
        },
      );
    };

    const handleCloseDialog = () => {
      setScanActive(false);

      setDefaultScanMethod();
    };

    React.useEffect(() => {
      (async () => {
        if (scanResult && scanMethod === "memor10") {
          mutate(
            {
              barcode: value,
              checklistId: checklistId,
              checkUid: check.uid,
              groupUid,
            },
            {
              onSuccess: async (data) => {
                console.log({ data });

                const state = (data as any).state;

                // await check.store(value, Number(state));

                await check.scan(scanResult, Number(state));

                resetCamera();

                if (check.finished) {
                  onFinish();
                }
              },
            },
          );

          // await check.scan(scanResult);

          // if (check.finished) {
          //   onFinish();
          // }
        }
      })();
    }, [scanResult]);

    const { t } = useTranslation();

    function handleCheckIsInApplicable() {
      mutateInApplicable(
        {
          checklistId: checklistId,
          checkUid: check.uid,
          groupUid,
        },
        {
          onSuccess: async () => {
            check.handleInApplicable();

            onFinish();
          },
        },
      );
    }

    return (
      <CheckPopupBase
        activities={check.activities}
        instructionId={check.instructionId}
        signed={signed}
        check={check}
      >
        <CheckStateCard
          signed={signed}
          check={check}
          sectionTitle={sectionTitle}
          isLoading={isLoadingValue || isLoadingInApplicable || isLoadingReset}
        >
          {check.state === CheckState.InApplicable && (
            <AnswerContainer>
              <AnswerBadge
                lastTouchedBy={check.lastTouchedBy}
                lastTouchedAt={check.lastTouchedAt}
                checklistAccepted={signed}
                onReset={handleReset}
              >
                <Circle className="w-5 h-5 mr-2" />
                {t("inspectionPage.checkPopup.inApplicableCheckButton")}
              </AnswerBadge>
            </AnswerContainer>
          )}
          {check.barcode !== null &&
            check.state !== CheckState.InApplicable && (
              <AnswerContainer>
                {check.state === CheckState.Bad && check.barcode !== null ? (
                  <AnswerBadge
                    lastTouchedBy={check.lastTouchedBy}
                    lastTouchedAt={check.lastTouchedAt}
                    checklistAccepted={signed}
                    deleteLabel={t("inspectionPage.checkPopup.scanCheck.retry")}
                    onReset={handleReset}
                  >
                    <QrCode className="w-5 h-5 mr-2" />({check.barcode}){" "}
                    {t("inspectionPage.checkPopup.scanCheck.invalidInput")}
                  </AnswerBadge>
                ) : (
                  <AnswerBadge
                    lastTouchedBy={check.lastTouchedBy}
                    lastTouchedAt={check.lastTouchedAt}
                    checklistAccepted={signed}
                    onReset={handleReset}
                  >
                    <QrCode className="w-5 h-5 mr-2" />
                    {check.barcode}
                  </AnswerBadge>
                )}
              </AnswerContainer>
            )}

          {scanMethod === "camera" && (
            <ScannerPopup
              readerId={"checkPopupScanReader"}
              onManualInput={() => overrideScanMethod("text")}
              onDeleteResult={reset}
              open={scanActive}
              data={scanResult}
              onConfirm={() => {
                setScanActive(false);
                handleScan(scanResult ?? "");
              }}
              onClose={() => {
                resetCamera();
                setScanActive(false);
              }}
            />
          )}
          <Dialog
            open={scanActive && scanMethod === "text"}
            onOpenChange={(incoming) => {
              setScanActive(incoming);

              if (!incoming) {
                handleCloseDialog();
              }
            }}
          >
            <DialogContent className="sm:max-w-[425px]">
              <DialogHeader>
                <DialogTitle>
                  {t("inspectionPage.checkPopup.scanCheck.enterCode")}
                </DialogTitle>
                <DialogDescription>{check.title}</DialogDescription>
              </DialogHeader>
              <div className="grid gap-4 py-4 w-full">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();

                    if (value === undefined) return;

                    handleScan(value);

                    setValue(undefined);

                    handleCloseDialog();
                  }}
                >
                  <Input
                    autoFocus
                    className="w-full"
                    placeholder={t(
                      "inspectionPage.checkPopup.scanCheck.enterCode",
                    )}
                    type="text"
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                  />
                </form>
              </div>
              <DialogFooter></DialogFooter>
            </DialogContent>

            {!signed &&
              !check.finished &&
              check.barcode === null &&
              check.state !== CheckState.InApplicable && (
                <div className="w-full">
                  <ActionContainer>
                    <DialogTrigger asChild>
                      <PrimaryActionButton
                        disabled={scanning}
                        onClick={handleScannerScan}
                      >
                        {scanning && (
                          <Loader2 className="animate-spin w-4 h-4 mr-2" />
                        )}
                        {scanMethod === "text"
                          ? t(
                              "inspectionPage.checkPopup.scanCheck.scanButtonText",
                            )
                          : t(
                              "inspectionPage.checkPopup.scanCheck.scanButtonScan",
                            )}
                      </PrimaryActionButton>
                    </DialogTrigger>
                  </ActionContainer>
                  {check.canBeInApplicable && (
                    <div className="px-4 pb-4">
                      <div className="border-t-2 border-dashed pt-4">
                        <SecondaryActionButton
                          onClick={handleCheckIsInApplicable}
                          disabled={check.loading}
                        >
                          {t(
                            "inspectionPage.checkPopup.inApplicableCheckButton",
                          )}
                        </SecondaryActionButton>
                      </div>
                    </div>
                  )}
                </div>
              )}
          </Dialog>
        </CheckStateCard>
      </CheckPopupBase>
    );
  },
);
