import { makeObservable, observable, runInAction } from "mobx";
import { z } from "zod";
import { schemas } from "../../api/schema";
import { CheckPhotoEntity } from "../../core/domain/entities/checklist/check/CheckPhotoEntity";
import { CheckState } from "../../infrastructure/api/common/types";
import { CheckBaseViewModel, ICheckBaseViewModel } from "./CheckBaseViewModel";

export interface ICheckPhotoViewModel extends ICheckBaseViewModel {
  photos: { uid: string; uri: string }[] | null;
  minPhotoCount: number | null;
  maxPhotoCount: number | null;

  save: (
    results: { uid: string; uri: string }[],
    state: CheckState,
  ) => Promise<void>;
  removePhoto: (photoUid: string, state: CheckState) => Promise<void>;
  reset: () => Promise<void>;
}

export type PhotoCheck = z.infer<
  typeof schemas.Checklab_WebApi_Features_Inspections_Dtos_Checks_InspectionCheckDto
> & { signed: boolean };

export type PhotoCheckUpdateRequest = Omit<
  z.infer<typeof schemas.Checklist_Check_Photo_Add_Body>,
  "checklistUid" | "groupUid" | "checkUid"
>;

export class CheckPhotoViewModel
  extends CheckBaseViewModel
  implements ICheckPhotoViewModel
{
  public photos: { uid: string; uri: string }[] | null = null;
  public minPhotoCount: number | null = null;
  public maxPhotoCount: number | null = null;

  constructor(check: CheckPhotoEntity, checklistUid: string, groupUid: string) {
    super(check, checklistUid, groupUid);

    this.photos = check.photos;
    this.minPhotoCount = check.minPhotoCount;
    this.maxPhotoCount = check.maxPhotoCount;

    this.finished = this.isFinished();

    makeObservable(this, {
      photos: observable,
    });
  }

  public async save(
    photos: { uid: string; uri: string }[],
    state: CheckState,
  ): Promise<void> {
    runInAction(() => {
      this.photos = [...(this.photos ?? []), ...photos];
      this.state = state;
      this.finished = this.isFinished();

      //@ts-ignore
      const x = { state: { selectedUser: { id: "" } } };

      const userId = //@ts-ignore
        window.Clerk.user.id === process.env.REACT_APP_ADMIN_USER_ID
          ? JSON.parse(
              localStorage.getItem("user-storage") || JSON.stringify(x),
            ).state.loggedInUser?.userId
          : //@ts-ignore
            window.Clerk.user.id;

      this.lastTouchedBy = {
        value: userId,
        type: 1,
      };
      this.lastTouchedAt = new Date();
    });
  }

  public async reset(): Promise<void> {
    runInAction(() => {
      this.photos = [];
      this.finished = false;
      this.lastTouchedBy = null;
      this.state = CheckState.Empty;
    });
  }

  public async removePhoto(photoUid: string, state: CheckState): Promise<void> {
    this.photos = [...(this.photos ?? []).filter((p) => p.uid !== photoUid)];
    this.state = state;
    this.finished = this.isFinished();

    this.lastTouchedBy = null;
  }
}
