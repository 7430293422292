import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ICheckBaseViewModel } from "../../../application/viewModels/CheckBaseViewModel";
import { IChecklistSectionViewModel } from "../../../application/viewModels/SectionViewModel";
import { OperatorRoutePath } from "../../../data";
import { ChecklistContext } from "../../context/ChecklistManager";
import { SettingsContext } from "../../context/SettingsManager";
import { Check } from "../../molecules/Check/Check";
import ChecklistSection from "../../molecules/ChecklistSection";
import LayoutV2 from "../../molecules/layout-v2/layout-v2";
import Checklist from "../../organisms/Checklist";
import IoTPopup from "./IoTPopup";

export function ChecklistTemplate({
  projectId,
}: {
  projectId: number | undefined;
}) {
  const { checklist } = useContext(ChecklistContext);
  const { activeLocationIds } = useContext(SettingsContext);
  const [buttonPosY, setButtonPosY] = useState(175);
  const navigate = useNavigate();
  const { serialNumber } = useParams<{ serialNumber: string }>();

  const handleCheckClick = (
    e: React.MouseEvent<HTMLButtonElement> | undefined,
    check: ICheckBaseViewModel,
  ) => {
    setTimeout(() => {
      const el = document.querySelector("html");

      if (el === null) return;

      // alert(`${el.scrollTop} ${el.clientHeight} ${scrollTop + clientHeight}`);
      setButtonPosY(
        el.scrollTop > 175 ? el.scrollTop + el.clientHeight / 2 : 175,
      );

      navigate(
        `${OperatorRoutePath.CheckBase}/${check.uid}/?projectId=${projectId}&check-popup=open`,
      );
    }, 100);
  };

  function filterReturnZeroCheck(groups: IChecklistSectionViewModel[]) {
    const x = groups.filter((group) => {
      if (group.checks.length < 1) {
        return;
      }

      if (activeLocationIds.length < 1) {
        return group;
      }

      const locations = group.checks
        .map((group) => group.locations)
        .flat()
        .map((location) => location.id);

      if (
        activeLocationIds.filter((activeLocationId) =>
          locations.includes(activeLocationId),
        ).length > 0
      ) {
        return group;
      } else {
        return;
      }
    });

    return x.length === 0;
  }

  return (
    <LayoutV2>
      <Checklist
        checklist={checklist}
        serialNumber={checklist.serialNumber}
        groups={checklist.groups}
        getPercentage={checklist.getPercentage}
      >
        {(groups, allOpen, trackActiveCheck) => (
          <>
            {groups
              .filter((group) => {
                if (filterReturnZeroCheck(groups)) {
                  return group;
                }

                if (group.checks.length < 1) {
                  return;
                }

                if (activeLocationIds.length < 1) {
                  return group;
                }

                const locations = group.checks
                  .map((group) => group.locations)
                  .flat()
                  .map((location) => location.id);

                if (
                  activeLocationIds.filter((activeLocationId) =>
                    locations.includes(activeLocationId),
                  ).length > 0
                ) {
                  return group;
                } else {
                  return;
                }
              })
              .map((s, idxSection) => (
                <ChecklistSection
                  allOpen={allOpen}
                  trackActiveCheck={trackActiveCheck}
                  group={s}
                  key={s.uid}
                  title={s.title}
                  checks={s.checks}
                  groupIndex={idxSection}
                  // SF_Name(string):DisplayName(string):GroupIndex(number):CheckIndex(number):Repeatable(true|false)
                  // SF_Name:DisplayName:GroupIndex:CheckIndex:Repeatable
                >
                  {(checks) => (
                    <>
                      {checks
                        .filter((c) => {
                          if (filterReturnZeroCheck(groups)) {
                            return c;
                          }

                          if (activeLocationIds.length) {
                            return activeLocationIds.find((activeLocationId) =>
                              c.locations
                                //@ts-ignore
                                .map((l) => l.id)
                                ?.includes(activeLocationId),
                            );
                          } else {
                            return true;
                          }
                        })
                        .map((c, idx) => (
                          <>
                            {idxSection ===
                              Number(
                                checklist.specialFunctions.split(":")[2],
                              ) &&
                              idx ===
                                Number(
                                  checklist.specialFunctions.split(":")[3],
                                ) && (
                                <IoTPopup
                                  checklist={checklist}
                                  groupId={s.uid}
                                  checklistId={checklist.id}
                                />
                              )}

                            <Check
                              key={c.uid}
                              onClick={(e) => handleCheckClick(e, c)}
                              title={c.title}
                              type={c.type}
                              finished={c.finished}
                              requiredCheck={c.required}
                              isIoT={c.isIoT}
                              activities={c.activities}
                              uid={c.uid}
                              check={c}
                            />
                          </>
                        ))}
                    </>
                  )}
                </ChecklistSection>
              ))}
          </>
        )}
      </Checklist>
    </LayoutV2>
  );
}
