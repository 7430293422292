import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      projectsPage: {
        searchProjectLabel: "Search project...",
        projects: "Projects",
        recentInspections: "Recent inspections",
        scanBarcodeToOpenProject: "Scan a barcode to open a project",
      },
      projectPage: {
        projects: "Projects",
        created: "Created",
        lastUpdated: "Last updated",
        activities: "Activities",
        projectInformation: "Project information",
        allInspections: "All inspections",
        projectProgress: "Project progress",
        signed: "Signed",
        inspections: "Inspections",
        noChecklistsForThisProject:
          "There are no checklists for this project...",
        noSuggestionsForThisProject:
          "There are no suggestions for this project...",
        startChecklist: "Start checklist",
        optional: "Optional",
        required: "Required",
        finishedInspections: "finished inspections",
      },
      addActivityPage: {
        rejectionPoint: "Rejection point",
        title: "Add activity",
        activityType: "Activity type",
        other: "Other...",
        description: "Description",
        saveButton: "save",
        uploadPhotos: "Upload photos",
        commentTypeLabelsInfo: "Comment",
        commentTypeLabelsRejected: "Rejection",
        commentTypeLabelsRecovered: "Recovery",
      },
      settingsPage: {
        title: "Settings",
        developerSettings: "Developer Settings",
        openDataWedgeDebugger: "Open Data Wedge Debugger",

        groupChecks: "Group checks",
        groupChecksDescription: "Required, optional and finished",

        nextCheck: "To next check",
        nextCheckDescription: "Go to next check after completing a check",

        expandGroups: "Expand groups",
        expandGroupsDescription: "Expand all groups when opening a checklist",

        deviceIsMemor10Scanner: "Device is memor10",
        deviceIsMemor10ScannerDescription: "Use scanner functionality",

        deviceIsDataWedgeScanner: "Device is datawedge scannner",
        deviceIsDataWedgeScannerDescription: "Use scanner functionality",

        activeLocations: "Active locations",
      },
      inspectionPage: {
        disabledLocationFilterAlert:
          "This checklist contains no checks for the selected location(s). Therefore, the location filter is temporarily disabled, and all checks are displayed.",
        accepted: "Accepted",
        rejected: "Rejected",
        recovered: "Recovered",
        yes: "Yes",
        no: "No",
        signed: "Signed",
        tasks: "Tasks",
        signChecklist: "Sign Checklist",
        signedBy: "Signed by",
        unSign: "Unsign",
        allChecksFinished: "All checks are done. Sign Checklist?",
        checkPopup: {
          card: {
            doneBy: "Completed by",
            deleted: "Delete",
          },
          inApplicableCheckButton: "N/A",
          activities: "Activities",
          noActivities: "No activities...",
          addActivity: "Add activity",
          okNokCheck: {
            accepted: "Accepted",
            rejected: "Rejected",
            recovered: "Recovered",
            acceptButton: "Accept",
            rejectButton: "Reject",
            recoverButton: "Recover",
          },
          yesNoCheck: {
            yesButton: "Yes",
            noButton: "No",
            onYesTitle: "Why?",
            onNoTitle: "Why niet?",
          },
          scanCheck: {
            scanButtonText: "Enter code",
            scanButtonScan: "Scan code",
            invalidInput: "is not correct",
            enterCode: "Enter code",
            retry: "Re-enter",
          },
          numberCheck: {
            numberButton: "Enter number",
            invalidInput: "is not correct",
          },
          dateCheck: {
            button: "Enter date",
          },
          photoCheck: {
            saveButton: "Save photos",
            minLabel: "Minimum amount",
            maxLabel: "Maximum amount",
            requirements: "Requirements",
            noLimit: "no limit",
            photo: "Photo",
          },
          signatureCheck: {
            saveButton: "Save signature",
            signature: "Signature",
            delete: "Delete",
          },
          singleLine: {
            enterText: "Enter text",
            saveButton: "Save",
          },
        },
      },
    },
  },
  nl: {
    translation: {
      projectsPage: {
        searchProjectLabel: "Project zoeken...",
        projects: "Projecten",
        recentInspections: "Recente inspecties",
        scanBarcodeToOpenProject: "Scan een barcode om een project te openen",
      },
      projectPage: {
        projects: "Projecten",
        created: "Aangemaakt",
        lastUpdated: "Laatste update",
        activities: "Werkzaamheden",
        projectInformation: "Projectinformatie",
        allInspections: "Alle inspecties",
        projectProgress: "Project voortgang",
        signed: "Afgetekend",
        inspections: "Inspecties",
        noChecklistsForThisProject:
          "Er zijn nog geen checklists voor dit project...",
        noSuggestionsForThisProject:
          "Er zijn geen suggesties voor dit project...",
        startChecklist: "Checklist starten",
        optional: "Optioneel",
        required: "Verplicht",
        finishedInspections: "inspecties voltooid",
      },
      addActivityPage: {
        rejectionPoint: "Afkeurpunt",
        title: "Activiteit toevoegen",
        activityType: "Type activiteit",
        other: "Anders namelijk...",
        description: "Beschrijving",
        saveButton: "opslaan",
        uploadPhotos: "Foto's uploaden",
        commentTypeLabelsInfo: "Opmerking",
        commentTypeLabelsRejected: "Afkeur",
        commentTypeLabelsRecovered: "Hersteld",
      },
      settingsPage: {
        title: "Instellingen",
        developerSettings: "Developer Settings",
        openDataWedgeDebugger: "Open Data Wedge Debugger",

        groupChecks: "Checks groeperen",
        groupChecksDescription: "Verplicht, optioneel en afgerond",

        nextCheck: "Naar volgende check",
        nextCheckDescription:
          "Na het afronden van een check automatisch de volgende check openen",

        expandGroups: "Groepen standaard uitvouwen",
        expandGroupsDescription:
          "Bij het openen van een checklist zijn alle groepen standaard open",

        deviceIsMemor10Scanner: "Apparaat is een memor10",
        deviceIsMemor10ScannerDescription:
          "Gebruik de scan functionaliteit van de scanner",

        deviceIsDataWedgeScanner: "Apparaat is een datawedge scannner",
        deviceIsDataWedgeScannerDescription:
          "Gebruik de scan functionaliteit van de scanner",

        activeLocations: "Actieve locaties",
      },
      inspectionPage: {
        disabledLocationFilterAlert:
          "Deze checklist bevat geen checks voor de geselecteerde locatie(s). Daarom is de locatie filter tijdelijk uitgeschakeld en tonen we alle checks.",
        accepted: "Goedgekeurd",
        rejected: "Afgekeurd",
        recovered: "Hersteld",
        yes: "Ja",
        no: "Nee",
        signed: "Gesigneerd",
        tasks: "Taken",
        signChecklist: "Checklist Aftekenen",
        signedBy: "Afgetekend door",
        unSign: "Aftekenen ongedaan maken",
        allChecksFinished: "Alle checks zijn afgerond. Checklist aftekenen?",
        checkPopup: {
          card: {
            doneBy: "Uitgevoerd door",
            deleted: "Verwijderen",
            rejectionPoint: "Afkeurpunt",
          },
          inApplicableCheckButton: "N.V.T",
          activities: "Activiteiten",
          noActivities: "Geen activiteiten...",
          addActivity: "Activiteit toevoegen",
          okNokCheck: {
            accepted: "Goedgekeurd",
            rejected: "Afgekeurd",
            recovered: "Hersteld",
            acceptButton: "Goedkeuren",
            rejectButton: "Afkeuren",
            recoverButton: "Herstellen",
          },
          yesNoCheck: {
            yesButton: "Ja",
            noButton: "Nee",
            onYesTitle: "Waarom wel?",
            onNoTitle: "Waarom niet?",
          },
          scanCheck: {
            scanButtonText: "Code invullen",
            scanButtonScan: "Code scannen",
            invalidInput: "is niet juist",
            enterCode: "Code ingeven",
            retry: "Opnieuw invoeren",
          },
          numberCheck: {
            numberButton: "Nummer ingeven",
            invalidInput: "is niet juist",
          },
          dateCheck: {
            button: "Datum ingeven",
          },
          photoCheck: {
            saveButton: "Foto's opslaan",
            minLabel: "Minimaal aantal",
            maxLabel: "Maximaal aantal",
            requirements: "Vereisten",
            noLimit: "geen limiet",
            photo: "Foto",
          },
          signatureCheck: {
            saveButton: "Handtekening opslaan",
            signature: "Handtekening",
            delete: "Verwijderen",
          },
          singleLine: {
            enterText: "Tekst ingeven",
            saveButton: "Opslaan",
          },
        },
      },
    },
  },
};

const lang = window.localStorage.getItem("language-code");

i18n.use(initReactI18next).init({
  resources,
  // TODO: why is nl-NL stored with goddamn double quotes in localstorage?!...
  lng: lang === '"nl-NL"' || lang === null ? "nl-NL" : "en-EN",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
