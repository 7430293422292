import { useState } from "react";
import CheckPopupBase from "./index";
// import Dialog from "../../molecules/Dialog";
import { Circle } from "lucide-react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { checksHooks2 } from "../../../api";
import { ICheckInputSingleLineViewModel } from "../../../application/viewModels/CheckInputSingleLineViewModel";
import { CheckState } from "../../../infrastructure/api/common/types";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../ui/dialog";
import { Textarea } from "../../ui/textarea";
import CheckStateCard, {
  ActionContainer,
  AnswerBadge,
  AnswerContainer,
  PrimaryActionButton,
  SecondaryActionButton,
} from "./CheckStateCard";

interface ICheckPopupInputSingleLineProps {
  check: ICheckInputSingleLineViewModel;
  onFinish: () => void;
  title: string;
  signed: boolean;
  sectionTitle: string;
  checklistId: number;
  groupUid: string;
}

export const CheckPopupInputSingleLine = observer(
  ({
    check,
    onFinish,
    title,
    signed,
    sectionTitle,
    checklistId,
    groupUid,
  }: ICheckPopupInputSingleLineProps): JSX.Element => {
    const { mutate, isLoading: isLoadingValue } = checksHooks2.usePut(
      "/checklists/groups/checks/single-text/value",
    );
    const { mutate: mutateReset, isLoading: isLoadingReset } =
      checksHooks2.usePut("/checklists/groups/checks/single-text/value/reset");
    const { mutate: mutateInApplicable, isLoading: isLoadingInApplicable } =
      checksHooks2.usePost(
        "/checklists/groups/checks/single-text/in-applicable",
      );

    const [dialogOpen, setDialogOpen] = useState(false);
    const [value, setValue] = useState<string | undefined>(undefined);

    const handleSubmit = async (value: string) => {
      setDialogOpen(false);

      mutate(
        {
          text: value,
          checklistId: checklistId,
          checkUid: check.uid,
          groupUid,
        },
        {
          onSuccess: async () => {
            await check.store(value);

            if (check.finished) {
              onFinish();
            }
          },
        },
      );
    };

    const handleReset = () => {
      // TODO: add translation.
      if (confirm("Weet je zeker dat je het antwoord wilt verwijderen?")) {
        mutateReset(
          {
            checklistId,
            checkUid: check.uid,
            groupUid,
          },
          {
            onSuccess: () => {
              check.reset();
              onFinish();
            },
          },
        );
      }
    };

    const { t } = useTranslation();

    function handleCheckIsInApplicable() {
      mutateInApplicable(
        {
          checklistId: checklistId,
          checkUid: check.uid,
          groupUid,
        },
        {
          onSuccess: async () => {
            // await check.accept();
            check.handleInApplicable();

            onFinish();
          },
        },
      );
    }

    return (
      <CheckPopupBase
        activities={check.activities}
        instructionId={check.instructionId}
        signed={signed}
        check={check}
      >
        <CheckStateCard
          signed={signed}
          check={check}
          sectionTitle={sectionTitle}
          isLoading={isLoadingValue || isLoadingInApplicable || isLoadingReset}
        >
          {check.state === CheckState.InApplicable && (
            <AnswerContainer>
              <AnswerBadge
                lastTouchedBy={check.lastTouchedBy}
                lastTouchedAt={check.lastTouchedAt}
                checklistAccepted={signed}
                onReset={handleReset}
              >
                <Circle className="w-5 h-5 mr-2" />
                {t("inspectionPage.checkPopup.inApplicableCheckButton")}
              </AnswerBadge>
            </AnswerContainer>
          )}

          {/* {check.text !== null && (
            <AnswerContainer>
              <AnswerBadge
                lastTouchedBy={check.lastTouchedBy}
                lastTouchedAt={check.lastTouchedAt}
                checklistAccepted={signed}
                onReset={handleReset}
                onEdit={() => {
                  setDialogOpen(true);
                  setValue(check.text ?? "");
                }}
                canEdit
              >
                <div>
                  <TextCursorInput className="w-5 h-5 mr-2" />
                </div>
                {check.text}
              </AnswerBadge>
            </AnswerContainer>
          )} */}

          {check.text !== null && (
            <AnswerContainer>
              <div className="px-3 pb-3">
                <Textarea
                  rows={10}
                  readOnly
                  autoFocus
                  className="px-2 py-1 w-full rounded-md bg-accent text-primary border"
                  placeholder={t(
                    "inspectionPage.checkPopup.singleLine.enterText",
                  )}
                  // type="text"
                  value={check.text}
                  onChange={(e) => setValue(e.target.value)}
                />
              </div>
              <AnswerBadge
                lastTouchedBy={check.lastTouchedBy}
                lastTouchedAt={check.lastTouchedAt}
                checklistAccepted={signed}
                onReset={handleReset}
                onEdit={() => {
                  setDialogOpen(true);
                  setValue(check.text ?? "");
                }}
                canEdit
              ></AnswerBadge>
            </AnswerContainer>
          )}

          <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
            <DialogContent className="sm:max-w-[425px]">
              <DialogHeader>
                <DialogTitle>
                  {t("inspectionPage.checkPopup.singleLine.enterText")}
                </DialogTitle>
                <DialogDescription>
                  {check.title.includes(":date:")
                    ? check.title.split(":date:")[1]
                    : check.title}
                </DialogDescription>
              </DialogHeader>
              <div className="grid gap-4 py-4 w-full">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();

                    if (value === undefined) return;

                    handleSubmit(value);

                    setValue(undefined);
                    setDialogOpen(false);
                  }}
                >
                  {check.title.includes(":date:") ? (
                    <input
                      autoFocus
                      type="datetime-local"
                      className="px-2 py-1 w-full rounded-md bg-accent text-primary border"
                      onChange={(e) => {
                        setValue(e.target.value);
                      }}
                    />
                  ) : (
                    <Textarea
                      autoFocus
                      className="px-2 py-1 w-full rounded-md bg-accent text-primary border"
                      placeholder={t(
                        "inspectionPage.checkPopup.singleLine.enterText",
                      )}
                      // type="text"
                      value={value}
                      onChange={(e) => setValue(e.target.value)}
                    />
                  )}
                  <div>
                    <button
                      disabled={!value}
                      className={`mt-3 text-sm border h-10 text-primary rounded-md w-full ${
                        !value ? "opacity-50" : ""
                      }`}
                      type="submit"
                    >
                      {t("inspectionPage.checkPopup.singleLine.saveButton")}
                    </button>
                  </div>
                </form>
              </div>
            </DialogContent>

            {!signed &&
              !check.text &&
              check.state !== CheckState.InApplicable && (
                <div className="w-full">
                  <ActionContainer>
                    <DialogTrigger asChild>
                      <PrimaryActionButton
                        disabled={check.loading}
                        onClick={() => {
                          setDialogOpen(true);
                        }}
                      >
                        {title}
                      </PrimaryActionButton>
                    </DialogTrigger>
                  </ActionContainer>
                  {check.canBeInApplicable && (
                    <div className="px-4 pb-4">
                      <div className="border-t-2 border-dashed pt-4">
                        <SecondaryActionButton
                          onClick={handleCheckIsInApplicable}
                          disabled={check.loading}
                        >
                          {t(
                            "inspectionPage.checkPopup.inApplicableCheckButton",
                          )}
                        </SecondaryActionButton>
                      </div>
                    </div>
                  )}
                </div>
              )}
          </Dialog>
        </CheckStateCard>
      </CheckPopupBase>
    );
  },
);
