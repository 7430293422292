import { mdiChevronRight } from "@mdi/js";
import { CheckCircle2, Circle, CircleDashed } from "lucide-react";
import React, {
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { ICheckBaseViewModel } from "../../../application/viewModels/CheckBaseViewModel";
import { IChecklistSectionViewModel } from "../../../application/viewModels/SectionViewModel";
import Grid from "../../atoms/Grid";
import { TypographyTitleMedium } from "../../atoms/TypographyTitleMedium/TypographyTitleMedium";
import { ChecklistContext } from "../../context/ChecklistManager";
import { SettingsContext } from "../../context/SettingsManager";
import ChecklistSectionGroup from "../ChecklistSectionGroup";
import {
  ChecklistSectionElement,
  ChecklistSectionHeaderContentContainerElement,
  ChecklistSectionHeaderElement,
  StyledChecklistSectionIconElement,
} from "./styled";

interface IChecklistSectionProps {
  group: IChecklistSectionViewModel;
  title: string;
  // Todo: check if this can be omitted since it's basically unused. We can use the useSection hook instead to receive the checks.
  // Update: Somehow using the useSection -> checks does not update state so we still need this prop...
  checks: ICheckBaseViewModel[];
  allOpen: boolean;
  trackActiveCheck: boolean;
  children(checks: ICheckBaseViewModel[]): ReactElement;
  groupIndex: number;
}

export const ChecklistSection: React.FC<IChecklistSectionProps> = ({
  group,
  allOpen,
  trackActiveCheck,
  checks,
  title,
  children,
  groupIndex,
}) => {
  const { defaultOpenSections } = useContext(SettingsContext);
  const [open, setOpen] = useState(defaultOpenSections);
  const { groupChecks } = useContext(SettingsContext);
  const { checkUid } = useParams<{ checkUid: string }>();
  const [searchParams] = useSearchParams();
  const [firstRender, setFirstRender] = useState(true);
  // Todo: The date is a temp fix for opening group when locating a check from check page.
  const date = searchParams.get("date");
  const { checklist } = useContext(ChecklistContext);

  useMemo(() => {
    if (checks.find((c) => c.uid === checkUid) && trackActiveCheck) {
      setOpen(true);
    }
  }, [checkUid, trackActiveCheck, date]);

  useEffect(() => {
    setOpen(allOpen);

    if (checks.find((c) => c.uid === checkUid) && trackActiveCheck) {
      setOpen(true);
    }

    setFirstRender(false);
  }, []);

  useEffect(() => {
    if (!firstRender) {
      setOpen(allOpen);
    }
  }, [allOpen]);

  const toggleOpen = () => {
    setOpen(!open);
  };

  const requiredChecks = checks.filter((c) => c.required);
  const unfinishedRequiredChecks = checks.filter(
    (c) => c.required && !c.finished,
  );
  const unfinishedNotRequiredChecks = checks.filter(
    (c) => !c.required && !c.finished,
  );
  const finishedChecks = checks.filter((c) => c.finished);
  const finishedChecksLength = requiredChecks.filter((c) => c.finished).length;
  const checksLength = requiredChecks.length;

  function X() {
    const checklistCode = checklist.title;

    if (!checklistCode.includes("|")) {
      return;
    }

    const codes = checklistCode.split(".");

    let a = -1;
    let b = -1;

    let x = -1;

    let c = -1;
    const d = codes.length - 3;

    codes.forEach((code, idx) => {
      if (code === "|") {
        if (a === -1) {
          a = idx - 1;
        }

        if (a !== -1) {
          c = idx - 2;
        }
      }

      if (code === "X" || code === "R") {
        x = idx - 1;

        b = idx - 2;
      }
    });

    if (groupIndex <= a) {
      return "A";
    }
    if (groupIndex > a && groupIndex <= b) {
      return "B";
    }

    if (groupIndex === x) {
      return "X";
    }

    if (groupIndex > x && groupIndex <= c) {
      return "C";
    }
    if (groupIndex > c && groupIndex <= d) {
      return "D";
    }
  }

  // useEffect(() => {
  //   const x = X();

  //   console.log(title, x);
  // }, []);

  return (
    <ChecklistSectionElement className="1mx-2 1rounded-lg 1mt-2 1first:border-t 1mx-2 1mb-2">
      <div className="border-x-[8px] border-t-[8px] md:border-x-[8px] md:border-t-[8px] border-surface-container md:border-surface-container md:dark:border-surface-container sticky top-0 z-10">
        <ChecklistSectionHeaderElement
          className={`active:scale-[99.2%] select-none duration-100 ease-out py-4 cursor-pointer bg-surface-low dark:bg-surface-high rounded-lg ${
            open ? "1rounded-b-none" : "1rounded-b-lg"
          }`}
          onClick={toggleOpen}
        >
          <Grid item>
            <ChecklistSectionHeaderContentContainerElement className="px-4">
              <div className="flex items-center">
                {finishedChecksLength === checksLength && (
                  <CheckCircle2 className="w-4 h-4 text-green-400 mr-2" />
                )}

                {checkUid &&
                  group.checks.map((c) => c.uid).includes(checkUid) &&
                  finishedChecksLength !== checksLength && (
                    <CircleDashed className="animate-[spin_7s_linear_infinite] w-4 h-4 text-muted-foreground mr-2" />
                  )}

                {checkUid &&
                  !group.checks.map((c) => c.uid).includes(checkUid) &&
                  finishedChecksLength !== checksLength && (
                    <Circle className="w-4 h-4 text-muted-foreground mr-2" />
                  )}

                <TypographyTitleMedium>{title}</TypographyTitleMedium>
              </div>
              <div className="flex items-center">
                {process.env.REACT_APP_ENABLE_GROUP_LABELS_NEDAIR && (
                  <>
                    {X() === "A" && (
                      <span className="text-blue-400 text-center w-16 text-sm font-medium">
                        ODA
                      </span>
                    )}
                    {X() === "B" && (
                      <span className="text-green-400 text-center w-16 text-sm font-medium">
                        SUP
                      </span>
                    )}
                    {X() === "X" && (
                      <span className="text-red-600 text-center w-16 text-sm font-medium">
                        EXC
                      </span>
                    )}
                    {X() === "C" && (
                      <span className="text-yellow-400 text-center w-16 text-sm font-medium">
                        ETA
                      </span>
                    )}
                    {X() === "D" && (
                      <span className="text-red-400 text-center w-16 text-sm font-medium">
                        EHA
                      </span>
                    )}
                  </>
                )}
                <TypographyTitleMedium>
                  {finishedChecksLength}
                  <span style={{ opacity: 0.35 }}> / {checksLength}</span>
                </TypographyTitleMedium>
              </div>
              {/* <div className="bg-background rounded-full"> */}
              <StyledChecklistSectionIconElement
                open={open}
                path={mdiChevronRight}
                size={1}
              />
              {/* </div> */}
            </ChecklistSectionHeaderContentContainerElement>
          </Grid>
        </ChecklistSectionHeaderElement>
      </div>
      {!groupChecks && open && (
        <ChecklistSectionGroup checks={checks} initialOpenState>
          {children(checks)}
        </ChecklistSectionGroup>
      )}

      {groupChecks && open && (
        <>
          {unfinishedRequiredChecks.length > 0 && (
            <ChecklistSectionGroup
              title="Verplicht"
              initialOpenState
              checks={unfinishedRequiredChecks}
            >
              {children(unfinishedRequiredChecks)}
            </ChecklistSectionGroup>
          )}
          {unfinishedNotRequiredChecks.length > 0 && (
            <ChecklistSectionGroup
              title="Optioneel"
              checks={unfinishedNotRequiredChecks}
            >
              {children(unfinishedNotRequiredChecks)}
            </ChecklistSectionGroup>
          )}
          {finishedChecks.length > 0 && (
            <ChecklistSectionGroup title="Afgerond" checks={finishedChecks}>
              {children(finishedChecks)}
            </ChecklistSectionGroup>
          )}
        </>
      )}
    </ChecklistSectionElement>
  );
};
