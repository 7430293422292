import { makeApi, Zodios } from "@zodios/core";
import { z } from "zod";

const Checklab_WebApi_Features_Inspections_Accept_V1_AcceptChecklistRequest =
  z.object({ checklistUid: z.string().uuid() });
const DiscEdge_WebApi_Responses_Errors_ErrorDetails = z
  .object({
    reason: z.union([
      z.literal(0),
      z.literal(1),
      z.literal(2),
      z.literal(3),
      z.literal(4),
    ]),
    message: z.string().nullable(),
    code: z.string().nullable(),
  })
  .partial();
const DiscEdge_WebApi_Responses_DetailedResponse = z
  .object({
    success: z.boolean(),
    error: DiscEdge_WebApi_Responses_Errors_ErrorDetails,
    content: z.unknown().nullable(),
  })
  .partial()
  // TODO: note passthrough is needed here...
  .passthrough();
const Checklab_WebApi_Features_Inspections_AcceptUndo_V1_AcceptUndoChecklistRequest =
  z.object({ checklistUid: z.string().uuid() });
const Checklab_WebApi_Features_Projects_AddChecklist_V1_AddProjectChecklistRequest =
  z
    .object({ projectId: z.number().int(), checklistId: z.string().uuid() })
    .partial();
const Checklab_WebApi_Features_Projects_DeleteChecklist_V1_DeleteProjectChecklistRequest =
  z
    .object({ projectId: z.number().int(), checklistId: z.string().uuid() })
    .partial();
const Checklab_WebApi_Features_Templates_Checks_ActionWorkflows_V1_AddTemplateCheckActionWorkflowRequestBody =
  z.object({ actionWorkflowId: z.number().int() }).partial();
const Checklab_WebApi_Features_Templates_Checklists_ActionWorkflows_V1_AddTemplateChecklistActionWorkflowRequestBody =
  z.object({ actionWorkflowId: z.number().int() }).partial();
const Checklab_WebApi_Features_Reporting_ChecksCountByTag_V1_ChecksCountByTagDto =
  z.object({ tag: z.string().nullable(), count: z.number() }).partial();
const Checklab_WebApi_Features_Reporting_ChecksCountByTag_V1_ChecksCountByTagResponse =
  z
    .object({
      results: z
        .array(
          Checklab_WebApi_Features_Reporting_ChecksCountByTag_V1_ChecksCountByTagDto,
        )
        .nullable(),
    })
    .partial();
const Checklab_WebApi_Features_Inspections_Checks_CheckSetValue_V1_CheckSetValueRequest =
  z.object({
    checklistUid: z.string().uuid().optional(),
    groupUid: z.string().uuid().optional(),
    checkUid: z.string().uuid().optional(),
    checkType: z.union([
      z.literal(1),
      z.literal(2),
      z.literal(3),
      z.literal(4),
      z.literal(5),
      z.literal(6),
      z.literal(7),
      z.literal(8),
      z.literal(9),
    ]),
    value: z.unknown(),
  });
const Checklab_WebApi_Features_Inspections_Checks_CheckSetValue_V1_CheckSetValueResponse =
  z
    .object({
      state: z.union([
        z.literal(1),
        z.literal(2),
        z.literal(3),
        z.literal(4),
        z.literal(5),
        z.literal(6),
      ]),
      commentId: z.string().uuid().nullable(),
    })
    .partial();
const Checklab_WebApi_Features_ActionWorkflows_Create_V1_CreateActionWorkflowRequest =
  z.object({
    title: z.string().min(1),
    description: z.string().min(1),
    workflowTrigger: z.union([
      z.literal(1),
      z.literal(2),
      z.literal(3),
      z.literal(4),
      z.literal(5),
      z.literal(6),
      z.literal(7),
      z.literal(8),
    ]),
  });
const Checklab_WebApi_Features_ActionWorkflows_Create_V1_CreateActionWorkflowResponse =
  z.object({ id: z.number().int() }).partial();
const Checklab_WebApi_WebRequests_PaginatedResponseMetadata = z
  .object({
    page: z.number().int(),
    size: z.number().int(),
    totalCount: z.number().int(),
  })
  .partial();
const Core_Domain_Features_ActionWorkflows_Models_ActionConfigurationModel = z
  .object({
    id: z.number().int(),
    configurationType: z.union([
      z.literal(1),
      z.literal(2),
      z.literal(3),
      z.literal(4),
    ]),
  })
  .partial();
const Core_Domain_Features_ActionWorkflows_Models_ActionWorkflowModel = z
  .object({
    id: z.number().int(),
    title: z.string().nullable(),
    description: z.string().nullable(),
    workflowTrigger: z.union([
      z.literal(1),
      z.literal(2),
      z.literal(3),
      z.literal(4),
      z.literal(5),
      z.literal(6),
      z.literal(7),
      z.literal(8),
    ]),
    createdAt: z.string(),
    updatedAt: z.string(),
    actionConfigurations: z
      .array(
        Core_Domain_Features_ActionWorkflows_Models_ActionConfigurationModel,
      )
      .nullable(),
    isDeleted: z.boolean(),
    deletedAt: z.string().nullable(),
  })
  .partial();
const Checklab_WebApi_Features_ActionWorkflows_GetAll_V1_GetActionWorkflowRangeResponse =
  z
    .object({
      metadata: Checklab_WebApi_WebRequests_PaginatedResponseMetadata,
      results: z
        .array(Core_Domain_Features_ActionWorkflows_Models_ActionWorkflowModel)
        .nullable(),
    })
    .partial();
const Checklab_WebApi_Features_ActionWorkflows_Update_V1_UpdateActionWorkflowRequest =
  z
    .object({
      id: z.number().int(),
      title: z.string().min(1).nullable(),
      description: z.string().min(1).nullable(),
    })
    .partial();
const Checklab_WebApi_Features_ActionWorkflows_Update_V1_UpdateActionWorkflowResponse =
  z.object({ id: z.number().int() }).partial();
const Checklab_WebApi_Features_ApiKeys_Create_V1_CreateApiKeyRequest = z
  .object({
    name: z.string().min(1).max(150).nullable(),
    expiresAt: z.string(),
  })
  .partial();
const Checklab_WebApi_Features_ApiKeys_Create_V1_CreateApiKeyResponse = z
  .object({ apiKeyId: z.number().int(), secret: z.string().nullable() })
  .partial();
const Core_Domain_Features_ApiKeys_Models_ApiKeyModel = z
  .object({
    id: z.number().int(),
    name: z.string().nullable(),
    secret: z.string().nullable(),
    expiresAt: z.string(),
    createdAt: z.string(),
    updatedAt: z.string(),
  })
  .partial();
const Checklab_WebApi_Features_ApiKeys_GetAll_V1_GetAllApiKeysResponse = z
  .object({
    metadata: Checklab_WebApi_WebRequests_PaginatedResponseMetadata,
    results: z
      .array(Core_Domain_Features_ApiKeys_Models_ApiKeyModel)
      .nullable(),
  })
  .partial();
const Checklab_WebApi_Features_Templates_Checks_Barcode_Create_V1_CreateBarcodeTemplateCheckRequest =
  z.object({
    description: z.string().min(1),
    priority: z.union([z.literal(1), z.literal(2), z.literal(3), z.literal(4)]),
    isEnabled: z.boolean(),
    isRequired: z.boolean(),
    groupId: z.number().int().optional(),
    tagIds: z.array(z.number()),
    locationIds: z.array(z.number()),
    excludeFromPublications: z.boolean(),
    originalLanguage: z.string().min(1),
    expectedScanInput: z.string().nullish(),
    scanInputValidationType: z
      .union([z.literal(1), z.literal(2), z.literal(3), z.literal(4)])
      .nullish(),
    isCaseSensitive: z.boolean().nullish(),
    rejectOnFailure: z.boolean().nullish(),
  });
const Checklab_WebApi_Features_Templates_Checks_Create_V1_CreateTemplateCheckResponse =
  z.object({ templateCheckId: z.number().int() }).partial();
const Checklab_WebApi_Features_Templates_Checks_Barcode_Update_V1_UpdateBarcodeTemplateCheckRequest =
  z.object({
    checkId: z.number().int().optional(),
    description: z.string().min(1),
    priority: z.union([z.literal(1), z.literal(2), z.literal(3), z.literal(4)]),
    isEnabled: z.boolean(),
    isRequired: z.boolean(),
    groupId: z.number().int(),
    tagIds: z.array(z.number()),
    locationIds: z.array(z.number()),
    excludeFromPublications: z.boolean(),
    expectedScanInput: z.string().nullish(),
    scanInputValidationType: z
      .union([z.literal(1), z.literal(2), z.literal(3), z.literal(4)])
      .nullish(),
    isCaseSensitive: z.boolean().nullish(),
    rejectOnFailure: z.boolean().nullish(),
  });
const Checklab_WebApi_Features_Inspections_CreateByAliases_V1_CreateChecklistByAliasesRequest =
  z.object({
    serialNumber: z.string().min(1),
    aliases: z.string().min(1),
    originalLanguage: z.string().min(1),
  });
const Checklab_WebApi_Features_Inspections_CreateByAliases_V1_CreateChecklistByAliasesResponse =
  z
    .object({
      checklistUid: z.string().uuid(),
      checklistId: z.number(),
      checklistSerialNumber: z.string().nullable(),
    })
    .partial();
const Checklab_WebApi_Features_Inspections_CreateManagement_V1_CreateChecklistRequest =
  z.object({
    serialNumber: z.string().min(1),
    productLabel: z.string().min(1),
    templateChecklistId: z.number().int().nullish(),
    groupIds: z.array(z.number()).nullish(),
    projectId: z.number().int().nullish(),
    originalLanguage: z.string().min(1),
  });
const Checklab_WebApi_Features_Inspections_CreateManagement_V1_CreateChecklistResponse =
  z
    .object({
      checklistSerialNumber: z.string().nullable(),
      checklistUid: z.string().uuid(),
      checklistId: z.number(),
    })
    .partial();
const Checklab_WebApi_Features_Inspections_Create_V1_CreateChecklistRequest =
  z.object({
    serialNumber: z.string().min(1),
    productLabel: z.string().min(1),
    templateChecklistId: z.number().int().optional(),
    projectId: z.number().int().gte(1).nullish(),
  });
const Checklab_WebApi_Features_Inspections_Create_V1_CreateChecklistResponse = z
  .object({
    checklistSerialNumber: z.string().nullable(),
    checklistUid: z.string().uuid(),
    checklistId: z.number(),
  })
  .partial();
const Checklab_WebApi_Features_Templates_Dtos_ChecklistSerialNumberValidatorDto =
  z
    .object({
      expectedValue: z.string().nullable(),
      validationType: z.union([
        z.literal(1),
        z.literal(2),
        z.literal(3),
        z.literal(4),
      ]),
      caseSensitive: z.boolean(),
      rejectOnFailure: z.boolean(),
    })
    .partial();
const Checklab_WebApi_Features_Templates_Checks_Checklist_Create_V1_CreateChecklistTemplateCheckRequest =
  z.object({
    description: z.string().min(1),
    priority: z.union([z.literal(1), z.literal(2), z.literal(3), z.literal(4)]),
    isEnabled: z.boolean(),
    isRequired: z.boolean(),
    groupId: z.number().int().optional(),
    tagIds: z.array(z.number()),
    locationIds: z.array(z.number()),
    excludeFromPublications: z.boolean(),
    originalLanguage: z.string().min(1),
    serialNumberValidator:
      Checklab_WebApi_Features_Templates_Dtos_ChecklistSerialNumberValidatorDto.optional(),
    shouldBeAccepted: z.boolean(),
  });
const Checklab_WebApi_Features_Templates_Checks_Checklist_Update_V1_UpdateChecklistTemplateCheckRequest =
  z.object({
    checkId: z.number().int().optional(),
    description: z.string().min(1),
    priority: z.union([z.literal(1), z.literal(2), z.literal(3), z.literal(4)]),
    isEnabled: z.boolean(),
    isRequired: z.boolean(),
    groupId: z.number().int(),
    tagIds: z.array(z.number()),
    locationIds: z.array(z.number()),
    excludeFromPublications: z.boolean(),
    serialNumberValidator:
      Checklab_WebApi_Features_Templates_Dtos_ChecklistSerialNumberValidatorDto.optional(),
    shouldBeAccepted: z.boolean(),
  });
const Checklab_WebApi_Features_Inspections_Checks_Comments_Create_V1_CreateCommentRequest =
  z.object({
    checklistId: z.number().int().optional(),
    groupUid: z.string().uuid().optional(),
    checkUid: z.string().uuid().optional(),
    message: z.string().min(1),
  });
const Checklab_WebApi_Features_Inspections_Checks_Comments_Create_V1_CreateCommentResponse =
  z.object({ commentUid: z.string().uuid() }).partial();
const Checklab_WebApi_Features_Inspections_Checks_Comments_Update_V1_UpdateCommentRequest =
  z.object({
    checklistId: z.string().uuid().optional(),
    groupId: z.string().uuid().optional(),
    checkId: z.string().uuid().optional(),
    commentId: z.string().uuid().optional(),
    message: z.string().min(1).max(2000),
  });
const Checklist_Check_Comment_Photo_Create_Body = z
  .object({
    // checklistUid: z.string().uuid(),
    checklistId: z.number().int(),
    groupUid: z.string().uuid(),
    checkUid: z.string().uuid(),
    commentUid: z.string().uuid(),
    // photos: z.array(z.instanceof(File)),
    photos: z.array(z.any()),
  })
  .partial();
const Checklab_WebApi_Features_Inspections_Dtos_PhotoDto = z
  .object({ uid: z.string().uuid(), uri: z.string().nullable() })
  .partial();
const Checklab_WebApi_Features_Inspections_Checks_Comments_Photos_Create_V1_CreateCommentPhotosResponse =
  z
    .object({
      results: z
        .array(Checklab_WebApi_Features_Inspections_Dtos_PhotoDto)
        .nullable(),
    })
    .partial();
const Checklab_WebApi_Features_Languages_Create_V1_CreateLanguageRequest =
  z.object({
    name: z.string().min(1).max(200),
    code: z.string().min(1).max(20),
  });
const Checklab_WebApi_Features_Languages_Create_V1_CreateLanguageResponse = z
  .object({ id: z.number().int() })
  .partial();
const Checklab_WebApi_Features_Languages_Update_V1_UpdateLanguageRequest =
  z.object({
    id: z.number().int().optional(),
    name: z.string().min(1).max(200),
    code: z.string().min(1).max(20),
  });
const Checklab_WebApi_Features_Locations_Create_V1_CreateLocationRequest =
  z.object({ name: z.string().min(1), description: z.string().min(1) });
const Checklab_WebApi_Features_Locations_Create_V1_CreateLocationResponse = z
  .object({ locationId: z.number().int() })
  .partial();
const Checklab_WebApi_Features_Locations_Dtos_LocationDto = z
  .object({
    id: z.number().int(),
    name: z.string().nullable(),
    description: z.string().nullable(),
    workspaceId: z.number().int(),
  })
  .partial();
const Checklab_WebApi_Features_Locations_GetAll_V1_GetAllLocationsResponse = z
  .object({
    metadata: Checklab_WebApi_WebRequests_PaginatedResponseMetadata,
    results: z
      .array(Checklab_WebApi_Features_Locations_Dtos_LocationDto)
      .nullable(),
  })
  .partial();
const Checklab_WebApi_Features_Locations_Update_V1_UpdateLocationRequest =
  z.object({
    locationId: z.number().int().optional(),
    name: z.string().min(1).max(50),
    description: z.string().min(1).max(2000),
  });
const Checklab_WebApi_Features_Templates_Checks_Number_Create_V1_CreateNumberTemplateCheckRequest =
  z.object({
    description: z.string().min(1),
    priority: z.union([z.literal(1), z.literal(2), z.literal(3), z.literal(4)]),
    isEnabled: z.boolean(),
    isRequired: z.boolean(),
    groupId: z.number().int().optional(),
    tagIds: z.array(z.number()),
    locationIds: z.array(z.number()),
    excludeFromPublications: z.boolean(),
    originalLanguage: z.string().min(1),
    lowerFailureThreshold: z.number().nullish(),
    lowerToleranceThreshold: z.number().nullish(),
    upperFailureThreshold: z.number().nullish(),
    upperToleranceThreshold: z.number().nullish(),
  });
const Checklab_WebApi_Features_Templates_Checks_Number_Update_V1_UpdateNumberTemplateCheckRequest =
  z.object({
    checkId: z.number().int().optional(),
    description: z.string().min(1),
    priority: z.union([z.literal(1), z.literal(2), z.literal(3), z.literal(4)]),
    isEnabled: z.boolean(),
    isRequired: z.boolean(),
    groupId: z.number().int(),
    tagIds: z.array(z.number()),
    locationIds: z.array(z.number()),
    excludeFromPublications: z.boolean(),
    lowerFailureThreshold: z.number().nullish(),
    lowerToleranceThreshold: z.number().nullish(),
    upperFailureThreshold: z.number().nullish(),
    upperToleranceThreshold: z.number().nullish(),
  });

const Checklab_WebApi_Features_Templates_Checks_OkNok_Create_V1_CreateOkNokTemplateCheckRequest =
  z.object({
    description: z.string().min(1),
    priority: z.union([z.literal(1), z.literal(2), z.literal(3), z.literal(4)]),
    isEnabled: z.boolean(),
    isRequired: z.boolean(),
    groupId: z.number().int().optional(),
    tagIds: z.array(z.number()),
    locationIds: z.array(z.number()),
    excludeFromPublications: z.boolean(),
    originalLanguage: z.string().min(1),
    rejectionMessages: z.array(z.string()),
    isUserRejectionMessageAllowed: z.boolean(),
  });
const Checklab_WebApi_Features_Templates_Checks_OkNok_Update_V1_RejectionMessageCreateOrUpdateDto =
  z.object({ key: z.string().nullish(), value: z.string().min(1) });
const Checklab_WebApi_Features_Templates_Checks_OkNok_Update_V1_UpdateOkNokTemplateCheckRequest =
  z.object({
    checkId: z.number().int().optional(),
    description: z.string().min(1),
    priority: z.union([z.literal(1), z.literal(2), z.literal(3), z.literal(4)]),
    isEnabled: z.boolean(),
    isRequired: z.boolean(),
    groupId: z.number().int(),
    tagIds: z.array(z.number()),
    locationIds: z.array(z.number()),
    excludeFromPublications: z.boolean(),
    rejectionMessages: z.array(
      Checklab_WebApi_Features_Templates_Checks_OkNok_Update_V1_RejectionMessageCreateOrUpdateDto,
    ),
    isUserRejectionMessageAllowed: z.boolean(),
  });
const Checklab_WebApi_Features_Templates_Checks_Photo_Create_V1_CreatePhotoTemplateCheckRequest =
  z.object({
    description: z.string().min(1),
    priority: z.union([z.literal(1), z.literal(2), z.literal(3), z.literal(4)]),
    isEnabled: z.boolean(),
    isRequired: z.boolean(),
    groupId: z.number().int().optional(),
    tagIds: z.array(z.number()),
    locationIds: z.array(z.number()),
    excludeFromPublications: z.boolean(),
    originalLanguage: z.string().min(1),
    minPhotoCount: z.number().int().gte(1).lte(2147483647).optional(),
    maxPhotoCount: z.number().int().gte(0).lte(2147483647).optional(),
  });
const Checklab_WebApi_Features_Templates_Checks_Photo_Update_V1_UpdatePhotoTemplateCheckRequest =
  z.object({
    checkId: z.number().int().optional(),
    description: z.string().min(1),
    priority: z.union([z.literal(1), z.literal(2), z.literal(3), z.literal(4)]),
    isEnabled: z.boolean(),
    isRequired: z.boolean(),
    groupId: z.number().int(),
    tagIds: z.array(z.number()),
    locationIds: z.array(z.number()),
    excludeFromPublications: z.boolean(),
    minPhotoCount: z.number().int().gte(1).lte(2147483647).optional(),
    maxPhotoCount: z.number().int().gte(0).lte(2147483647).optional(),
  });
const Checklab_WebApi_Features_Projects_Create_V1_CreateProjectMetadataDto =
  z.object({ key: z.string().min(1), value: z.string().min(1) });
const Checklab_WebApi_Features_Projects_Create_V1_CreateProjectChecklistRuleDto =
  z
    .object({
      templateChecklistId: z.number().int(),
      minOccurrence: z.number().int().gte(0).lte(2147483647),
      maxOccurrence: z.number().int().gte(0).lte(2147483647),
    })
    .partial();
const Checklab_WebApi_Features_Projects_Create_V1_CreateProjectRequest =
  z.object({
    code: z.string().min(1),
    metadata: z.array(
      Checklab_WebApi_Features_Projects_Create_V1_CreateProjectMetadataDto,
    ),
    checklistRules: z.array(
      Checklab_WebApi_Features_Projects_Create_V1_CreateProjectChecklistRuleDto,
    ),
  });
const Checklab_WebApi_Features_Projects_Create_V1_CreateProjectResponse = z
  .object({ id: z.number().int(), code: z.string().nullable() })
  .partial();
const Checklab_WebApi_Features_Projects_Update_V1_UpdateProjectMetadataDto =
  z.object({ key: z.string().min(1), value: z.string().min(1) });
const Checklab_WebApi_Features_Projects_Update_V1_UpdateProjectChecklistRuleDto =
  z
    .object({
      templateChecklistId: z.number().int(),
      minOccurrence: z.number().int().gte(0).lte(2147483647),
      maxOccurrence: z.number().int().gte(0).lte(2147483647),
    })
    .partial();
const Checklab_WebApi_Features_Projects_Update_V1_UpdateProjectRequest =
  z.object({
    id: z.number().int().optional(),
    code: z.string().min(1),
    metadata: z.array(
      Checklab_WebApi_Features_Projects_Update_V1_UpdateProjectMetadataDto,
    ),
    checklistRules: z.array(
      Checklab_WebApi_Features_Projects_Update_V1_UpdateProjectChecklistRuleDto,
    ),
  });
const Checklab_WebApi_Features_Projects_Update_V1_UpdateProjectResponse = z
  .object({ id: z.number().int(), code: z.string().nullable() })
  .partial();
const Checklab_WebApi_Features_ActionWorkflows_CreateConfiguration_V1_CreatePublishIoTMessageActionConfigurationRequestBody =
  z
    .object({
      onSuccessEmailAddresses: z.array(z.string()).nullable(),
      onFailureEmailAddresses: z.array(z.string()).nullable(),
    })
    .partial();
const Checklab_WebApi_Features_ActionWorkflows_CreateConfiguration_V1_CreatePublishIoTMessageActionConfigurationResponse =
  z.object({}).partial();
const Checklab_WebApi_Features_ActionWorkflows_CreateConfiguration_V1_CreateSendCheckEmailActionConfigurationRequestBody =
  z.object({ emailAddresses: z.array(z.string()) });
const Checklab_WebApi_Features_ActionWorkflows_CreateConfiguration_V1_CreateSendCheckEmailActionConfigurationResponse =
  z.object({}).partial();
const Checklab_WebApi_Features_ActionWorkflows_CreateConfiguration_V1_CreateSendChecklistEmailActionConfigurationRequestBody =
  z.object({ emailAddresses: z.array(z.string()) });
const Checklab_WebApi_Features_ActionWorkflows_CreateConfiguration_V1_CreateSendChecklistEmailActionConfigurationResponse =
  z.object({}).partial();
const Checklab_WebApi_Features_Templates_Checks_Signature_Create_V1_CreateSignatureTemplateCheckRequest =
  z.object({
    description: z.string().min(1),
    priority: z.union([z.literal(1), z.literal(2), z.literal(3), z.literal(4)]),
    isEnabled: z.boolean(),
    isRequired: z.boolean(),
    groupId: z.number().int().optional(),
    tagIds: z.array(z.number()),
    locationIds: z.array(z.number()),
    excludeFromPublications: z.boolean(),
    originalLanguage: z.string().min(1),
  });
const Checklab_WebApi_Features_Templates_Checks_Signature_Create_V1_CreateSignatureTemplateCheckResponse =
  z.object({ templateCheckId: z.number().int() }).partial();
const Checklab_WebApi_Features_Templates_Checks_Signature_Update_V1_UpdateSignatureTemplateCheckRequest =
  z.object({
    checkId: z.number().int().optional(),
    description: z.string().min(1),
    priority: z.union([z.literal(1), z.literal(2), z.literal(3), z.literal(4)]),
    isEnabled: z.boolean(),
    isRequired: z.boolean(),
    groupId: z.number().int(),
    tagIds: z.array(z.number()),
    locationIds: z.array(z.number()),
    excludeFromPublications: z.boolean(),
  });
const Checklab_WebApi_Features_Templates_Checks_SingleChoiceList_Create_V1_CreateSingleChoiceListTemplateCheckRequest =
  z.object({
    description: z.string().min(1),
    priority: z.union([z.literal(1), z.literal(2), z.literal(3), z.literal(4)]),
    isEnabled: z.boolean(),
    isRequired: z.boolean(),
    groupId: z.number().int().optional(),
    tagIds: z.array(z.number()),
    locationIds: z.array(z.number()),
    excludeFromPublications: z.boolean(),
    originalLanguage: z.string().min(1),
    choices: z.array(z.string()),
  });
const Checklab_WebApi_Features_Templates_Checks_SingleChoiceList_Update_V1_SingleChoiceListItemCreateOrUpdateDto =
  z.object({ key: z.string().nullish(), value: z.string().min(1) });
const Checklab_WebApi_Features_Templates_Checks_SingleChoiceList_Update_V1_UpdateSingleChoiceListTemplateCheckRequest =
  z.object({
    checkId: z.number().int().optional(),
    description: z.string().min(1),
    priority: z.union([z.literal(1), z.literal(2), z.literal(3), z.literal(4)]),
    isEnabled: z.boolean(),
    isRequired: z.boolean(),
    groupId: z.number().int(),
    tagIds: z.array(z.number()),
    locationIds: z.array(z.number()),
    excludeFromPublications: z.boolean(),
    choices: z.array(
      Checklab_WebApi_Features_Templates_Checks_SingleChoiceList_Update_V1_SingleChoiceListItemCreateOrUpdateDto,
    ),
  });
const Checklab_WebApi_Features_Templates_Checks_SingleText_Create_V1_CreateSingleTextTemplateCheckRequest =
  z.object({
    description: z.string().min(1),
    priority: z.union([z.literal(1), z.literal(2), z.literal(3), z.literal(4)]),
    isEnabled: z.boolean(),
    isRequired: z.boolean(),
    groupId: z.number().int().optional(),
    tagIds: z.array(z.number()),
    locationIds: z.array(z.number()),
    excludeFromPublications: z.boolean(),
    originalLanguage: z.string().min(1),
  });
const Checklab_WebApi_Features_Templates_Checks_SingleText_Update_V1_UpdateSingleTextTemplateCheckRequest =
  z.object({
    checkId: z.number().int().optional(),
    description: z.string().min(1),
    priority: z.union([z.literal(1), z.literal(2), z.literal(3), z.literal(4)]),
    isEnabled: z.boolean(),
    isRequired: z.boolean(),
    groupId: z.number().int(),
    tagIds: z.array(z.number()),
    locationIds: z.array(z.number()),
    excludeFromPublications: z.boolean(),
  });
const Checklab_WebApi_Features_Templates_Checklists_Create_V1_CreateTemplateChecklistRequest =
  z.object({ title: z.string().min(1), originalLanguage: z.string().min(1) });
const Checklab_WebApi_Features_Templates_Checklists_Create_V1_CreateTemplateChecklistResponse =
  z.object({ templateChecklistId: z.number().int() }).partial();
const Checklab_WebApi_Features_Templates_Dtos_ActionWorkflowTemplateDto = z
  .object({
    id: z.number().int(),
    title: z.string().nullable(),
    description: z.string().nullable(),
    workflowTrigger: z.union([
      z.literal(1),
      z.literal(2),
      z.literal(3),
      z.literal(4),
      z.literal(5),
      z.literal(6),
      z.literal(7),
      z.literal(8),
    ]),
  })
  .partial();
const Checklab_WebApi_Features_Templates_Dtos_TemplateChecklistDto = z
  .object({
    id: z.number().int(),
    title: z.string().nullable(),
    productLabels: z.array(z.string()).nullable(),
    groupIds: z.array(z.number()).nullable(),
    actionWorkflows: z
      .array(Checklab_WebApi_Features_Templates_Dtos_ActionWorkflowTemplateDto)
      .nullable(),
    originalLanguage: z.string().nullable(),
  })
  .partial();
const Checklab_WebApi_Features_Templates_Checklists_GetAll_V1_GetAllTemplateChecklistsResponse =
  z
    .object({
      metadata: Checklab_WebApi_WebRequests_PaginatedResponseMetadata,
      results: z
        .array(Checklab_WebApi_Features_Templates_Dtos_TemplateChecklistDto)
        .nullable(),
    })
    .partial();
const Checklab_WebApi_Features_Templates_Checklists_Update_V1_UpdateTemplateChecklistRequest =
  z.object({
    templateChecklistId: z.number().int().optional(),
    title: z.string().min(1),
    groupIds: z.array(z.number()),
    productLabels: z.array(z.string()),
  });
const Checklab_WebApi_Features_Templates_Groups_Create_V1_CreateTemplateGroupRequest =
  z.object({
    title: z.string().min(1).max(300),
    displayTitle: z.string().min(1).max(300),
    originalLanguage: z.string().min(1).max(20),
  });
const Checklab_WebApi_Features_Templates_Groups_Create_V1_CreateTemplateGroupResponse =
  z.object({ groupId: z.number().int() }).partial();
const Checklab_WebApi_Features_Templates_Dtos_TemplateTagDto = z
  .object({
    id: z.number().int(),
    value: z.string().nullable(),
    typeName: z.string().nullable(),
    typeId: z.number().int(),
  })
  .partial();
const Checklab_WebApi_Features_Templates_Dtos_TemplateWorkInstructionDto = z
  .object({ id: z.number().int(), title: z.string().nullable() })
  .partial();
const Checklab_WebApi_Features_Templates_Dtos_TemplateCheckDto = z
  .object({
    id: z.number().int(),
    description: z.string().nullable(),
    priority: z.union([z.literal(1), z.literal(2), z.literal(3), z.literal(4)]),
    checkType: z.union([
      z.literal(1),
      z.literal(2),
      z.literal(3),
      z.literal(4),
      z.literal(5),
      z.literal(6),
      z.literal(7),
      z.literal(8),
      z.literal(9),
    ]),
    isEnabled: z.boolean(),
    isRequired: z.boolean(),
    tags: z
      .array(Checklab_WebApi_Features_Templates_Dtos_TemplateTagDto)
      .nullable(),
    locations: z
      .array(Checklab_WebApi_Features_Locations_Dtos_LocationDto)
      .nullable(),
    //TODO: nullable...
    templateWorkInstruction:
      Checklab_WebApi_Features_Templates_Dtos_TemplateWorkInstructionDto.nullable(),
    originalLanguage: z.string().nullable(),
    excludeFromPublications: z.boolean(),
    actionWorkflows: z
      .array(Checklab_WebApi_Features_Templates_Dtos_ActionWorkflowTemplateDto)
      .nullable(),
  })
  .partial();
const Checklab_WebApi_Features_Templates_Dtos_TemplateGroupDto = z
  .object({
    id: z.number().int(),
    title: z.string().nullable(),
    displayTitle: z.string().nullable(),
    checks: z
      .array(Checklab_WebApi_Features_Templates_Dtos_TemplateCheckDto)
      .nullable(),
    aliases: z.array(z.string()).nullable(),
    originalLanguage: z.string().nullable(),
  })
  .partial();
const Checklab_WebApi_Features_Templates_Groups_GetAll_V1_GetAllTemplateGroupsResponse =
  z
    .object({
      metadata: Checklab_WebApi_WebRequests_PaginatedResponseMetadata,
      results: z
        .array(Checklab_WebApi_Features_Templates_Dtos_TemplateGroupDto)
        .nullable(),
    })
    .partial();
const Checklab_WebApi_Features_Templates_Groups_Update_V1_UpdateTemplateGroupRequest =
  z.object({
    groupId: z.number().int().optional(),
    title: z.string().min(1).max(300),
    displayTitle: z.string().min(1).max(300),
    aliases: z.array(z.string()),
  });
const Checklab_WebApi_Features_Templates_Tags_Create_V1_CreateTemplateTagRequest =
  z.object({
    value: z.string().min(1),
    tagTypeId: z.number().int().optional(),
  });
const Checklab_WebApi_Features_Templates_Tags_Create_V1_CreateTemplateTagResponse =
  z.object({ templateTagId: z.number().int() }).partial();
const Checklab_WebApi_Features_Templates_Tags_GetAll_V1_GetAllTemplateTagsResponse =
  z
    .object({
      metadata: Checklab_WebApi_WebRequests_PaginatedResponseMetadata,
      results: z
        .array(Checklab_WebApi_Features_Templates_Dtos_TemplateTagDto)
        .nullable(),
    })
    .partial();
const Checklab_WebApi_Features_Templates_Tags_Update_V1_UpdateTemplateTagRequest =
  z.object({
    tagId: z.number().int().optional(),
    value: z.string().min(1),
    tagTypeId: z.number().int().optional(),
  });
const Checklab_WebApi_Features_Templates_TagTypes_Create_V1_CreateTemplateTagTypeRequest =
  z.object({ name: z.string().min(1) });
const Checklab_WebApi_Features_Templates_Dtos_TemplateTagTypeDto = z
  .object({ id: z.number().int(), name: z.string().nullable() })
  .partial();
const Checklab_WebApi_Features_Templates_TagTypes_GetAll_V1_GetAllTemplateTagTypesResponse =
  z
    .object({
      results: z
        .array(Checklab_WebApi_Features_Templates_Dtos_TemplateTagTypeDto)
        .nullable(),
    })
    .partial();
const Checklab_WebApi_Features_WorkInstructions_Create_V1_CreateWorkInstructionRequest =
  z.object({
    title: z.string().min(1).max(200),
    description: z.string().min(1),
  });
const Checklab_WebApi_Features_WorkInstructions_Create_V1_CreateWorkInstructionResponse =
  z.object({ workInstructionId: z.number().int() }).partial();
const Checklab_WebApi_Features_WorkInstructions_Dtos_WorkInstructionFileSummaryDto =
  z
    .object({
      id: z.string().uuid(),
      fileName: z.string().nullable(),
      fileTitle: z.string().nullable(),
    })
    .partial();
const Checklab_WebApi_Features_WorkInstructions_Dtos_WorkInstructionDto = z
  .object({
    id: z.number().int(),
    title: z.string().nullable(),
    description: z.string().nullable(),
    textContent: z.string().nullable(),
    files: z
      .array(
        Checklab_WebApi_Features_WorkInstructions_Dtos_WorkInstructionFileSummaryDto,
      )
      .nullable(),
  })
  .partial();
const Checklab_WebApi_Features_WorkInstructions_GetAll_V1_GetAllWorkInstructionsResponse =
  z
    .object({
      metadata: Checklab_WebApi_WebRequests_PaginatedResponseMetadata,
      results: z
        .array(
          Checklab_WebApi_Features_WorkInstructions_Dtos_WorkInstructionDto,
        )
        .nullable(),
    })
    .partial();
const Checklab_WebApi_Features_WorkInstructions_Update_V1_UpdateWorkInstructionRequest =
  z.object({
    workInstructionId: z.number().int().optional(),
    title: z.string().min(1).max(200),
    description: z.string().min(1),
    textContent: z.string().min(0).max(10000).nullish(),
  });
const WorkInstructions_Files_Create_Body = z.object({
  workInstructionId: z.number().int().optional(),
  file: z.instanceof(File).optional(),
  fileName: z.string(),
  fileTitle: z.string(),
});
const Checklab_WebApi_Features_WorkInstructions_Files_Create_V1_CreateWorkInstructionFileResponse =
  z.object({ fileUri: z.string().nullable() }).partial();
const Checklab_WebApi_Features_Workspaces_Create_V1_CreateWorkspaceRequest =
  z.object({ clerkOrganisationId: z.string().min(1) });
const Checklab_WebApi_Features_Workspaces_Create_V1_CreateWorkspaceResponse = z
  .object({ workspaceId: z.number().int() })
  .partial();
const Checklab_WebApi_Features_Templates_Checks_YesNo_Create_V1_CreateYesNoTemplateCheckRequest =
  z.object({
    description: z.string().min(1),
    priority: z.union([z.literal(1), z.literal(2), z.literal(3), z.literal(4)]),
    isEnabled: z.boolean(),
    isRequired: z.boolean(),
    groupId: z.number().int().optional(),
    tagIds: z.array(z.number()),
    locationIds: z.array(z.number()),
    excludeFromPublications: z.boolean(),
    originalLanguage: z.string().min(1),
    correctAnswer: z.union([z.literal(1), z.literal(2), z.literal(3)]),
    commentRequiredOnYes: z.boolean(),
    commentOnYesTitle: z.string().nullish(),
    commentRequiredOnNo: z.boolean(),
    commentOnNoTitle: z.string().nullish(),
  });
const Checklab_WebApi_Features_Templates_Checks_YesNo_Update_V1_UpdateYesNoTemplateCheckRequest =
  z.object({
    checkId: z.number().int().optional(),
    description: z.string().min(1),
    priority: z.union([z.literal(1), z.literal(2), z.literal(3), z.literal(4)]),
    isEnabled: z.boolean(),
    isRequired: z.boolean(),
    groupId: z.number().int(),
    tagIds: z.array(z.number()),
    locationIds: z.array(z.number()),
    excludeFromPublications: z.boolean(),
    correctAnswer: z.union([z.literal(1), z.literal(2), z.literal(3)]),
    commentRequiredOnYes: z.boolean(),
    commentOnYesTitle: z.string().nullish(),
    commentRequiredOnNo: z.boolean(),
    commentOnNoTitle: z.string().nullish(),
  });
const Checklab_WebApi_Features_ActionWorkflows_GetById_V1_GetActionWorkflowByIdResponse =
  z
    .object({
      id: z.number().int(),
      title: z.string().nullable(),
      description: z.string().nullable(),
      workflowTrigger: z.union([
        z.literal(1),
        z.literal(2),
        z.literal(3),
        z.literal(4),
        z.literal(5),
        z.literal(6),
        z.literal(7),
        z.literal(8),
      ]),
      actionConfigurations: z
        .array(
          Core_Domain_Features_ActionWorkflows_Models_ActionConfigurationModel,
        )
        .nullable(),
    })
    .partial();
const Checklab_WebApi_Features_ApiKeys_GetById_V1_GetApiKeyByIdResponse = z
  .object({
    id: z.number().int(),
    name: z.string().nullable(),
    secret: z.string().nullable(),
    expiresAt: z.string(),
    createdAt: z.string(),
    updatedAt: z.string(),
  })
  .partial();
const Checklab_WebApi_Features_Inspections_Dtos_OriginReferenceDto = z
  .object({
    type: z.union([z.literal(0), z.literal(1), z.literal(2), z.literal(3)]),
    value: z.string().nullable(),
  })
  .partial();
const Checklab_WebApi_Features_Inspections_Dtos_CommentDto = z
  .object({
    uid: z.string().uuid(),
    message: z.string().nullable(),
    author: Checklab_WebApi_Features_Inspections_Dtos_OriginReferenceDto,
    createdAt: z.string(),
    updatedAt: z.string(),
    type: z.union([z.literal(1), z.literal(2), z.literal(3)]),
    photos: z
      .array(Checklab_WebApi_Features_Inspections_Dtos_PhotoDto)
      .nullable(),
  })
  .partial();
const Checklab_WebApi_Features_Inspections_Dtos_Checks_InspectionCheckDto = z
  .object({
    uid: z.string().uuid(),
    description: z.string().nullable(),
    priority: z.union([z.literal(1), z.literal(2), z.literal(3), z.literal(4)]),
    type: z.union([
      z.literal(1),
      z.literal(2),
      z.literal(3),
      z.literal(4),
      z.literal(5),
      z.literal(6),
      z.literal(7),
      z.literal(8),
      z.literal(9),
    ]),
    isRequired: z.boolean(),
    isIoT: z.boolean(),
    comments: z
      .array(Checklab_WebApi_Features_Inspections_Dtos_CommentDto)
      .nullable(),
    workInstructionId: z.number().int().nullable(),
    locations: z
      .array(Checklab_WebApi_Features_Locations_Dtos_LocationDto)
      .nullable(),
    checkState: z.union([
      z.literal(1),
      z.literal(2),
      z.literal(3),
      z.literal(4),
      z.literal(5),
      z.literal(6),
    ]),
    lastTouchedBy: Checklab_WebApi_Features_Inspections_Dtos_OriginReferenceDto,
    lastTouchedAt: z.string().nullable(),
  })
  .partial();
const Checklab_WebApi_Features_Inspections_Dtos_InspectionGroupDto = z
  .object({
    uid: z.string().uuid(),
    title: z.string().nullable(),
    checks: z
      .array(
        Checklab_WebApi_Features_Inspections_Dtos_Checks_InspectionCheckDto,
      )
      .nullable(),
  })
  .partial();
const Checklab_WebApi_Features_Inspections_Get_ById_V1_GetChecklistByIdResponse =
  z
    .object({
      uid: z.string().uuid(),
      id: z.number(),
      serialNumber: z.string().nullable(),
      specialFunctions: z.string().nullable(),
      title: z.string().nullable(),
      accepted: z.boolean(),
      groups: z
        .array(Checklab_WebApi_Features_Inspections_Dtos_InspectionGroupDto)
        .nullable(),
      createdBy: Checklab_WebApi_Features_Inspections_Dtos_OriginReferenceDto,
      acceptedBy: Checklab_WebApi_Features_Inspections_Dtos_OriginReferenceDto,
      createdAt: z.string(),
      acceptedAt: z.string().nullable(),
      originalLanguage: z.string().nullable(),
    })
    .partial();
const Checklab_WebApi_Features_Languages_GetById_V1_GetLanguageByIdResponse = z
  .object({
    id: z.number().int(),
    name: z.string().nullable(),
    code: z.string().nullable(),
  })
  .partial();
const Checklab_WebApi_Features_Projects_Dtos_ProjectChecklistRuleDto = z
  .object({
    templateChecklistId: z.number().int(),
    minOccurrence: z.number().int(),
    maxOccurrence: z.number().int(),
  })
  .partial();
const Checklab_WebApi_Features_Projects_Dtos_ProjectMetadataDto = z
  .object({ key: z.string().nullable(), value: z.string().nullable() })
  .partial();
const Checklab_WebApi_Features_Projects_Dtos_ProjectChecklistDto = z
  .object({
    checklistId: z.string().uuid(),
    templateChecklistId: z.number().int().nullable(),
    templateChecklistTitle: z.string().nullable(),
  })
  .partial();
const Checklab_WebApi_Features_Projects_GetById_V1_GetProjectByIdResponse = z
  .object({
    id: z.number().int(),
    code: z.string().nullable(),
    checklistRules: z
      .array(Checklab_WebApi_Features_Projects_Dtos_ProjectChecklistRuleDto)
      .nullable(),
    metadata: z
      .array(Checklab_WebApi_Features_Projects_Dtos_ProjectMetadataDto)
      .nullable(),
    createdAt: z.string(),
    updatedAt: z.string(),
    checklists: z
      .array(Checklab_WebApi_Features_Projects_Dtos_ProjectChecklistDto)
      .nullable(),
  })
  .partial();
const Core_Domain_Features_ActionWorkflows_Models_ActionWorkflowTemplateModel =
  z
    .object({
      id: z.number().int(),
      title: z.string().nullable(),
      description: z.string().nullable(),
      workflowTrigger: z.union([
        z.literal(1),
        z.literal(2),
        z.literal(3),
        z.literal(4),
        z.literal(5),
        z.literal(6),
        z.literal(7),
        z.literal(8),
      ]),
    })
    .partial();
const Checklab_WebApi_Features_Templates_Checklists_GetById_V1_GetTemplateChecklistByIdResponse =
  z
    .object({
      id: z.number().int(),
      title: z.string().nullable(),
      groupIds: z.array(z.number()).nullable(),
      productLabels: z.array(z.string()).nullable(),
      actionWorkflows: z
        .array(
          Core_Domain_Features_ActionWorkflows_Models_ActionWorkflowTemplateModel,
        )
        .nullable(),
      originalLanguage: z.string().nullable(),
    })
    .partial();
const Checklab_WebApi_Features_Templates_Groups_GetById_V1_GetTemplateGroupByIdResponse =
  z
    .object({
      id: z.number().int(),
      title: z.string().nullable(),
      displayTitle: z.string().nullable(),
      checks: z
        .array(Checklab_WebApi_Features_Templates_Dtos_TemplateCheckDto)
        .nullable(),
      aliases: z.array(z.string()).nullable(),
    })
    .partial();
const Checklab_WebApi_Features_WorkInstructions_Dtos_WorkInstructionFileDto = z
  .object({
    id: z.string().uuid(),
    uri: z.string().nullable(),
    title: z.string().nullable(),
    fileName: z.string().nullable(),
    updatedAt: z.string(),
  })
  .partial();
const Checklab_WebApi_Features_WorkInstructions_GetById_V1_GetWorkInstructionByIdResponse =
  z
    .object({
      workInstructionId: z.number().int(),
      title: z.string().nullable(),
      description: z.string().nullable(),
      textContent: z.string().nullable(),
      files: z
        .array(
          Checklab_WebApi_Features_WorkInstructions_Dtos_WorkInstructionFileDto,
        )
        .nullable(),
    })
    .partial();
const Checklab_WebApi_Features_WorkInstructions_Files_Update_V1_UpdateWorkInstructionFileRequestBody =
  z.object({ title: z.string().max(200).nullable() }).partial();
const Core_Domain_Features_Workspaces_Models_WorkspaceModel = z
  .object({
    id: z.number().int(),
    clerkId: z.string().nullable(),
    isDeleted: z.boolean(),
    deletedAt: z.string().nullable(),
  })
  .partial();
const Checklab_WebApi_Features_Workspaces_Get_ById_V1_GetWorkspaceByIdResponse =
  z
    .object({
      workspace: Core_Domain_Features_Workspaces_Models_WorkspaceModel,
    })
    .partial();
const Checklab_WebApi_Features_Inspections_Get_StateIndices_V1_GetAllInspectionStateIndicesResponse =
  z
    .object({
      activeAmount: z.number().int(),
      completedAmount: z.number().int(),
      rejectedAmount: z.number().int(),
      acceptedAmount: z.number().int(),
    })
    .partial();
const Checklab_WebApi_Features_Templates_Dtos_TemplateGroupOverviewDto = z
  .object({
    id: z.number().int(),
    title: z.string().nullable(),
    displayTitle: z.string().nullable(),
    checkAmount: z.number().int(),
    aliases: z.array(z.string()).nullable(),
  })
  .partial();
const Checklab_WebApi_Features_Templates_Checklists_GetAllGroupsById_V1_GetAllTemplateChecklistGroupsByIdResponse =
  z
    .object({
      results: z
        .array(Checklab_WebApi_Features_Templates_Dtos_TemplateGroupOverviewDto)
        .nullable(),
    })
    .partial();
const Checklab_WebApi_Features_Workspaces_Get_Getall_V1_GetAllWorkspacesResponse =
  z
    .object({
      metadata: Checklab_WebApi_WebRequests_PaginatedResponseMetadata,
      workspaces: z
        .array(Core_Domain_Features_Workspaces_Models_WorkspaceModel)
        .nullable(),
    })
    .partial();
const Checklab_WebApi_Features_Templates_Dtos_Translations_Checks_BarcodeTemplateCheckTranslationDto =
  z
    .object({
      id: z.number().int(),
      descriptionTranslations: z.record(z.string()).nullable(),
      originalLanguage: z.string().nullable(),
      checkType: z.union([
        z.literal(1),
        z.literal(2),
        z.literal(3),
        z.literal(4),
        z.literal(5),
        z.literal(6),
        z.literal(7),
        z.literal(8),
        z.literal(9),
      ]),
    })
    .partial();
const Checklab_WebApi_Features_Templates_Checks_Barcode_GetTranslations_V1_GetBarcodeTemplateCheckTranslationsByIdResponse =
  z
    .object({
      value:
        Checklab_WebApi_Features_Templates_Dtos_Translations_Checks_BarcodeTemplateCheckTranslationDto,
    })
    .partial();
const Checklab_WebApi_Features_Publications_Inspections_GetByInspectionIdRangeAsZip_V1_GetByInspectionIdRangeAsZipResponse =
  z.object({}).partial();
const Checklab_WebApi_Features_Publications_Checklists_GetByChecklistIdAndAccessToken_V1_GetChecklistPublicationByChecklistIdAndAccessTokenResponse =
  z
    .object({
      id: z.number().int(),
      fileUri: z.string().nullable(),
      createdAt: z.string(),
    })
    .partial();
const Core_Domain_Features_Publications_Checklist_Models_InspectionPublicationSasModel =
  z
    .object({
      id: z.number().int(),
      checklistId: z.string().uuid(),
      accessToken: z.string().uuid(),
      filePath: z.string().nullable(),
      fileUri: z.string().nullable(),
      createdAt: z.string(),
    })
    .partial();
const Checklab_WebApi_Features_Publications_Checklists_GetByChecklistId_V1_GetChecklistPublicationByChecklistIdResponse =
  z
    .object({
      publication:
        Core_Domain_Features_Publications_Checklist_Models_InspectionPublicationSasModel,
    })
    .partial();
const Checklab_WebApi_Features_Inspections_Dtos_ChecklistSearchDto = z
  .object({
    id: z.number(),
    uid: z.string().uuid(),
    serialNumber: z.string().nullable(),
    percentage: z.number(),
    title: z.string().nullable(),
    state: z.union([z.literal(1), z.literal(2), z.literal(3)]),
    createdBy: Checklab_WebApi_Features_Inspections_Dtos_OriginReferenceDto,
    // TODO: nullable should be defined in back-end.
    acceptedBy:
      Checklab_WebApi_Features_Inspections_Dtos_OriginReferenceDto.nullable(),
    createdAt: z.string(),
    acceptedAt: z.string().nullable(),
  })
  .partial();
const Checklab_WebApi_Features_Inspections_Get_BySerialNumber_V1_GetChecklistsBySerialNumberResponse =
  z
    .object({
      results: z
        .array(Checklab_WebApi_Features_Inspections_Dtos_ChecklistSearchDto)
        .nullable(),
    })
    .partial();
const Checklab_WebApi_Features_Inspections_Get_SearchByIdRange_V1_GetChecklistSearchByIdRangeResponse =
  z
    .object({
      results: z
        .array(Checklab_WebApi_Features_Inspections_Dtos_ChecklistSearchDto)
        .nullable(),
    })
    .partial();
const Checklab_WebApi_Features_Templates_Dtos_Translations_Checks_ChecklistTemplateCheckTranslationDto =
  z
    .object({
      id: z.number().int(),
      descriptionTranslations: z.record(z.string()).nullable(),
      originalLanguage: z.string().nullable(),
      checkType: z.union([
        z.literal(1),
        z.literal(2),
        z.literal(3),
        z.literal(4),
        z.literal(5),
        z.literal(6),
        z.literal(7),
        z.literal(8),
        z.literal(9),
      ]),
    })
    .partial();
const Checklab_WebApi_Features_Templates_Checks_Checklist_GetTranslations_V1_GetChecklistTemplateCheckTranslationsByIdResponse =
  z
    .object({
      value:
        Checklab_WebApi_Features_Templates_Dtos_Translations_Checks_ChecklistTemplateCheckTranslationDto,
    })
    .partial();
const Checklab_WebApi_Features_Reporting_ErrorIndices_V1_GetErrorIndicesByPeriodResponse =
  z
    .object({
      checklistAmount: z.number().int(),
      recoveredChecklistAmount: z.number().int(),
      lowPriorityRecoveries: z.number().int(),
      moderatePriorityRecoveries: z.number().int(),
      highPriorityRecoveries: z.number().int(),
      criticalPriorityRecoveries: z.number().int(),
    })
    .partial();
const Checklab_WebApi_Features_Publications_Inspections_GetByAccessToken_V1_GetInspectionPublicationByAccessTokenResponse =
  z
    .object({
      id: z.number().int(),
      fileUri: z.string().nullable(),
      createdAt: z.string(),
    })
    .partial();
const Checklab_WebApi_Features_Publications_Inspections_GetByInspectionId_V1_GetInspectionPublicationByInspectionIdResponse =
  z
    .object({
      publication:
        Core_Domain_Features_Publications_Checklist_Models_InspectionPublicationSasModel,
    })
    .partial();
const Checklab_WebApi_Features_Languages_GetByCode_V1_GetLanguageByCodeResponse =
  z
    .object({
      id: z.number().int(),
      name: z.string().nullable(),
      code: z.string().nullable(),
    })
    .partial();
const Checklab_WebApi_Features_Locations_GetById_V1_GetLocationByIdResponse = z
  .object({
    locationId: z.number().int(),
    name: z.string().nullable(),
    description: z.string().nullable(),
  })
  .partial();
const Checklab_WebApi_Features_Templates_Dtos_Translations_Checks_NumberTemplateCheckTranslationDto =
  z
    .object({
      id: z.number().int(),
      descriptionTranslations: z.record(z.string()).nullable(),
      originalLanguage: z.string().nullable(),
      checkType: z.union([
        z.literal(1),
        z.literal(2),
        z.literal(3),
        z.literal(4),
        z.literal(5),
        z.literal(6),
        z.literal(7),
        z.literal(8),
        z.literal(9),
      ]),
    })
    .partial();
const Checklab_WebApi_Features_Templates_Checks_Number_GetTranslations_V1_GetNumberTemplateCheckTranslationsByIdResponse =
  z
    .object({
      value:
        Checklab_WebApi_Features_Templates_Dtos_Translations_Checks_NumberTemplateCheckTranslationDto,
    })
    .partial();
const Checklab_WebApi_Features_Templates_Dtos_Translations_Checks_OkNokTemplateCheckTranslationDto =
  z
    .object({
      id: z.number().int(),
      descriptionTranslations: z.record(z.string()).nullable(),
      originalLanguage: z.string().nullable(),
      checkType: z.union([
        z.literal(1),
        z.literal(2),
        z.literal(3),
        z.literal(4),
        z.literal(5),
        z.literal(6),
        z.literal(7),
        z.literal(8),
        z.literal(9),
      ]),
      rejectionMessageTranslations: z.record(z.record(z.string())).nullable(),
    })
    .partial();
const Checklab_WebApi_Features_Templates_Checks_OkNok_GetTranslations_V1_GetOkNokTemplateCheckTranslationsByIdResponse =
  z
    .object({
      value:
        Checklab_WebApi_Features_Templates_Dtos_Translations_Checks_OkNokTemplateCheckTranslationDto,
    })
    .partial();
const Checklab_WebApi_Features_Templates_Dtos_Translations_Checks_PhotoTemplateCheckTranslationDto =
  z
    .object({
      id: z.number().int(),
      descriptionTranslations: z.record(z.string()).nullable(),
      originalLanguage: z.string().nullable(),
      checkType: z.union([
        z.literal(1),
        z.literal(2),
        z.literal(3),
        z.literal(4),
        z.literal(5),
        z.literal(6),
        z.literal(7),
        z.literal(8),
        z.literal(9),
      ]),
    })
    .partial();
const Checklab_WebApi_Features_Templates_Checks_Photo_GetTranslations_V1_GetPhotoTemplateCheckTranslationsByIdResponse =
  z
    .object({
      value:
        Checklab_WebApi_Features_Templates_Dtos_Translations_Checks_PhotoTemplateCheckTranslationDto,
    })
    .partial();
const Checklab_WebApi_Features_Reporting_ProductLabels_V1_GetProductLabelsResponse =
  z.object({ results: z.array(z.string()).nullable() }).partial();
const Core_Domain_Features_Projects_Models_ProjectByInspectionIdModel = z
  .object({ id: z.number().int(), code: z.string().nullable() })
  .partial();
const Checklab_WebApi_Features_Projects_GetByInspectionId_V1_GetProjectByInspectionIdResponse =
  z
    .object({
      results: z
        .array(Core_Domain_Features_Projects_Models_ProjectByInspectionIdModel)
        .nullable(),
    })
    .partial();
const Checklab_WebApi_Features_Projects_Dtos_ProjectDto = z
  .object({
    id: z.number().int(),
    code: z.string().nullable(),
    suggestedTemplateChecklistIds: z.array(z.number()).nullable(),
    metadata: z
      .array(Checklab_WebApi_Features_Projects_Dtos_ProjectMetadataDto)
      .nullable(),
    checklistIds: z.array(z.string()).nullable(),
    createdAt: z.string(),
    updatedAt: z.string(),
  })
  .partial();
const Checklab_WebApi_Features_Projects_GetByPartialCode_V1_GetProjectByPartialCodeResponse =
  z
    .object({
      metadata: Checklab_WebApi_WebRequests_PaginatedResponseMetadata,
      results: z
        .array(Checklab_WebApi_Features_Projects_Dtos_ProjectDto)
        .nullable(),
    })
    .partial();
const Checklab_WebApi_Features_Publications_Projects_GetByAccessTokenAsZip_V1_GetProjectPublicationAsZipByAccessTokenResponse =
  z.object({}).partial();
const Checklab_WebApi_Features_Publications_Projects_GetByProjectId_V1_GetProjectPublicationByProjectIdResponse =
  z
    .object({
      projectId: z.number().int(),
      accessToken: z.string().nullable(),
      createdAt: z.string(),
      updatedAt: z.string(),
    })
    .partial();
const Checklab_WebApi_Features_Projects_GetRecent_V1_GetRecentProjectsResponse =
  z
    .object({
      metadata: Checklab_WebApi_WebRequests_PaginatedResponseMetadata,
      results: z
        .array(Checklab_WebApi_Features_Projects_Dtos_ProjectDto)
        .nullable(),
    })
    .partial();
const Checklab_WebApi_Features_Reporting_Search_V1_ReportingCommentPhotoDto = z
  .object({ uri: z.string().nullable(), createdAt: z.string() })
  .partial();
const Checklab_WebApi_Features_Reporting_Search_V1_ReportingCommentDto = z
  .object({
    message: z.string().nullable(),
    author: Checklab_WebApi_Features_Inspections_Dtos_OriginReferenceDto,
    type: z.union([z.literal(1), z.literal(2), z.literal(3)]),
    createdAt: z.string(),
    photos: z
      .array(
        Checklab_WebApi_Features_Reporting_Search_V1_ReportingCommentPhotoDto,
      )
      .nullable(),
  })
  .partial();
const Checklab_WebApi_Features_Reporting_Comments_V1_GetReportingCommentDto = z
  .object({
    id: z.number().int(),
    comments: z
      .array(Checklab_WebApi_Features_Reporting_Search_V1_ReportingCommentDto)
      .nullable(),
  })
  .partial();
const Checklab_WebApi_Features_Reporting_Comments_V1_GetReportingCommentRangeResponse =
  z
    .object({
      results: z
        .array(
          Checklab_WebApi_Features_Reporting_Comments_V1_GetReportingCommentDto,
        )
        .nullable(),
    })
    .partial();
const Checklab_WebApi_Features_Reporting_Search_V1_ReportingCheckSearchDto = z
  .object({
    id: z.number().int(),
    description: z.string().nullable(),
    productLabel: z.string().nullable(),
    state: z.union([
      z.literal(1),
      z.literal(2),
      z.literal(3),
      z.literal(4),
      z.literal(6),
    ]),
    priority: z.union([z.literal(1), z.literal(2), z.literal(3), z.literal(4)]),
    checkType: z.union([
      z.literal(1),
      z.literal(2),
      z.literal(3),
      z.literal(4),
      z.literal(5),
      z.literal(6),
      z.literal(7),
      z.literal(8),
      z.literal(9),
    ]),
    lastUpdatedAt: z.string(),
    commentCount: z.number().int(),
    templateChecklistId: z.number().int().nullable(),
    templateChecklistTitle: z.string().nullable(),
    checklistId: z.string().uuid().nullable(),
    checklistTitle: z.string().nullable(),
    checklistSerialNumber: z.string().nullable(),
  })
  .partial();
const Checklab_WebApi_Features_Reporting_Search_V1_GetSearchResponse = z
  .object({
    metadata: Checklab_WebApi_WebRequests_PaginatedResponseMetadata,
    results: z
      .array(
        Checklab_WebApi_Features_Reporting_Search_V1_ReportingCheckSearchDto,
      )
      .nullable(),
  })
  .partial();
const Checklab_WebApi_Features_Inspections_Get_ByPartialSerialNumber_V1_GetSearchByPartialSerialNumberResponse =
  z
    .object({
      metadata: Checklab_WebApi_WebRequests_PaginatedResponseMetadata,
      results: z
        .array(Checklab_WebApi_Features_Inspections_Dtos_ChecklistSearchDto)
        .nullable(),
    })
    .partial();
const Checklab_WebApi_Features_Templates_Dtos_Translations_Checks_SignatureTemplateCheckTranslationDto =
  z
    .object({
      id: z.number().int(),
      descriptionTranslations: z.record(z.string()).nullable(),
      originalLanguage: z.string().nullable(),
      checkType: z.union([
        z.literal(1),
        z.literal(2),
        z.literal(3),
        z.literal(4),
        z.literal(5),
        z.literal(6),
        z.literal(7),
        z.literal(8),
        z.literal(9),
      ]),
    })
    .partial();
const Checklab_WebApi_Features_Templates_Checks_Signature_GetTranslations_V1_GetSignatureTemplateCheckTranslationsByIdResponse =
  z
    .object({
      value:
        Checklab_WebApi_Features_Templates_Dtos_Translations_Checks_SignatureTemplateCheckTranslationDto,
    })
    .partial();
const Checklab_WebApi_Features_Templates_Dtos_Translations_Checks_SingleChoiceListTemplateCheckTranslationDto =
  z
    .object({
      id: z.number().int(),
      descriptionTranslations: z.record(z.string()).nullable(),
      originalLanguage: z.string().nullable(),
      checkType: z.union([
        z.literal(1),
        z.literal(2),
        z.literal(3),
        z.literal(4),
        z.literal(5),
        z.literal(6),
        z.literal(7),
        z.literal(8),
        z.literal(9),
      ]),
      choiceTranslations: z.record(z.record(z.string())).nullable(),
    })
    .partial();
const Checklab_WebApi_Features_Templates_Checks_SingleChoiceList_GetTranslations_V1_GetSingleChoiceListTemplateCheckTranslationsByIdResponse =
  z
    .object({
      value:
        Checklab_WebApi_Features_Templates_Dtos_Translations_Checks_SingleChoiceListTemplateCheckTranslationDto,
    })
    .partial();
const Checklab_WebApi_Features_Templates_Dtos_Translations_Checks_SingleTextTemplateCheckTranslationDto =
  z
    .object({
      id: z.number().int(),
      descriptionTranslations: z.record(z.string()).nullable(),
      originalLanguage: z.string().nullable(),
      checkType: z.union([
        z.literal(1),
        z.literal(2),
        z.literal(3),
        z.literal(4),
        z.literal(5),
        z.literal(6),
        z.literal(7),
        z.literal(8),
        z.literal(9),
      ]),
    })
    .partial();
const Checklab_WebApi_Features_Templates_Checks_SingleText_GetTranslations_V1_GetSingleTextTemplateCheckTranslationsByIdResponse =
  z
    .object({
      value:
        Checklab_WebApi_Features_Templates_Dtos_Translations_Checks_SingleTextTemplateCheckTranslationDto,
    })
    .partial();
const Checklab_WebApi_Features_Templates_Checklists_GetByProductLabel_V1_GetTemplateChecklistsByProductLabelResponse =
  z
    .object({
      results: z
        .array(Checklab_WebApi_Features_Templates_Dtos_TemplateChecklistDto)
        .nullable(),
    })
    .partial();
const Checklab_WebApi_Features_Templates_Dtos_TemplateChecklistSearchDto = z
  .object({
    id: z.number().int(),
    title: z.string().nullable(),
    specialFunctions: z.string().nullable(),
    // productLabels: z.array(z.string()).nullable(),
    // groupIds: z.array(z.number()).nullable(),
    locationIds: z.array(z.number()).nullable(),
  })
  .partial();
const Checklab_WebApi_Features_Templates_Checklists_GetSearchByIdRange_V1_GetTemplateChecklistSearchByIdRangeResponse =
  z
    .object({
      results: z
        .array(
          Checklab_WebApi_Features_Templates_Dtos_TemplateChecklistSearchDto,
        )
        .nullable(),
    })
    .partial();
const Checklab_WebApi_Features_Templates_Checklists_GetTranslationsById_V1_GetTemplateChecklistTranslationsByIdResponse =
  z
    .object({
      id: z.number().int(),
      titleTranslations: z.record(z.string()).nullable(),
      originalLanguage: z.string().nullable(),
    })
    .partial();
const Checklab_WebApi_Features_Templates_Groups_GetTranslationsById_V1_GetTemplateGroupTranslationsResponse =
  z
    .object({
      id: z.number().int(),
      titleTranslations: z.record(z.string()).nullable(),
      originalLanguage: z.string().nullable(),
    })
    .partial();
const Checklab_WebApi_Features_Workspaces_Get_ByClerkId_V1_GetWorkspaceByClerkIdResponse =
  z
    .object({
      workspace: Core_Domain_Features_Workspaces_Models_WorkspaceModel,
    })
    .partial();
const Checklab_WebApi_Features_Templates_Dtos_Translations_Checks_YesNoTemplateCheckTranslationDto =
  z
    .object({
      id: z.number().int(),
      descriptionTranslations: z.record(z.string()).nullable(),
      originalLanguage: z.string().nullable(),
      checkType: z.union([
        z.literal(1),
        z.literal(2),
        z.literal(3),
        z.literal(4),
        z.literal(5),
        z.literal(6),
        z.literal(7),
        z.literal(8),
        z.literal(9),
      ]),
      commentOnYesTitleTranslations: z.record(z.string()).nullable(),
      commentOnNoTitleTranslations: z.record(z.string()).nullable(),
    })
    .partial();
const Checklab_WebApi_Features_Templates_Checks_YesNo_GetTranslations_V1_GetYesNoTemplateCheckTranslationsByIdResponse =
  z
    .object({
      value:
        Checklab_WebApi_Features_Templates_Dtos_Translations_Checks_YesNoTemplateCheckTranslationDto,
    })
    .partial();
const Checklab_WebApi_Features_Inspections_Checks_OkNok_SetValue_V1_OkNokInspectionCheckSetValueRequest =
  z
    .object({
      checklistId: z.number().int(),
      groupUid: z.string().uuid(),
      checkUid: z.string().uuid(),
      checkState: z.union([
        z.literal(1),
        z.literal(2),
        z.literal(3),
        z.literal(4),
        z.literal(5),
        z.literal(6),
        z.literal(8),
      ]),
      message: z.string().nullable(),
      selectedRejectionMessage: z.string().nullable(),
    })
    .partial();
const Checklist_Check_Photo_Add_Body = z
  .object({
    checklistId: z.number().int(),
    groupUid: z.string().uuid(),
    checkUid: z.string().uuid(),
    photos: z.array(z.instanceof(File)),
  })
  .partial()
  .passthrough();
const Checklab_WebApi_Features_Inspections_Checks_Photo_AddValue_V1_PhotoCheckAddPhotoResponse =
  z
    .object({
      results: z
        .array(Checklab_WebApi_Features_Inspections_Dtos_PhotoDto)
        .nullable(),
      state: z.union([
        z.literal(1),
        z.literal(2),
        z.literal(3),
        z.literal(4),
        z.literal(5),
        z.literal(6),
      ]),
    })
    .partial();
const Checklab_WebApi_Features_Inspections_Checks_Photo_DeleteValue_V1_PhotoCheckDeletePhotoResponse =
  z
    .object({
      state: z.union([
        z.literal(1),
        z.literal(2),
        z.literal(3),
        z.literal(4),
        z.literal(5),
        z.literal(6),
      ]),
    })
    .partial();
const Checklab_WebApi_Features_Publications_Projects_ResetAccessToken_V1_ResetAccessTokenRequest =
  z.object({ projectId: z.number().int() }).partial();
const Checklab_WebApi_Features_Publications_Projects_ResetAccessToken_V1_ResetAccessTokenResponse =
  z.object({ accessToken: z.string().nullable() }).partial();
const Checklab_WebApi_Features_Publications_Checklists_ResetChecklistPublicationAccessToken_V1_ResetChecklistPublicationAccessTokenRequest =
  z.object({ checklistUid: z.string().uuid() }).partial();
const Checklab_WebApi_Features_Publications_Checklists_ResetChecklistPublicationAccessToken_V1_ResetChecklistPublicationAccessTokenResponse =
  z.object({ newAccessToken: z.string().uuid() }).partial();
const Checklab_WebApi_Features_Inspections_Get_Search_V1_SearchInspectionsResponse =
  z
    .object({
      metadata: Checklab_WebApi_WebRequests_PaginatedResponseMetadata,
      results: z
        .array(Checklab_WebApi_Features_Inspections_Dtos_ChecklistSearchDto)
        .nullable(),
    })
    .partial();
const Core_Domain_Features_Translations_Languages_Models_LanguageModel = z
  .object({
    id: z.number().int(),
    name: z.string().nullable(),
    code: z.string().nullable(),
  })
  .partial();
const Checklab_WebApi_Features_Languages_Search_V1_SearchLanguageResponse = z
  .object({
    metadata: Checklab_WebApi_WebRequests_PaginatedResponseMetadata,
    results: z
      .array(Core_Domain_Features_Translations_Languages_Models_LanguageModel)
      .nullable(),
  })
  .partial();
const Checklab_WebApi_Features_Inspections_Checks_Signature_ResetValue_V1_SignatureCheckResetValueRequest =
  z
    .object({
      checklistId: z.number().int(),
      groupUid: z.string().uuid(),
      checkUid: z.string().uuid(),
    })
    .partial();
const Checklist_Check_Signature_SetValue_Body = z
  .object({
    checklistId: z.number().int().optional(),
    groupUid: z.string().uuid(),
    checkUid: z.string().uuid(),
    // signatureFile: z.instanceof(File),
    signatureFile: z.any(),
  })
  .partial();
const Checklab_WebApi_Features_Inspections_Checks_Signature_SetValue_V1_SignatureCheckSetValueResponse =
  z
    .object({
      state: z.union([
        z.literal(1),
        z.literal(2),
        z.literal(3),
        z.literal(4),
        z.literal(5),
        z.literal(6),
      ]),
    })
    .partial();
const Checklab_WebApi_Features_Inspections_Checks_SingleChoiceList_ResetValue_V1_SingleChoiceListCheckResetValue =
  z
    .object({
      checklistId: z.number().int().optional(),
      groupUid: z.string().uuid(),
      checkUid: z.string().uuid(),
    })
    .partial();
const Checklab_WebApi_Features_Inspections_Checks_SingleChoiceList_SetValue_V1_SingleChoiceListInspectionCheckSetValueRequest =
  z.object({
    checklistId: z.number().int().optional(),
    groupUid: z.string().uuid().optional(),
    checkUid: z.string().uuid().optional(),
    selectedItemKey: z.string().min(1),
  });
const Checklab_WebApi_Features_Templates_Checks_Barcode_UpdateTranslations_V1_UpdateBarcodeTemplateCheckTranslationsRequest =
  z.object({
    id: z.number().int().optional(),
    descriptionTranslations: z.record(z.string()),
  });
const Checklab_WebApi_Features_Templates_Checks_Checklist_UpdateTranslations_V1_UpdateChecklistTemplateCheckTranslationsRequest =
  z.object({
    id: z.number().int().optional(),
    descriptionTranslations: z.record(z.string()),
  });
const Checklab_WebApi_Features_Templates_Checks_Number_UpdateTranslations_V1_UpdateNumberTemplateCheckTranslationsRequest =
  z.object({
    id: z.number().int().optional(),
    descriptionTranslations: z.record(z.string()),
  });
const Checklab_WebApi_Features_Templates_Checks_OkNok_UpdateTranslations_V1_UpdateOkNokTemplateCheckTranslationsRequest =
  z.object({
    id: z.number().int().optional(),
    descriptionTranslations: z.record(z.string()),
    rejectionMessageTranslations: z.record(z.record(z.string())),
  });
const Checklab_WebApi_Features_Templates_Checks_Photo_UpdateTranslations_V1_UpdatePhotoTemplateCheckTranslationsRequest =
  z.object({
    id: z.number().int().optional(),
    descriptionTranslations: z.record(z.string()),
  });
const Checklab_WebApi_Features_Templates_Checks_Signature_UpdateTranslations_V1_UpdateSignatureTemplateCheckTranslationsRequest =
  z.object({
    id: z.number().int().optional(),
    descriptionTranslations: z.record(z.string()),
  });
const Checklab_WebApi_Features_Templates_Checks_SingleChoiceList_UpdateTranslations_V1_UpdateOkNokTemplateCheckTranslationsRequest =
  z.object({
    id: z.number().int().optional(),
    descriptionTranslations: z.record(z.string()),
    choiceTranslations: z.record(z.record(z.string())),
  });
const Checklab_WebApi_Features_Templates_Checks_SingleText_UpdateTranslations_V1_UpdateSingleTextTemplateCheckTranslationsRequest =
  z.object({
    id: z.number().int().optional(),
    descriptionTranslations: z.record(z.string()),
  });
const Checklab_WebApi_Features_Templates_Checklists_UpdateTranslations_V1_UpdateTemplateChecklistTranslationsRequest =
  z.object({
    id: z.number().int().optional(),
    titleTranslations: z.record(z.string()),
  });
const Checklab_WebApi_Features_Templates_Checks_Locations_Update_V1_UpdateTemplateCheckLocationsRequest =
  z.object({
    templateCheckId: z.number().int().optional(),
    locationIds: z.array(z.number()),
  });
const Checklab_WebApi_Features_Templates_Checks_Tags_Update_V1_UpdateTemplateCheckTagsRequest =
  z.object({
    templateCheckId: z.number().int().optional(),
    tagIds: z.array(z.number()),
  });
const Checklab_WebApi_Features_Templates_Checks_WorkInstructions_V1_UpdateTemplateCheckWorkInstructionRequest =
  z
    .object({
      templateCheckId: z.number().int(),
      workInstructionId: z.number().int().nullable(),
    })
    .partial();
const Checklab_WebApi_Features_Templates_Groups_UpdateChecksOrder_V1_UpdateTemplateGroupChecksOrderRequest =
  z.object({
    groupId: z.number().int().optional(),
    templateCheckIds: z.array(z.number()),
  });
const Checklab_WebApi_Features_Templates_Groups_UpdateTranslations_V1_UpdateTemplateGroupTranslationsRequest =
  z.object({
    id: z.number().int().optional(),
    displayTitleTranslations: z.record(z.string()),
  });
const Checklab_WebApi_Features_Templates_Checks_YesNo_UpdateTranslations_V1_UpdateYesNoTemplateCheckTranslationsRequest =
  z.object({
    id: z.number().int().optional(),
    descriptionTranslations: z.record(z.string()),
    commentOnYesTitleTranslations: z.record(z.string()).nullish(),
    commentOnNoTitleTranslations: z.record(z.string()).nullish(),
  });
const Checklab_WebApi_Features_Inspections_Checks_YesNo_ResetValue_V1_YesNoCheckResetValueRequest =
  z
    .object({
      checklistId: z.number().int(),
      groupUid: z.string().uuid(),
      checkUid: z.string().uuid(),
    })
    .partial();
const Checklab_WebApi_Features_Inspections_Checks_YesNo_SetValue_V1_YesNoInspectionCheckSetValueRequest =
  z
    .object({
      checklistId: z.number().int(),
      groupUid: z.string().uuid(),
      checkUid: z.string().uuid(),
      answer: z.boolean().nullable(),
      commentValue: z.string().max(2000).nullable(),
    })
    .partial();
const Checklab_WebApi_Features_Inspections_Get_Recent_V1_RecentInspectionsResponse =
  z
    .object({
      metadata: Checklab_WebApi_WebRequests_PaginatedResponseMetadata,
      results: z
        .array(Checklab_WebApi_Features_Inspections_Dtos_ChecklistSearchDto)
        .nullable(),
    })
    .partial();
const Core_Domain_Features_IoT_Activities_Models_IoTActivityChecklistModel = z
  .object({
    id: z.number().int(),
    deviceId: z.number().int(),
    deviceName: z.string().nullable(),
    inspectionChecklistId: z.number().int(),
    inspectionGroupId: z.string().uuid(),
    inspectionCheckId: z.string().uuid(),
    message: z.string().nullable(),
    // createdAtUtc: z.string().datetime(),
    createdAtUtc: z.string(),
  })
  .partial();
const Checklab_WebApi_Features_IoT_Activities_GetAllByChecklistId_V1_GetAllIoTActivitiesByChecklistIdResponse =
  z
    .object({
      results: z
        .array(
          Core_Domain_Features_IoT_Activities_Models_IoTActivityChecklistModel,
        )
        .nullable(),
    })
    .partial();

const Core_Domain_Features_IoT_Activities_Models_IoTActivityModel = z
  .object({
    id: z.number().int(),
    deviceId: z.number().int(),
    inspectionChecklistId: z.number().int(),
    inspectionGroupId: z.string().uuid(),
    inspectionCheckId: z.string().uuid(),
    message: z.string().nullable(),
    // createdAtUtc: z.string().datetime(),
    createdAtUtc: z.string(),
  })
  .partial();
const Checklab_WebApi_Features_IoT_Activities_GetAllByDeviceId_V1_GetAllIoTActivitiesByDeviceIdResponse =
  z
    .object({
      metadata: Checklab_WebApi_WebRequests_PaginatedResponseMetadata,
      results: z
        .array(Core_Domain_Features_IoT_Activities_Models_IoTActivityModel)
        .nullable(),
    })
    .partial();
const Core_Domain_Features_IoT_Devices_Models_IoTDeviceModel = z
  .object({
    id: z.number().int(),
    name: z.string().nullable(),
    description: z.string().nullable(),
    locationIds: z.array(z.number()).nullable(),
    status: z.union([
      z.literal(1),
      z.literal(2),
      z.literal(3),
      z.literal(4),
      z.literal(5),
    ]),
    isLockable: z.boolean(),
    supportedCheckTypes: z
      .array(
        z.union([
          z.literal(1),
          z.literal(2),
          z.literal(3),
          z.literal(4),
          z.literal(5),
          z.literal(6),
          z.literal(7),
          z.literal(8),
          z.literal(9),
        ]),
      )
      .nullable(),
  })
  .partial();
const Checklab_WebApi_Features_IoT_Devices_GetAll_V1_GetAllIoTDevicesResponse =
  z
    .object({
      metadata: Checklab_WebApi_WebRequests_PaginatedResponseMetadata,
      results: z
        .array(Core_Domain_Features_IoT_Devices_Models_IoTDeviceModel)
        .nullable(),
    })
    .partial();
const Checklab_WebApi_Features_IoT_Devices_GetByLocationIds_V1_GetIoTDevicesByLocationIdRangeResponse =
  z
    .object({
      results: z
        .array(Core_Domain_Features_IoT_Devices_Models_IoTDeviceModel)
        .nullable(),
    })
    .partial();
const Checklab_WebApi_Features_IoT_Devices_Start_V1_PayloadItem = z.object({
  key: z.string().min(1),
  value: z.string().min(1),
});
const Checklab_WebApi_Features_IoT_Devices_Start_V1_StartIoTDeviceRequest = z
  .object({
    deviceId: z.number().int(),
    checklistId: z.number().int(),
    groupId: z.string().uuid(),
    checkId: z.string().uuid(),
    checkType: z.union([
      z.literal(1),
      z.literal(2),
      z.literal(3),
      z.literal(4),
      z.literal(5),
      z.literal(6),
      z.literal(7),
      z.literal(8),
      z.literal(9),
    ]),
    payload: z
      .array(Checklab_WebApi_Features_IoT_Devices_Start_V1_PayloadItem)
      .nullable(),
  })
  .partial();
const Checklab_WebApi_Features_IoT_Devices_Query_V1_SelfContainedIoTDeviceQueryPayloadItem =
  z.object({ key: z.string().min(1), value: z.string().min(1) });
const Checklab_WebApi_Features_IoT_Devices_Query_V1_StartSelfContainedIoTDeviceQueryRequest =
  z
    .object({
      deviceId: z.number().int(),
      checklistId: z.number().int(),
      payload: z
        .array(
          Checklab_WebApi_Features_IoT_Devices_Query_V1_SelfContainedIoTDeviceQueryPayloadItem,
        )
        .nullable(),
    })
    .partial();
const Checklab_WebApi_Features_IoT_Devices_Start_V1_SelfContainedIoTDevicePayloadItem =
  z.object({ key: z.string().min(1), value: z.string().min(1) });
const Checklab_WebApi_Features_IoT_Devices_Start_V1_StartSelfContainedIoTDeviceRequest =
  z
    .object({
      deviceId: z.number().int(),
      checklistId: z.number().int(),
      payload: z
        .array(
          Checklab_WebApi_Features_IoT_Devices_Start_V1_SelfContainedIoTDevicePayloadItem,
        )
        .nullable(),
    })
    .partial();

const Checklab_WebApi_Features_Inspections_Checks_Number_SetValue_V1_NumberInspectionCheckSetValueRequest =
  z
    .object({
      checklistId: z.number().int(),
      groupUid: z.string().uuid(),
      checkUid: z.string().uuid(),
      number: z.number().nullable(),
    })
    .partial();
const Checklab_WebApi_Features_Inspections_Checks_Number_ResetValue_V1_NumberCheckResetValueRequest =
  z
    .object({
      checklistId: z.number().int(),
      groupUid: z.string().uuid(),
      checkUid: z.string().uuid(),
    })
    .partial();

const Checklab_WebApi_Features_Inspections_Checks_SingleText_SetValue_V1_SingleTextInspectionCheckSetValueRequest =
  z
    .object({
      checklistId: z.number().int(),
      groupUid: z.string().uuid(),
      checkUid: z.string().uuid(),
      text: z.string().max(500).nullable(),
    })
    .partial();

const Checklab_WebApi_Features_Inspections_Checks_SingleText_ResetValue_V1_SingleTextCheckResetValueRequest =
  z
    .object({
      checklistId: z.number().int(),
      groupUid: z.string().uuid(),
      checkUid: z.string().uuid(),
    })
    .partial();
const Checklab_WebApi_Features_Inspections_Checks_Barcode_ResetValue_V1_BarcodeCheckResetValueRequest =
  z
    .object({
      checklistId: z.number().int(),
      groupUid: z.string().uuid(),
      checkUid: z.string().uuid(),
    })
    .partial();
const Checklab_WebApi_Features_Inspections_Checks_Barcode_SetValue_V1_BarcodeInspectionCheckSetValueRequest =
  z
    .object({
      checklistId: z.number().int(),
      groupUid: z.string().uuid(),
      checkUid: z.string().uuid(),
      barcode: z.string().max(256).nullable(),
    })
    .partial();
const Checklab_WebApi_Features_Inspections_Checks_SetInApplicable_V1_BarcodeInspectionCheckSetInApplicableRequest =
  z
    .object({
      checklistId: z.number().int(),
      groupUid: z.string().uuid(),
      checkUid: z.string().uuid(),
    })
    .partial();

export const IotEndpoints = makeApi([
  // {
  //   method: "get",
  //   path: "/v2/iot-activities/:deviceId",
  //   requestFormat: "json",
  //   parameters: [
  //     {
  //       name: "deviceId",
  //       type: "Path",
  //       schema: z.number().int(),
  //     },
  //     {
  //       name: "page",
  //       type: "Query",
  //       schema: z.number().int().gte(1).lte(2147483647).default(1),
  //     },
  //     {
  //       name: "size",
  //       type: "Query",
  //       schema: z.number().int().gte(1).lte(100).optional().default(25),
  //     },
  //   ],
  //   response:
  //     Checklab_WebApi_Features_IoT_Activities_GetAllByDeviceId_V1_GetAllIoTActivitiesByDeviceIdResponse,
  // },
  // {
  //   method: "get",
  //   path: "/v2/iot-activities/checklist/:checklistId",
  //   requestFormat: "json",
  //   parameters: [
  //     {
  //       name: "checklistId",
  //       type: "Path",
  //       schema: z.number().int(),
  //     },
  //     {
  //       name: "groupId",
  //       type: "Query",
  //       schema: z.string().uuid().optional(),
  //     },
  //     {
  //       name: "checkId",
  //       type: "Query",
  //       schema: z.string().uuid().optional(),
  //     },
  //   ],
  //   response:
  //     Checklab_WebApi_Features_IoT_Activities_GetAllByChecklistId_V1_GetAllIoTActivitiesByChecklistIdResponse,
  // },
  // {
  //   method: "get",
  //   path: "/v2/iot-devices",
  //   description: `Return devices in a paginated manner`,
  //   requestFormat: "json",
  //   parameters: [
  //     {
  //       name: "name",
  //       type: "Query",
  //       schema: z.string().max(200).optional(),
  //     },
  //     {
  //       name: "page",
  //       type: "Query",
  //       schema: z.number().int().gte(1).lte(2147483647).default(1),
  //     },
  //     {
  //       name: "size",
  //       type: "Query",
  //       schema: z.number().int().gte(1).lte(100).optional().default(25),
  //     },
  //   ],
  //   response:
  //     Checklab_WebApi_Features_IoT_Devices_GetAll_V1_GetAllIoTDevicesResponse,
  // },
  // {
  //   method: "get",
  //   path: "/v2/iot-devices/by-locations",
  //   requestFormat: "json",
  //   parameters: [
  //     {
  //       name: "locationIds",
  //       type: "Query",
  //       schema: z.array(z.number()).optional(),
  //     },
  //   ],
  //   response:
  //     Checklab_WebApi_Features_IoT_Devices_GetByLocationIds_V1_GetIoTDevicesByLocationIdRangeResponse,
  // },
  // {
  //   method: "put",
  //   path: "/v2/iot-devices/locations",
  //   requestFormat: "json",
  //   parameters: [
  //     {
  //       name: "id",
  //       type: "Query",
  //       schema: z.number().int().optional(),
  //     },
  //     {
  //       name: "locationIds",
  //       type: "Query",
  //       schema: z.array(z.number()),
  //     },
  //   ],
  //   response: DiscEdge_WebApi_Responses_DetailedResponse,
  // },
  // {
  //   method: "post",
  //   path: "/v2/iot-devices/start",
  //   requestFormat: "json",
  //   parameters: [
  //     {
  //       name: "body",
  //       type: "Body",
  //       schema:
  //         Checklab_WebApi_Features_IoT_Devices_Start_V1_StartIoTDeviceRequest,
  //     },
  //   ],
  //   response: DiscEdge_WebApi_Responses_DetailedResponse,
  // },
  {
    method: "get",
    path: "/v2/iot-activities/:deviceId",
    alias: "IoTActivities.GetAll",
    requestFormat: "json",
    parameters: [
      {
        name: "deviceId",
        type: "Path",
        schema: z.number().int(),
      },
      {
        name: "page",
        type: "Query",
        schema: z.number().int().gte(1).lte(2147483647).default(1),
      },
      {
        name: "size",
        type: "Query",
        schema: z.number().int().gte(1).lte(100).optional().default(25),
      },
    ],
    response:
      Checklab_WebApi_Features_IoT_Activities_GetAllByDeviceId_V1_GetAllIoTActivitiesByDeviceIdResponse,
  },
  {
    method: "get",
    path: "/v2/iot-activities/checklist/:checklistId",
    alias: "IoTActivities.GetByChecklist",
    requestFormat: "json",
    parameters: [
      {
        name: "checklistId",
        type: "Path",
        schema: z.number().int(),
      },
      {
        name: "groupId",
        type: "Query",
        schema: z.string().uuid().optional(),
      },
      {
        name: "checkId",
        type: "Query",
        schema: z.string().uuid().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_IoT_Activities_GetAllByChecklistId_V1_GetAllIoTActivitiesByChecklistIdResponse,
  },
  {
    method: "get",
    path: "/v2/iot-devices",
    alias: "IoTDevices.GetAll",
    description: `Return devices in a paginated manner`,
    requestFormat: "json",
    parameters: [
      {
        name: "name",
        type: "Query",
        schema: z.string().max(200).optional(),
      },
      {
        name: "page",
        type: "Query",
        schema: z.number().int().gte(1).lte(2147483647).default(1),
      },
      {
        name: "size",
        type: "Query",
        schema: z.number().int().gte(1).lte(100).optional().default(25),
      },
    ],
    response:
      Checklab_WebApi_Features_IoT_Devices_GetAll_V1_GetAllIoTDevicesResponse,
  },
  {
    method: "get",
    path: "/v2/iot-devices/by-locations",
    alias: "IoTDevices.GetByLocations",
    requestFormat: "json",
    parameters: [
      {
        name: "locationIds",
        type: "Query",
        schema: z.array(z.number().int()).optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_IoT_Devices_GetByLocationIds_V1_GetIoTDevicesByLocationIdRangeResponse,
  },
  {
    method: "put",
    path: "/v2/iot-devices/locations",
    alias: "IoTDevices.UpdateLocations",
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Query",
        schema: z.number().int().optional(),
      },
      {
        name: "locationIds",
        type: "Query",
        schema: z.array(z.number().int()),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "post",
    path: "/v2/iot-devices/start",
    alias: "IoTDevices.Start",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_IoT_Devices_Start_V1_StartIoTDeviceRequest,
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "post",
    path: "/v2/iot-devices/start-self-contained",
    alias: "IoTDevices.StartSelfContained",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_IoT_Devices_Start_V1_StartSelfContainedIoTDeviceRequest,
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "post",
    path: "/v2/iot-devices/start-self-contained-query",
    alias: "IoTDevices.StartSelfContainedQuery",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_IoT_Devices_Query_V1_StartSelfContainedIoTDeviceQueryRequest,
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
]);

export const schemas = {
  Checklab_WebApi_Features_IoT_Devices_Start_V1_StartSelfContainedIoTDeviceRequest,
  Checklab_WebApi_Features_IoT_Devices_Start_V1_SelfContainedIoTDevicePayloadItem,
  Checklab_WebApi_Features_IoT_Devices_Query_V1_SelfContainedIoTDeviceQueryPayloadItem,
  Checklab_WebApi_Features_IoT_Devices_Query_V1_StartSelfContainedIoTDeviceQueryRequest,
  Core_Domain_Features_IoT_Activities_Models_IoTActivityChecklistModel,
  Checklab_WebApi_Features_IoT_Activities_GetAllByChecklistId_V1_GetAllIoTActivitiesByChecklistIdResponse,
  Core_Domain_Features_IoT_Activities_Models_IoTActivityModel,
  Checklab_WebApi_Features_IoT_Activities_GetAllByDeviceId_V1_GetAllIoTActivitiesByDeviceIdResponse,
  Core_Domain_Features_IoT_Devices_Models_IoTDeviceModel,
  Checklab_WebApi_Features_IoT_Devices_GetAll_V1_GetAllIoTDevicesResponse,
  Checklab_WebApi_Features_IoT_Devices_GetByLocationIds_V1_GetIoTDevicesByLocationIdRangeResponse,
  Checklab_WebApi_Features_IoT_Devices_Start_V1_PayloadItem,
  Checklab_WebApi_Features_IoT_Devices_Start_V1_StartIoTDeviceRequest,
  Checklab_WebApi_Features_Inspections_Get_Recent_V1_RecentInspectionsResponse,
  Checklab_WebApi_Features_Inspections_Accept_V1_AcceptChecklistRequest,
  DiscEdge_WebApi_Responses_Errors_ErrorDetails,
  DiscEdge_WebApi_Responses_DetailedResponse,
  Checklab_WebApi_Features_Inspections_AcceptUndo_V1_AcceptUndoChecklistRequest,
  Checklab_WebApi_Features_Projects_AddChecklist_V1_AddProjectChecklistRequest,
  Checklab_WebApi_Features_Projects_DeleteChecklist_V1_DeleteProjectChecklistRequest,
  Checklab_WebApi_Features_Templates_Checks_ActionWorkflows_V1_AddTemplateCheckActionWorkflowRequestBody,
  Checklab_WebApi_Features_Templates_Checklists_ActionWorkflows_V1_AddTemplateChecklistActionWorkflowRequestBody,
  Checklab_WebApi_Features_Reporting_ChecksCountByTag_V1_ChecksCountByTagDto,
  Checklab_WebApi_Features_Reporting_ChecksCountByTag_V1_ChecksCountByTagResponse,
  Checklab_WebApi_Features_Inspections_Checks_CheckSetValue_V1_CheckSetValueRequest,
  Checklab_WebApi_Features_Inspections_Checks_CheckSetValue_V1_CheckSetValueResponse,
  Checklab_WebApi_Features_ActionWorkflows_Create_V1_CreateActionWorkflowRequest,
  Checklab_WebApi_Features_ActionWorkflows_Create_V1_CreateActionWorkflowResponse,
  Checklab_WebApi_WebRequests_PaginatedResponseMetadata,
  Core_Domain_Features_ActionWorkflows_Models_ActionConfigurationModel,
  Core_Domain_Features_ActionWorkflows_Models_ActionWorkflowModel,
  Checklab_WebApi_Features_ActionWorkflows_GetAll_V1_GetActionWorkflowRangeResponse,
  Checklab_WebApi_Features_ActionWorkflows_Update_V1_UpdateActionWorkflowRequest,
  Checklab_WebApi_Features_ActionWorkflows_Update_V1_UpdateActionWorkflowResponse,
  Checklab_WebApi_Features_ApiKeys_Create_V1_CreateApiKeyRequest,
  Checklab_WebApi_Features_ApiKeys_Create_V1_CreateApiKeyResponse,
  Core_Domain_Features_ApiKeys_Models_ApiKeyModel,
  Checklab_WebApi_Features_ApiKeys_GetAll_V1_GetAllApiKeysResponse,
  Checklab_WebApi_Features_Templates_Checks_Barcode_Create_V1_CreateBarcodeTemplateCheckRequest,
  Checklab_WebApi_Features_Templates_Checks_Create_V1_CreateTemplateCheckResponse,
  Checklab_WebApi_Features_Templates_Checks_Barcode_Update_V1_UpdateBarcodeTemplateCheckRequest,
  Checklab_WebApi_Features_Inspections_CreateByAliases_V1_CreateChecklistByAliasesRequest,
  Checklab_WebApi_Features_Inspections_CreateByAliases_V1_CreateChecklistByAliasesResponse,
  Checklab_WebApi_Features_Inspections_CreateManagement_V1_CreateChecklistRequest,
  Checklab_WebApi_Features_Inspections_CreateManagement_V1_CreateChecklistResponse,
  Checklab_WebApi_Features_Inspections_Create_V1_CreateChecklistRequest,
  Checklab_WebApi_Features_Inspections_Create_V1_CreateChecklistResponse,
  Checklab_WebApi_Features_Templates_Dtos_ChecklistSerialNumberValidatorDto,
  Checklab_WebApi_Features_Templates_Checks_Checklist_Create_V1_CreateChecklistTemplateCheckRequest,
  Checklab_WebApi_Features_Templates_Checks_Checklist_Update_V1_UpdateChecklistTemplateCheckRequest,
  Checklab_WebApi_Features_Inspections_Checks_Comments_Create_V1_CreateCommentRequest,
  Checklab_WebApi_Features_Inspections_Checks_Comments_Create_V1_CreateCommentResponse,
  Checklab_WebApi_Features_Inspections_Checks_Comments_Update_V1_UpdateCommentRequest,
  Checklist_Check_Comment_Photo_Create_Body,
  Checklab_WebApi_Features_Inspections_Dtos_PhotoDto,
  Checklab_WebApi_Features_Inspections_Checks_Comments_Photos_Create_V1_CreateCommentPhotosResponse,
  Checklab_WebApi_Features_Languages_Create_V1_CreateLanguageRequest,
  Checklab_WebApi_Features_Languages_Create_V1_CreateLanguageResponse,
  Checklab_WebApi_Features_Languages_Update_V1_UpdateLanguageRequest,
  Checklab_WebApi_Features_Locations_Create_V1_CreateLocationRequest,
  Checklab_WebApi_Features_Locations_Create_V1_CreateLocationResponse,
  Checklab_WebApi_Features_Locations_Dtos_LocationDto,
  Checklab_WebApi_Features_Locations_GetAll_V1_GetAllLocationsResponse,
  Checklab_WebApi_Features_Locations_Update_V1_UpdateLocationRequest,
  Checklab_WebApi_Features_Templates_Checks_Number_Create_V1_CreateNumberTemplateCheckRequest,
  Checklab_WebApi_Features_Templates_Checks_Number_Update_V1_UpdateNumberTemplateCheckRequest,
  Checklab_WebApi_Features_Templates_Checks_OkNok_Create_V1_CreateOkNokTemplateCheckRequest,
  Checklab_WebApi_Features_Templates_Checks_OkNok_Update_V1_RejectionMessageCreateOrUpdateDto,
  Checklab_WebApi_Features_Templates_Checks_OkNok_Update_V1_UpdateOkNokTemplateCheckRequest,
  Checklab_WebApi_Features_Templates_Checks_Photo_Create_V1_CreatePhotoTemplateCheckRequest,
  Checklab_WebApi_Features_Templates_Checks_Photo_Update_V1_UpdatePhotoTemplateCheckRequest,
  Checklab_WebApi_Features_Projects_Create_V1_CreateProjectMetadataDto,
  Checklab_WebApi_Features_Projects_Create_V1_CreateProjectChecklistRuleDto,
  Checklab_WebApi_Features_Projects_Create_V1_CreateProjectRequest,
  Checklab_WebApi_Features_Projects_Create_V1_CreateProjectResponse,
  Checklab_WebApi_Features_Projects_Update_V1_UpdateProjectMetadataDto,
  Checklab_WebApi_Features_Projects_Update_V1_UpdateProjectChecklistRuleDto,
  Checklab_WebApi_Features_Projects_Update_V1_UpdateProjectRequest,
  Checklab_WebApi_Features_Projects_Update_V1_UpdateProjectResponse,
  Checklab_WebApi_Features_ActionWorkflows_CreateConfiguration_V1_CreatePublishIoTMessageActionConfigurationRequestBody,
  Checklab_WebApi_Features_ActionWorkflows_CreateConfiguration_V1_CreatePublishIoTMessageActionConfigurationResponse,
  Checklab_WebApi_Features_ActionWorkflows_CreateConfiguration_V1_CreateSendCheckEmailActionConfigurationRequestBody,
  Checklab_WebApi_Features_ActionWorkflows_CreateConfiguration_V1_CreateSendCheckEmailActionConfigurationResponse,
  Checklab_WebApi_Features_ActionWorkflows_CreateConfiguration_V1_CreateSendChecklistEmailActionConfigurationRequestBody,
  Checklab_WebApi_Features_ActionWorkflows_CreateConfiguration_V1_CreateSendChecklistEmailActionConfigurationResponse,
  Checklab_WebApi_Features_Templates_Checks_Signature_Create_V1_CreateSignatureTemplateCheckRequest,
  Checklab_WebApi_Features_Templates_Checks_Signature_Create_V1_CreateSignatureTemplateCheckResponse,
  Checklab_WebApi_Features_Templates_Checks_Signature_Update_V1_UpdateSignatureTemplateCheckRequest,
  Checklab_WebApi_Features_Templates_Checks_SingleChoiceList_Create_V1_CreateSingleChoiceListTemplateCheckRequest,
  Checklab_WebApi_Features_Templates_Checks_SingleChoiceList_Update_V1_SingleChoiceListItemCreateOrUpdateDto,
  Checklab_WebApi_Features_Templates_Checks_SingleChoiceList_Update_V1_UpdateSingleChoiceListTemplateCheckRequest,
  Checklab_WebApi_Features_Templates_Checks_SingleText_Create_V1_CreateSingleTextTemplateCheckRequest,
  Checklab_WebApi_Features_Templates_Checks_SingleText_Update_V1_UpdateSingleTextTemplateCheckRequest,
  Checklab_WebApi_Features_Templates_Checklists_Create_V1_CreateTemplateChecklistRequest,
  Checklab_WebApi_Features_Templates_Checklists_Create_V1_CreateTemplateChecklistResponse,
  Checklab_WebApi_Features_Templates_Dtos_ActionWorkflowTemplateDto,
  Checklab_WebApi_Features_Templates_Dtos_TemplateChecklistDto,
  Checklab_WebApi_Features_Templates_Checklists_GetAll_V1_GetAllTemplateChecklistsResponse,
  Checklab_WebApi_Features_Templates_Checklists_Update_V1_UpdateTemplateChecklistRequest,
  Checklab_WebApi_Features_Templates_Groups_Create_V1_CreateTemplateGroupRequest,
  Checklab_WebApi_Features_Templates_Groups_Create_V1_CreateTemplateGroupResponse,
  Checklab_WebApi_Features_Templates_Dtos_TemplateTagDto,
  Checklab_WebApi_Features_Templates_Dtos_TemplateWorkInstructionDto,
  Checklab_WebApi_Features_Templates_Dtos_TemplateCheckDto,
  Checklab_WebApi_Features_Templates_Dtos_TemplateGroupDto,
  Checklab_WebApi_Features_Templates_Groups_GetAll_V1_GetAllTemplateGroupsResponse,
  Checklab_WebApi_Features_Templates_Groups_Update_V1_UpdateTemplateGroupRequest,
  Checklab_WebApi_Features_Templates_Tags_Create_V1_CreateTemplateTagRequest,
  Checklab_WebApi_Features_Templates_Tags_Create_V1_CreateTemplateTagResponse,
  Checklab_WebApi_Features_Templates_Tags_GetAll_V1_GetAllTemplateTagsResponse,
  Checklab_WebApi_Features_Templates_Tags_Update_V1_UpdateTemplateTagRequest,
  Checklab_WebApi_Features_Templates_TagTypes_Create_V1_CreateTemplateTagTypeRequest,
  Checklab_WebApi_Features_Templates_Dtos_TemplateTagTypeDto,
  Checklab_WebApi_Features_Templates_TagTypes_GetAll_V1_GetAllTemplateTagTypesResponse,
  Checklab_WebApi_Features_WorkInstructions_Create_V1_CreateWorkInstructionRequest,
  Checklab_WebApi_Features_WorkInstructions_Create_V1_CreateWorkInstructionResponse,
  Checklab_WebApi_Features_WorkInstructions_Dtos_WorkInstructionFileSummaryDto,
  Checklab_WebApi_Features_WorkInstructions_Dtos_WorkInstructionDto,
  Checklab_WebApi_Features_WorkInstructions_GetAll_V1_GetAllWorkInstructionsResponse,
  Checklab_WebApi_Features_WorkInstructions_Update_V1_UpdateWorkInstructionRequest,
  WorkInstructions_Files_Create_Body,
  Checklab_WebApi_Features_WorkInstructions_Files_Create_V1_CreateWorkInstructionFileResponse,
  Checklab_WebApi_Features_Workspaces_Create_V1_CreateWorkspaceRequest,
  Checklab_WebApi_Features_Workspaces_Create_V1_CreateWorkspaceResponse,
  Checklab_WebApi_Features_Templates_Checks_YesNo_Create_V1_CreateYesNoTemplateCheckRequest,
  Checklab_WebApi_Features_Templates_Checks_YesNo_Update_V1_UpdateYesNoTemplateCheckRequest,
  Checklab_WebApi_Features_ActionWorkflows_GetById_V1_GetActionWorkflowByIdResponse,
  Checklab_WebApi_Features_ApiKeys_GetById_V1_GetApiKeyByIdResponse,
  Checklab_WebApi_Features_Inspections_Dtos_OriginReferenceDto,
  Checklab_WebApi_Features_Inspections_Dtos_CommentDto,
  Checklab_WebApi_Features_Inspections_Dtos_Checks_InspectionCheckDto,
  Checklab_WebApi_Features_Inspections_Dtos_InspectionGroupDto,
  Checklab_WebApi_Features_Inspections_Get_ById_V1_GetChecklistByIdResponse,
  Checklab_WebApi_Features_Languages_GetById_V1_GetLanguageByIdResponse,
  Checklab_WebApi_Features_Projects_Dtos_ProjectChecklistRuleDto,
  Checklab_WebApi_Features_Projects_Dtos_ProjectMetadataDto,
  Checklab_WebApi_Features_Projects_Dtos_ProjectChecklistDto,
  Checklab_WebApi_Features_Projects_GetById_V1_GetProjectByIdResponse,
  Core_Domain_Features_ActionWorkflows_Models_ActionWorkflowTemplateModel,
  Checklab_WebApi_Features_Templates_Checklists_GetById_V1_GetTemplateChecklistByIdResponse,
  Checklab_WebApi_Features_Templates_Groups_GetById_V1_GetTemplateGroupByIdResponse,
  Checklab_WebApi_Features_WorkInstructions_Dtos_WorkInstructionFileDto,
  Checklab_WebApi_Features_WorkInstructions_GetById_V1_GetWorkInstructionByIdResponse,
  Checklab_WebApi_Features_WorkInstructions_Files_Update_V1_UpdateWorkInstructionFileRequestBody,
  Core_Domain_Features_Workspaces_Models_WorkspaceModel,
  Checklab_WebApi_Features_Workspaces_Get_ById_V1_GetWorkspaceByIdResponse,
  Checklab_WebApi_Features_Inspections_Get_StateIndices_V1_GetAllInspectionStateIndicesResponse,
  Checklab_WebApi_Features_Templates_Dtos_TemplateGroupOverviewDto,
  Checklab_WebApi_Features_Templates_Checklists_GetAllGroupsById_V1_GetAllTemplateChecklistGroupsByIdResponse,
  Checklab_WebApi_Features_Workspaces_Get_Getall_V1_GetAllWorkspacesResponse,
  Checklab_WebApi_Features_Templates_Dtos_Translations_Checks_BarcodeTemplateCheckTranslationDto,
  Checklab_WebApi_Features_Templates_Checks_Barcode_GetTranslations_V1_GetBarcodeTemplateCheckTranslationsByIdResponse,
  Checklab_WebApi_Features_Publications_Inspections_GetByInspectionIdRangeAsZip_V1_GetByInspectionIdRangeAsZipResponse,
  Checklab_WebApi_Features_Publications_Checklists_GetByChecklistIdAndAccessToken_V1_GetChecklistPublicationByChecklistIdAndAccessTokenResponse,
  Core_Domain_Features_Publications_Checklist_Models_InspectionPublicationSasModel,
  Checklab_WebApi_Features_Publications_Checklists_GetByChecklistId_V1_GetChecklistPublicationByChecklistIdResponse,
  Checklab_WebApi_Features_Inspections_Dtos_ChecklistSearchDto,
  Checklab_WebApi_Features_Inspections_Get_BySerialNumber_V1_GetChecklistsBySerialNumberResponse,
  Checklab_WebApi_Features_Inspections_Get_SearchByIdRange_V1_GetChecklistSearchByIdRangeResponse,
  Checklab_WebApi_Features_Templates_Dtos_Translations_Checks_ChecklistTemplateCheckTranslationDto,
  Checklab_WebApi_Features_Templates_Checks_Checklist_GetTranslations_V1_GetChecklistTemplateCheckTranslationsByIdResponse,
  Checklab_WebApi_Features_Reporting_ErrorIndices_V1_GetErrorIndicesByPeriodResponse,
  Checklab_WebApi_Features_Publications_Inspections_GetByAccessToken_V1_GetInspectionPublicationByAccessTokenResponse,
  Checklab_WebApi_Features_Publications_Inspections_GetByInspectionId_V1_GetInspectionPublicationByInspectionIdResponse,
  Checklab_WebApi_Features_Languages_GetByCode_V1_GetLanguageByCodeResponse,
  Checklab_WebApi_Features_Locations_GetById_V1_GetLocationByIdResponse,
  Checklab_WebApi_Features_Templates_Dtos_Translations_Checks_NumberTemplateCheckTranslationDto,
  Checklab_WebApi_Features_Templates_Checks_Number_GetTranslations_V1_GetNumberTemplateCheckTranslationsByIdResponse,
  Checklab_WebApi_Features_Templates_Dtos_Translations_Checks_OkNokTemplateCheckTranslationDto,
  Checklab_WebApi_Features_Templates_Checks_OkNok_GetTranslations_V1_GetOkNokTemplateCheckTranslationsByIdResponse,
  Checklab_WebApi_Features_Templates_Dtos_Translations_Checks_PhotoTemplateCheckTranslationDto,
  Checklab_WebApi_Features_Templates_Checks_Photo_GetTranslations_V1_GetPhotoTemplateCheckTranslationsByIdResponse,
  Checklab_WebApi_Features_Reporting_ProductLabels_V1_GetProductLabelsResponse,
  Core_Domain_Features_Projects_Models_ProjectByInspectionIdModel,
  Checklab_WebApi_Features_Projects_GetByInspectionId_V1_GetProjectByInspectionIdResponse,
  Checklab_WebApi_Features_Projects_Dtos_ProjectDto,
  Checklab_WebApi_Features_Projects_GetByPartialCode_V1_GetProjectByPartialCodeResponse,
  Checklab_WebApi_Features_Publications_Projects_GetByAccessTokenAsZip_V1_GetProjectPublicationAsZipByAccessTokenResponse,
  Checklab_WebApi_Features_Publications_Projects_GetByProjectId_V1_GetProjectPublicationByProjectIdResponse,
  Checklab_WebApi_Features_Projects_GetRecent_V1_GetRecentProjectsResponse,
  Checklab_WebApi_Features_Reporting_Search_V1_ReportingCommentPhotoDto,
  Checklab_WebApi_Features_Reporting_Search_V1_ReportingCommentDto,
  Checklab_WebApi_Features_Reporting_Comments_V1_GetReportingCommentDto,
  Checklab_WebApi_Features_Reporting_Comments_V1_GetReportingCommentRangeResponse,
  Checklab_WebApi_Features_Reporting_Search_V1_ReportingCheckSearchDto,
  Checklab_WebApi_Features_Reporting_Search_V1_GetSearchResponse,
  Checklab_WebApi_Features_Inspections_Get_ByPartialSerialNumber_V1_GetSearchByPartialSerialNumberResponse,
  Checklab_WebApi_Features_Templates_Dtos_Translations_Checks_SignatureTemplateCheckTranslationDto,
  Checklab_WebApi_Features_Templates_Checks_Signature_GetTranslations_V1_GetSignatureTemplateCheckTranslationsByIdResponse,
  Checklab_WebApi_Features_Templates_Dtos_Translations_Checks_SingleChoiceListTemplateCheckTranslationDto,
  Checklab_WebApi_Features_Templates_Checks_SingleChoiceList_GetTranslations_V1_GetSingleChoiceListTemplateCheckTranslationsByIdResponse,
  Checklab_WebApi_Features_Templates_Dtos_Translations_Checks_SingleTextTemplateCheckTranslationDto,
  Checklab_WebApi_Features_Templates_Checks_SingleText_GetTranslations_V1_GetSingleTextTemplateCheckTranslationsByIdResponse,
  Checklab_WebApi_Features_Templates_Checklists_GetByProductLabel_V1_GetTemplateChecklistsByProductLabelResponse,
  Checklab_WebApi_Features_Templates_Dtos_TemplateChecklistSearchDto,
  Checklab_WebApi_Features_Templates_Checklists_GetSearchByIdRange_V1_GetTemplateChecklistSearchByIdRangeResponse,
  Checklab_WebApi_Features_Templates_Checklists_GetTranslationsById_V1_GetTemplateChecklistTranslationsByIdResponse,
  Checklab_WebApi_Features_Templates_Groups_GetTranslationsById_V1_GetTemplateGroupTranslationsResponse,
  Checklab_WebApi_Features_Workspaces_Get_ByClerkId_V1_GetWorkspaceByClerkIdResponse,
  Checklab_WebApi_Features_Templates_Dtos_Translations_Checks_YesNoTemplateCheckTranslationDto,
  Checklab_WebApi_Features_Templates_Checks_YesNo_GetTranslations_V1_GetYesNoTemplateCheckTranslationsByIdResponse,
  Checklab_WebApi_Features_Inspections_Checks_OkNok_SetValue_V1_OkNokInspectionCheckSetValueRequest,
  Checklist_Check_Photo_Add_Body,
  Checklab_WebApi_Features_Inspections_Checks_Photo_AddValue_V1_PhotoCheckAddPhotoResponse,
  Checklab_WebApi_Features_Inspections_Checks_Photo_DeleteValue_V1_PhotoCheckDeletePhotoResponse,
  Checklab_WebApi_Features_Publications_Projects_ResetAccessToken_V1_ResetAccessTokenRequest,
  Checklab_WebApi_Features_Publications_Projects_ResetAccessToken_V1_ResetAccessTokenResponse,
  Checklab_WebApi_Features_Publications_Checklists_ResetChecklistPublicationAccessToken_V1_ResetChecklistPublicationAccessTokenRequest,
  Checklab_WebApi_Features_Publications_Checklists_ResetChecklistPublicationAccessToken_V1_ResetChecklistPublicationAccessTokenResponse,
  Checklab_WebApi_Features_Inspections_Get_Search_V1_SearchInspectionsResponse,
  Core_Domain_Features_Translations_Languages_Models_LanguageModel,
  Checklab_WebApi_Features_Languages_Search_V1_SearchLanguageResponse,
  Checklab_WebApi_Features_Inspections_Checks_Signature_ResetValue_V1_SignatureCheckResetValueRequest,
  Checklist_Check_Signature_SetValue_Body,
  Checklab_WebApi_Features_Inspections_Checks_Signature_SetValue_V1_SignatureCheckSetValueResponse,
  Checklab_WebApi_Features_Inspections_Checks_SingleChoiceList_ResetValue_V1_SingleChoiceListCheckResetValue,
  Checklab_WebApi_Features_Inspections_Checks_SingleChoiceList_SetValue_V1_SingleChoiceListInspectionCheckSetValueRequest,
  Checklab_WebApi_Features_Templates_Checks_Barcode_UpdateTranslations_V1_UpdateBarcodeTemplateCheckTranslationsRequest,
  Checklab_WebApi_Features_Templates_Checks_Checklist_UpdateTranslations_V1_UpdateChecklistTemplateCheckTranslationsRequest,
  Checklab_WebApi_Features_Templates_Checks_Number_UpdateTranslations_V1_UpdateNumberTemplateCheckTranslationsRequest,
  Checklab_WebApi_Features_Templates_Checks_OkNok_UpdateTranslations_V1_UpdateOkNokTemplateCheckTranslationsRequest,
  Checklab_WebApi_Features_Templates_Checks_Photo_UpdateTranslations_V1_UpdatePhotoTemplateCheckTranslationsRequest,
  Checklab_WebApi_Features_Templates_Checks_Signature_UpdateTranslations_V1_UpdateSignatureTemplateCheckTranslationsRequest,
  Checklab_WebApi_Features_Templates_Checks_SingleChoiceList_UpdateTranslations_V1_UpdateOkNokTemplateCheckTranslationsRequest,
  Checklab_WebApi_Features_Templates_Checks_SingleText_UpdateTranslations_V1_UpdateSingleTextTemplateCheckTranslationsRequest,
  Checklab_WebApi_Features_Templates_Checklists_UpdateTranslations_V1_UpdateTemplateChecklistTranslationsRequest,
  Checklab_WebApi_Features_Templates_Checks_Locations_Update_V1_UpdateTemplateCheckLocationsRequest,
  Checklab_WebApi_Features_Templates_Checks_Tags_Update_V1_UpdateTemplateCheckTagsRequest,
  Checklab_WebApi_Features_Templates_Checks_WorkInstructions_V1_UpdateTemplateCheckWorkInstructionRequest,
  Checklab_WebApi_Features_Templates_Groups_UpdateChecksOrder_V1_UpdateTemplateGroupChecksOrderRequest,
  Checklab_WebApi_Features_Templates_Groups_UpdateTranslations_V1_UpdateTemplateGroupTranslationsRequest,
  Checklab_WebApi_Features_Templates_Checks_YesNo_UpdateTranslations_V1_UpdateYesNoTemplateCheckTranslationsRequest,
  Checklab_WebApi_Features_Inspections_Checks_YesNo_ResetValue_V1_YesNoCheckResetValueRequest,
  Checklab_WebApi_Features_Inspections_Checks_YesNo_SetValue_V1_YesNoInspectionCheckSetValueRequest,
};

export const ChecklistsEndpoints = makeApi([
  {
    method: "put",
    path: "/checklists/accept",
    description: `Set a checklist to the accepted state. This can only be done once all checks within each group are completed`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({ checklistId: z.number() }),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "put",
    path: "/checklists/accept-undo",
    description: `Set a checklist to the &#x27;needs approval&#x27; state.`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({ checklistId: z.number() }),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "post",
    path: "/checklists/by-aliases",
    description: `Create a checklist based on the given template group aliases`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Inspections_CreateByAliases_V1_CreateChecklistByAliasesRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_Inspections_CreateByAliases_V1_CreateChecklistByAliasesResponse,
  },
  {
    method: "post",
    path: "/management/checklists",
    description: `Create a new checklist based on a template`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Inspections_CreateManagement_V1_CreateChecklistRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_Inspections_CreateManagement_V1_CreateChecklistResponse,
  },
  {
    method: "post",
    path: "/checklists",
    description: `Create a new checklist based on a template`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Inspections_Create_V1_CreateChecklistRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_Inspections_Create_V1_CreateChecklistResponse,
  },
  {
    method: "delete",
    path: "/checklists/:checklistUid/groups/:groupUid/checks/:checkUid",
    description: `Delete a specific check from a checklist`,
    requestFormat: "json",
    parameters: [
      {
        name: "checklistUid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "groupUid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "checkUid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "delete",
    path: "/checklists/:checklistId",
    description: `Delete a specific checklists and all of the linked data`,
    requestFormat: "json",
    parameters: [
      {
        name: "checklistId",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "get",
    path: "/checklists/:checklistId",
    description: `Get a specific Checklist`,
    requestFormat: "json",
    parameters: [
      {
        name: "checklistId",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "language",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_Inspections_Get_ById_V1_GetChecklistByIdResponse,
  },
  {
    method: "get",
    path: "/checklists/serialnumbers/:checklistSerialNumber",
    description: `Get a specific checklist by searching for its serial number`,
    requestFormat: "json",
    parameters: [
      {
        name: "checklistSerialNumber",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_Inspections_Get_BySerialNumber_V1_GetChecklistsBySerialNumberResponse,
  },
  {
    method: "get",
    path: "/checklists/uids",
    description: `Get search metadata fo all checklists that match the ids given`,
    requestFormat: "json",
    parameters: [
      {
        name: "uids",
        type: "Query",
        schema: z.array(z.string()),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_Inspections_Get_SearchByIdRange_V1_GetChecklistSearchByIdRangeResponse,
  },
  {
    method: "get",
    path: "/checklists/serialnumbers/search",
    description: `Get a list of serial numbers that belong to existing checklists by a partial serial number accompanied by information like template name, completion percentage and current state.`,
    requestFormat: "json",
    parameters: [
      {
        name: "partialSerialNumber",
        type: "Query",
        schema: z.string(),
      },
      {
        name: "page",
        type: "Query",
        schema: z.number().int().gte(1).lte(2147483647).default(1),
      },
      {
        name: "size",
        type: "Query",
        schema: z.number().int().gte(1).lte(100).optional().default(25),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_Inspections_Get_ByPartialSerialNumber_V1_GetSearchByPartialSerialNumberResponse,
  },
]);

export const ChecklistsApi = new Zodios(ChecklistsEndpoints);

export const ProjectsEndpoints = makeApi([
  {
    method: "put",
    path: "/projects/checklists",
    description: `Link an existing checklist to an existing project by matching both ids`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Projects_AddChecklist_V1_AddProjectChecklistRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "delete",
    path: "/projects/checklists",
    description: `Unlink an existing checklist from an existing project by un matching both ids`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Projects_DeleteChecklist_V1_DeleteProjectChecklistRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "post",
    path: "/projects",
    description: `Create a new project using a project Code`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Projects_Create_V1_CreateProjectRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: Checklab_WebApi_Features_Projects_Create_V1_CreateProjectResponse,
  },
  {
    method: "put",
    path: "/projects",
    description: `Update a project using a project Code`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Projects_Update_V1_UpdateProjectRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: Checklab_WebApi_Features_Projects_Update_V1_UpdateProjectResponse,
  },
  {
    method: "delete",
    path: "/projects/:projectId",
    description: `Delete an existing project`,
    requestFormat: "json",
    parameters: [
      {
        name: "projectId",
        type: "Path",
        schema: z.number().int(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "get",
    path: "/projects/:projectId",
    description: `Get a project by its unique id`,
    requestFormat: "json",
    parameters: [
      {
        name: "projectId",
        type: "Path",
        schema: z.number().int(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_Projects_GetById_V1_GetProjectByIdResponse,
  },
  {
    method: "get",
    path: "/projects/by-inspection/:inspectionIdentifier",
    requestFormat: "json",
    parameters: [
      {
        name: "inspectionIdentifier",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_Projects_GetByInspectionId_V1_GetProjectByInspectionIdResponse,
  },
  {
    method: "get",
    path: "/projects/code/search",
    description: `Get a list of existing projects that match the provided (partial) project code. Searching is NOT case sensitive`,
    requestFormat: "json",
    parameters: [
      {
        name: "partialCode",
        type: "Query",
        schema: z.string().min(1),
      },
      {
        name: "page",
        type: "Query",
        schema: z.number().int().gte(1).lte(2147483647).default(1),
      },
      {
        name: "size",
        type: "Query",
        schema: z.number().int().gte(1).lte(100).optional().default(25),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_Projects_GetByPartialCode_V1_GetProjectByPartialCodeResponse,
  },
  {
    method: "get",
    path: "/projects/recent",
    description: `Get a paginated list of recent projects`,
    requestFormat: "json",
    parameters: [
      {
        name: "page",
        type: "Query",
        schema: z.number().int().gte(1).lte(2147483647).default(1),
      },
      {
        name: "size",
        type: "Query",
        schema: z.number().int().gte(1).lte(100).optional().default(25),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_Projects_GetRecent_V1_GetRecentProjectsResponse,
  },
]);

export const ProjectsApi = new Zodios(ProjectsEndpoints);

export const Action_WorkflowsEndpoints = makeApi([
  {
    method: "post",
    path: "/templates/checks/:checkId/action-workflows",
    description: `Add action workflow to a template check`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({ actionWorkflowId: z.number().int() }).partial(),
      },
      {
        name: "checkId",
        type: "Path",
        schema: z.number().int(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "post",
    path: "/templates/checklists/:checklistId/action-workflows",
    description: `Add action workflow to a template checklist`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({ actionWorkflowId: z.number().int() }).partial(),
      },
      {
        name: "checklistId",
        type: "Path",
        schema: z.number().int(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "post",
    path: "/action-workflows",
    description: `Create a new Action Workflow that gets executed on the defined trigger`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_ActionWorkflows_Create_V1_CreateActionWorkflowRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: z.object({ id: z.number().int() }).partial(),
  },
  {
    method: "get",
    path: "/action-workflows",
    description: `Get a paginated range of existing action workflows that match all given search criteria`,
    requestFormat: "json",
    parameters: [
      {
        name: "title",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "description",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "workflowTrigger",
        type: "Query",
        schema: z
          .union([
            z.literal(1),
            z.literal(2),
            z.literal(3),
            z.literal(4),
            z.literal(5),
            z.literal(6),
            z.literal(7),
            z.literal(8),
          ])
          .optional(),
      },
      {
        name: "page",
        type: "Query",
        schema: z.number().int().gte(1).lte(2147483647).default(1),
      },
      {
        name: "size",
        type: "Query",
        schema: z.number().int().gte(1).lte(100).optional().default(25),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_ActionWorkflows_GetAll_V1_GetActionWorkflowRangeResponse,
  },
  {
    method: "put",
    path: "/action-workflows",
    description: `Update the direct values of an existing action workflow`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_ActionWorkflows_Update_V1_UpdateActionWorkflowRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: z.object({ id: z.number().int() }).partial(),
  },
  {
    method: "post",
    path: "/action-workflows/:actionWorkflowId/configurations/publish-iot-message",
    description: `Create and attach a new publish IoT message action configuration to an existing workflow`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_ActionWorkflows_CreateConfiguration_V1_CreatePublishIoTMessageActionConfigurationRequestBody,
      },
      {
        name: "actionWorkflowId",
        type: "Path",
        schema: z.number().int(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: z.object({}).partial(),
  },
  {
    method: "post",
    path: "/action-workflows/:actionWorkflowId/configurations/send-check-email",
    description: `Create and attach a new send check email action configuration to an existing workflow`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_ActionWorkflows_CreateConfiguration_V1_CreateSendCheckEmailActionConfigurationRequestBody,
      },
      {
        name: "actionWorkflowId",
        type: "Path",
        schema: z.number().int(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: z.object({}).partial(),
  },
  {
    method: "post",
    path: "/action-workflows/:actionWorkflowId/configurations/send-checklist-email",
    description: `Create and attach a new send checklist email action configuration to an existing workflow`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_ActionWorkflows_CreateConfiguration_V1_CreateSendChecklistEmailActionConfigurationRequestBody,
      },
      {
        name: "actionWorkflowId",
        type: "Path",
        schema: z.number().int(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: z.object({}).partial(),
  },
  {
    method: "delete",
    path: "/action-workflows/:actionWorkflowId/configurations/:actionConfigurationId",
    description: `Remove an action configuration from a workflow`,
    requestFormat: "json",
    parameters: [
      {
        name: "actionWorkflowId",
        type: "Path",
        schema: z.number().int(),
      },
      {
        name: "actionConfigurationId",
        type: "Path",
        schema: z.number().int(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "delete",
    path: "/action-workflows/:actionWorkflowId",
    description: `Delete an existing action workflow`,
    requestFormat: "json",
    parameters: [
      {
        name: "actionWorkflowId",
        type: "Path",
        schema: z.number().int(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "get",
    path: "/action-workflows/:actionWorkflowId",
    description: `Get an action workflow by its unique id`,
    requestFormat: "json",
    parameters: [
      {
        name: "actionWorkflowId",
        type: "Path",
        schema: z.number().int(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_ActionWorkflows_GetById_V1_GetActionWorkflowByIdResponse,
  },
  {
    method: "delete",
    path: "/templates/checks/:checkId/action-workflows/:actionWorkflowId",
    description: `Delete action workflow to a template check`,
    requestFormat: "json",
    parameters: [
      {
        name: "checkId",
        type: "Path",
        schema: z.number().int(),
      },
      {
        name: "actionWorkflowId",
        type: "Path",
        schema: z.number().int(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "delete",
    path: "/templates/checklists/:checklistId/action-workflows/:actionWorkflowId",
    description: `Delete action workflow from a template checklist`,
    requestFormat: "json",
    parameters: [
      {
        name: "checklistId",
        type: "Path",
        schema: z.number().int(),
      },
      {
        name: "actionWorkflowId",
        type: "Path",
        schema: z.number().int(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
]);

export const Action_WorkflowsApi = new Zodios(Action_WorkflowsEndpoints);
const Checklab_WebApi_Features_Inspections_Checks_Photo_SetInApplicable_V1_PhotoInspectionCheckSetInApplicableRequest =
  z
    .object({
      checklistId: z.number().int(),
      groupUid: z.string().uuid(),
      checkUid: z.string().uuid(),
    })
    .partial();
const Checklab_WebApi_Features_Inspections_Checks_Photo_ResetValue_V1_PhotoCheckResetValueRequest =
  z
    .object({
      checklistId: z.number().int(),
      groupUid: z.string().uuid(),
      checkUid: z.string().uuid(),
    })
    .partial();
export const ChecksEndpoints2 = makeApi([
  {
    method: "put",
    path: "/checklists/groups/checks/photo/value/reset",
    alias: "Checklist.Check.Photo.ResetValue",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Inspections_Checks_Photo_ResetValue_V1_PhotoCheckResetValueRequest,
      },
      {
        name: "x-Api-Version",
        type: "Header",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "post",
    path: "/checklists/groups/checks/photo/in-applicable",
    alias: "Checklist.Check.Photo.SetInApplicable",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Inspections_Checks_Photo_SetInApplicable_V1_PhotoInspectionCheckSetInApplicableRequest,
      },
      {
        name: "x-Api-Version",
        type: "Header",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "post",
    path: "/checklists/groups/checks/signature/in-applicable",
    alias: "Checklist.Check.Signature.SetInApplicable",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Inspections_Checks_SetInApplicable_V1_BarcodeInspectionCheckSetInApplicableRequest,
      },
      {
        name: "x-Api-Version",
        type: "Header",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "put",
    path: "/checklists/groups/checks/signature/value",
    alias: "Checklist.Check.Signature.SetValue",
    requestFormat: "form-data",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: Checklist_Check_Signature_SetValue_Body,
      },
      {
        name: "x-Api-Version",
        type: "Header",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_Inspections_Checks_Signature_SetValue_V1_SignatureCheckSetValueResponse,
  },
  {
    method: "put",
    path: "/checklists/groups/checks/signature/value/reset",
    alias: "Checklist.Check.Signature.ResetValue",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Inspections_Checks_Signature_ResetValue_V1_SignatureCheckResetValueRequest,
      },
      {
        name: "x-Api-Version",
        type: "Header",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "post",
    path: "/checklists/groups/checks/barcode/in-applicable",
    alias: "Checklist.Check.Barcode.SetInApplicable",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Inspections_Checks_SetInApplicable_V1_BarcodeInspectionCheckSetInApplicableRequest,
      },
      {
        name: "x-Api-Version",
        type: "Header",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "put",
    path: "/checklists/groups/checks/barcode/value",
    alias: "Checklist.Check.Barcode.SetValue",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Inspections_Checks_Barcode_SetValue_V1_BarcodeInspectionCheckSetValueRequest,
      },
      {
        name: "x-Api-Version",
        type: "Header",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "put",
    path: "/checklists/groups/checks/barcode/value/reset",
    alias: "Checklist.Check.Barcode.ResetValue",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Inspections_Checks_Barcode_ResetValue_V1_BarcodeCheckResetValueRequest,
      },
      {
        name: "x-Api-Version",
        type: "Header",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "post",
    path: "/checklists/groups/checks/single-choice-list/in-applicable",
    alias: "Checklist.Check.SingleChoiceList.SetInApplicable",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Inspections_Checks_SetInApplicable_V1_BarcodeInspectionCheckSetInApplicableRequest,
      },
      {
        name: "x-Api-Version",
        type: "Header",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "put",
    path: "/checklists/groups/checks/single-text/value",
    alias: "Checklist.Check.SingleText.SetValue",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Inspections_Checks_SingleText_SetValue_V1_SingleTextInspectionCheckSetValueRequest,
      },
      {
        name: "x-Api-Version",
        type: "Header",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "put",
    path: "/checklists/groups/checks/single-text/value/reset",
    alias: "Checklist.Check.SingleText.ResetValue",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Inspections_Checks_SingleText_ResetValue_V1_SingleTextCheckResetValueRequest,
      },
      {
        name: "x-Api-Version",
        type: "Header",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "post",
    path: "/checklists/groups/checks/single-text/in-applicable",
    alias: "Checklist.Check.SingleText.SetInApplicable",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Inspections_Checks_SetInApplicable_V1_BarcodeInspectionCheckSetInApplicableRequest,
      },
      {
        name: "x-Api-Version",
        type: "Header",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
]);

export const ChecksEndpoints = makeApi([
  {
    method: "put",
    path: "/checklists/groups/checks/barcode/value",
    requestFormat: "json",
    parameters: [
      {
        name: "checklistUid",
        type: "Query",
        schema: z.string().uuid().optional(),
      },
      {
        name: "groupUid",
        type: "Query",
        schema: z.string().uuid().optional(),
      },
      {
        name: "checkUid",
        type: "Query",
        schema: z.string().uuid().optional(),
      },
      {
        name: "barcode",
        type: "Query",
        schema: z.string().max(256).optional(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "put",
    path: "/checklists/groups/checks/checklist/value",
    requestFormat: "json",
    parameters: [
      {
        name: "checklistUid",
        type: "Query",
        schema: z.string().uuid().optional(),
      },
      {
        name: "groupUid",
        type: "Query",
        schema: z.string().uuid().optional(),
      },
      {
        name: "checkUid",
        type: "Query",
        schema: z.string().uuid().optional(),
      },
      {
        name: "serialNumber",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "checklistIdentifier",
        type: "Query",
        schema: z.string().uuid().optional(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "put",
    path: "/checklists/groups/checks/value",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Inspections_Checks_CheckSetValue_V1_CheckSetValueRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_Inspections_Checks_CheckSetValue_V1_CheckSetValueResponse,
  },
  {
    method: "post",
    path: "/checklists/groups/checks/comments",
    description: `Create a comment for a specific check. Can be supplemented with photos`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Inspections_Checks_Comments_Create_V1_CreateCommentRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: z.object({ commentUid: z.string().uuid() }).partial(),
  },
  {
    method: "put",
    path: "/checklists/groups/checks/comments",
    description: `Update a comment for a specific check`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Inspections_Checks_Comments_Update_V1_UpdateCommentRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "delete",
    path: "/checklists/:checklistUid/groups/:groupUid/checks/:checkUid/comments/:commentUid",
    description: `Remove a comment from a check. This will also destroy all the attached photos`,
    requestFormat: "json",
    parameters: [
      {
        name: "checklistUid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "groupUid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "checkUid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "commentUid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  // {
  //   method: "put",
  //   path: "/checklists/groups/checks/ok-nok/value",
  //   requestFormat: "json",
  //   parameters: [
  //     {
  //       name: "body",
  //       type: "Body",
  //       schema:
  //         Checklab_WebApi_Features_Inspections_Checks_OkNok_SetValue_V1_OkNokInspectionCheckSetValueRequest,
  //     },
  //     {
  //       name: "api-version",
  //       type: "Query",
  //       schema: z.string().optional(),
  //     },
  //   ],
  //   response: DiscEdge_WebApi_Responses_DetailedResponse,
  // },
  {
    method: "post",
    path: "/checklists/groups/checks/photo",
    requestFormat: "form-data",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: Checklist_Check_Photo_Add_Body,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_Inspections_Checks_Photo_AddValue_V1_PhotoCheckAddPhotoResponse,
  },
  {
    method: "put",
    path: "/checklists/:checklistId/groups/:groupUid/checks/:checkUid/photo/:photoUid",
    requestFormat: "json",
    parameters: [
      {
        name: "checklistId",
        type: "Path",
        schema: z.number(),
      },
      {
        name: "groupUid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "checkUid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "photoUid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_Inspections_Checks_Photo_DeleteValue_V1_PhotoCheckDeletePhotoResponse,
  },
  {
    method: "put",
    path: "/checklists/groups/checks/signature/value/reset",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Inspections_Checks_Signature_ResetValue_V1_SignatureCheckResetValueRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "put",
    path: "/checklists/groups/checks/signature/value",
    requestFormat: "form-data",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: Checklist_Check_Signature_SetValue_Body,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_Inspections_Checks_Signature_SetValue_V1_SignatureCheckSetValueResponse,
  },
  {
    method: "put",
    path: "/checklists/groups/checks/single-choice-list/value/reset",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Inspections_Checks_SingleChoiceList_ResetValue_V1_SingleChoiceListCheckResetValue,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "put",
    path: "/checklists/groups/checks/single-choice-list/value",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Inspections_Checks_SingleChoiceList_SetValue_V1_SingleChoiceListInspectionCheckSetValueRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "put",
    path: "/checklists/groups/checks/yes-no/value/reset",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Inspections_Checks_YesNo_ResetValue_V1_YesNoCheckResetValueRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "post",
    path: "/checklists/groups/checks/yes-no/in-applicable",
    alias: "Checklist.Check.YesNo.SetInApplicable",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Inspections_Checks_SetInApplicable_V1_BarcodeInspectionCheckSetInApplicableRequest,
      },
      {
        name: "x-Api-Version",
        type: "Header",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "put",
    path: "/checklists/groups/checks/yes-no/value",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Inspections_Checks_YesNo_SetValue_V1_YesNoInspectionCheckSetValueRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "post",
    path: "/checklists/groups/checks/single-text/in-applicable",
    alias: "Checklist.Check.SingleText.SetInApplicable",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Inspections_Checks_SetInApplicable_V1_BarcodeInspectionCheckSetInApplicableRequest,
      },
      {
        name: "x-Api-Version",
        type: "Header",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "put",
    path: "/checklists/groups/checks/number/value",
    alias: "Checklist.Check.Number.SetValue",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Inspections_Checks_Number_SetValue_V1_NumberInspectionCheckSetValueRequest,
      },
      {
        name: "x-Api-Version",
        type: "Header",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "post",
    path: "/checklists/groups/checks/number/in-applicable",
    alias: "Checklist.Check.Number.SetInApplicable",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Inspections_Checks_SetInApplicable_V1_BarcodeInspectionCheckSetInApplicableRequest,
      },
      {
        name: "x-Api-Version",
        type: "Header",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "put",
    path: "/checklists/groups/checks/number/value/reset",
    alias: "Checklist.Check.Number.ResetValue",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Inspections_Checks_Number_ResetValue_V1_NumberCheckResetValueRequest,
      },
      {
        name: "x-Api-Version",
        type: "Header",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "post",
    path: "/checklists/groups/checks/ok-nok/in-applicable",
    alias: "Checklist.Check.OkNok.SetInApplicable",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Inspections_Checks_SetInApplicable_V1_BarcodeInspectionCheckSetInApplicableRequest,
      },
      {
        name: "x-Api-Version",
        type: "Header",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "put",
    path: "/checklists/groups/checks/ok-nok/value",
    alias: "Checklist.Check.OkNok.SetValue",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Inspections_Checks_OkNok_SetValue_V1_OkNokInspectionCheckSetValueRequest,
      },
      {
        name: "x-Api-Version",
        type: "Header",
        schema: z.string().optional(),
      },
    ],
    // response: DiscEdge_WebApi_Responses_DetailedResponse,
    response: z.any(),
  },
]);

export const ChecksApi = new Zodios(ChecksEndpoints);

export const DashboardEndpoints = makeApi([
  {
    method: "get",
    path: "/dashboard/recovered-by-tag",
    requestFormat: "json",
    parameters: [
      {
        name: "startDateTime",
        type: "Query",
        schema: z.string(),
      },
      {
        name: "endDateTime",
        type: "Query",
        schema: z.string(),
      },
      {
        name: "productLabels",
        type: "Query",
        schema: z.array(z.string()).optional(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_Reporting_ChecksCountByTag_V1_ChecksCountByTagResponse,
  },
  {
    method: "get",
    path: "/dashboard/error-indices",
    description: `Get the statistics for recoveries made on checklists during the specified period`,
    requestFormat: "json",
    parameters: [
      {
        name: "from",
        type: "Query",
        schema: z.string(),
      },
      {
        name: "to",
        type: "Query",
        schema: z.string(),
      },
      {
        name: "productLabels",
        type: "Query",
        schema: z.array(z.string()).optional(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_Reporting_ErrorIndices_V1_GetErrorIndicesByPeriodResponse,
  },
  {
    method: "get",
    path: "/dashboard/productlabels",
    requestFormat: "json",
    parameters: [
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_Reporting_ProductLabels_V1_GetProductLabelsResponse,
  },
]);

export const DashboardApi = new Zodios(DashboardEndpoints);

export const Api_KeysEndpoints = makeApi([
  {
    method: "post",
    path: "/api-keys",
    description: `Create a new api key for the given workspace`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: Checklab_WebApi_Features_ApiKeys_Create_V1_CreateApiKeyRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: Checklab_WebApi_Features_ApiKeys_Create_V1_CreateApiKeyResponse,
  },
  {
    method: "get",
    path: "/api-keys",
    description: `Get a list of exising api keys, this list can be filters on the name`,
    requestFormat: "json",
    parameters: [
      {
        name: "name",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "page",
        type: "Query",
        schema: z.number().int().gte(1).lte(2147483647).default(1),
      },
      {
        name: "size",
        type: "Query",
        schema: z.number().int().gte(1).lte(100).optional().default(25),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: Checklab_WebApi_Features_ApiKeys_GetAll_V1_GetAllApiKeysResponse,
  },
  {
    method: "delete",
    path: "/api-keys/:apiKeyId",
    description: `Delete an existing api key`,
    requestFormat: "json",
    parameters: [
      {
        name: "apiKeyId",
        type: "Path",
        schema: z.number().int(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "get",
    path: "/api-keys/:apiKeyId",
    description: `Get an existing api key by it id`,
    requestFormat: "json",
    parameters: [
      {
        name: "apiKeyId",
        type: "Path",
        schema: z.number().int().gte(1).lte(2147483647),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: Checklab_WebApi_Features_ApiKeys_GetById_V1_GetApiKeyByIdResponse,
  },
]);

export const Api_KeysApi = new Zodios(Api_KeysEndpoints);

export const Template_ChecksEndpoints = makeApi([
  {
    method: "post",
    path: "/templates/checks/barcode",
    description: `Create a new Barcode Check`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Templates_Checks_Barcode_Create_V1_CreateBarcodeTemplateCheckRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: z.object({ templateCheckId: z.number().int() }).partial(),
  },
  {
    method: "put",
    path: "/templates/checks/barcode",
    description: `Update a specific template Check`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Templates_Checks_Barcode_Update_V1_UpdateBarcodeTemplateCheckRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "post",
    path: "/templates/checks/checklist",
    description: `Create a new Checklist Check`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Templates_Checks_Checklist_Create_V1_CreateChecklistTemplateCheckRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: z.object({ templateCheckId: z.number().int() }).partial(),
  },
  {
    method: "put",
    path: "/templates/checks/checklist",
    description: `Update a specific template Check`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Templates_Checks_Checklist_Update_V1_UpdateChecklistTemplateCheckRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "post",
    path: "/templates/checks/number",
    description: `Create a new Number Check`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Templates_Checks_Number_Create_V1_CreateNumberTemplateCheckRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: z.object({ templateCheckId: z.number().int() }).partial(),
  },
  {
    method: "put",
    path: "/templates/checks/number",
    description: `Update a specific template Check`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Templates_Checks_Number_Update_V1_UpdateNumberTemplateCheckRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "post",
    path: "/templates/checks/ok-nok",
    description: `Create a new OkNok Check`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Templates_Checks_OkNok_Create_V1_CreateOkNokTemplateCheckRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: z.object({ templateCheckId: z.number().int() }).partial(),
  },
  {
    method: "put",
    path: "/templates/checks/ok-nok",
    description: `Update a specific template Check`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Templates_Checks_OkNok_Update_V1_UpdateOkNokTemplateCheckRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "post",
    path: "/templates/checks/photo",
    description: `Create a new Photo Check`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Templates_Checks_Photo_Create_V1_CreatePhotoTemplateCheckRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: z.object({ templateCheckId: z.number().int() }).partial(),
  },
  {
    method: "put",
    path: "/templates/checks/photo",
    description: `Update a specific template Check`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Templates_Checks_Photo_Update_V1_UpdatePhotoTemplateCheckRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "post",
    path: "/templates/checks/signature",
    description: `Create a new Signature Check`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Templates_Checks_Signature_Create_V1_CreateSignatureTemplateCheckRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: z.object({ templateCheckId: z.number().int() }).partial(),
  },
  {
    method: "put",
    path: "/templates/checks/signature",
    description: `Update a specific template Check`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Templates_Checks_Signature_Update_V1_UpdateSignatureTemplateCheckRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "post",
    path: "/templates/checks/single-choice-list",
    description: `Create a new SingeChoiceList Check`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Templates_Checks_SingleChoiceList_Create_V1_CreateSingleChoiceListTemplateCheckRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: z.object({ templateCheckId: z.number().int() }).partial(),
  },
  {
    method: "put",
    path: "/templates/checks/single-choice-list",
    description: `Update a specific template Check`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Templates_Checks_SingleChoiceList_Update_V1_UpdateSingleChoiceListTemplateCheckRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "post",
    path: "/templates/checks/single-text",
    description: `Create a new SingleText Check`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Templates_Checks_SingleText_Create_V1_CreateSingleTextTemplateCheckRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: z.object({ templateCheckId: z.number().int() }).partial(),
  },
  {
    method: "put",
    path: "/templates/checks/single-text",
    description: `Update a specific template Check`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Templates_Checks_SingleText_Update_V1_UpdateSingleTextTemplateCheckRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "post",
    path: "/templates/checks/yes-no",
    description: `Create a new YesNo Check`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Templates_Checks_YesNo_Create_V1_CreateYesNoTemplateCheckRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: z.object({ templateCheckId: z.number().int() }).partial(),
  },
  {
    method: "put",
    path: "/templates/checks/yes-no",
    description: `Update a specific template Check`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Templates_Checks_YesNo_Update_V1_UpdateYesNoTemplateCheckRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "delete",
    path: "/templates/checks/:templateCheckId",
    description: `Delete a specific template Check`,
    requestFormat: "json",
    parameters: [
      {
        name: "templateCheckId",
        type: "Path",
        schema: z.number().int(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "get",
    path: "/templates/checks/:templateCheckId",
    description: `Get a specific Template Check`,
    requestFormat: "json",
    parameters: [
      {
        name: "templateCheckId",
        type: "Path",
        schema: z.number().int(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: Checklab_WebApi_Features_Templates_Dtos_TemplateCheckDto,
  },
  {
    method: "get",
    path: "/templates/checks/barcode/:id/translations",
    description: `Get all translatable fields for a specific Barcode Template Check`,
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: z.number().int(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_Templates_Checks_Barcode_GetTranslations_V1_GetBarcodeTemplateCheckTranslationsByIdResponse,
  },
  {
    method: "get",
    path: "/templates/checks/checklist/:id/translations",
    description: `Get all translatable fields for a specific Checklist Template Check`,
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: z.number().int(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_Templates_Checks_Checklist_GetTranslations_V1_GetChecklistTemplateCheckTranslationsByIdResponse,
  },
  {
    method: "get",
    path: "/templates/checks/number/:id/translations",
    description: `Get all translatable fields for a specific Number Template Check`,
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: z.number().int(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_Templates_Checks_Number_GetTranslations_V1_GetNumberTemplateCheckTranslationsByIdResponse,
  },
  {
    method: "get",
    path: "/templates/checks/ok-nok/:id/translations",
    description: `Get all translatable fields for a specific OkNok Template Check`,
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: z.number().int(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_Templates_Checks_OkNok_GetTranslations_V1_GetOkNokTemplateCheckTranslationsByIdResponse,
  },
  {
    method: "get",
    path: "/templates/checks/photo/:id/translations",
    description: `Get all translatable fields for a specific Photo Template Check`,
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: z.number().int(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_Templates_Checks_Photo_GetTranslations_V1_GetPhotoTemplateCheckTranslationsByIdResponse,
  },
  {
    method: "get",
    path: "/templates/checks/signature/:id/translations",
    description: `Get all translatable fields for a specific Signature Template Check`,
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: z.number().int(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_Templates_Checks_Signature_GetTranslations_V1_GetSignatureTemplateCheckTranslationsByIdResponse,
  },
  {
    method: "get",
    path: "/templates/checks/single-choice-list/:id/translations",
    description: `Get all translatable fields for a specific SingleChoiceList Template Check`,
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: z.number().int(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_Templates_Checks_SingleChoiceList_GetTranslations_V1_GetSingleChoiceListTemplateCheckTranslationsByIdResponse,
  },
  {
    method: "get",
    path: "/templates/checks/single-text/:id/translations",
    description: `Get all translatable fields for a specific SingleText Template Check`,
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: z.number().int(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_Templates_Checks_SingleText_GetTranslations_V1_GetSingleTextTemplateCheckTranslationsByIdResponse,
  },
  {
    method: "get",
    path: "/templates/checks/yes-no/:id/translations",
    description: `Get all translatable fields for a specific YesNo Template Check`,
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: z.number().int(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_Templates_Checks_YesNo_GetTranslations_V1_GetYesNoTemplateCheckTranslationsByIdResponse,
  },
  {
    method: "put",
    path: "/templates/checks/barcode/translations",
    description: `Update the translations of a specific Barcode Template Check`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Templates_Checks_Barcode_UpdateTranslations_V1_UpdateBarcodeTemplateCheckTranslationsRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "put",
    path: "/templates/checks/checklist/translations",
    description: `Update the translations of a specific Checklist Template Check`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Templates_Checks_Checklist_UpdateTranslations_V1_UpdateChecklistTemplateCheckTranslationsRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "put",
    path: "/templates/checks/number/translations",
    description: `Update the translations of a specific Number Template Check`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Templates_Checks_Number_UpdateTranslations_V1_UpdateNumberTemplateCheckTranslationsRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "put",
    path: "/templates/checks/ok-nok/translations",
    description: `Update the translations of a specific OkNok Template Check`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Templates_Checks_OkNok_UpdateTranslations_V1_UpdateOkNokTemplateCheckTranslationsRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "put",
    path: "/templates/checks/photo/translations",
    description: `Update the translations of a specific Photo Template Check`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Templates_Checks_Photo_UpdateTranslations_V1_UpdatePhotoTemplateCheckTranslationsRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "put",
    path: "/templates/checks/signature/translations",
    description: `Update the translations of a specific Signature Template Check`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Templates_Checks_Signature_UpdateTranslations_V1_UpdateSignatureTemplateCheckTranslationsRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "put",
    path: "/templates/checks/single-choice-list/translations",
    description: `Update the translations of a specific SingleChoiceList Template Check`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Templates_Checks_SingleChoiceList_UpdateTranslations_V1_UpdateOkNokTemplateCheckTranslationsRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "put",
    path: "/templates/checks/single-text/translations",
    description: `Update the translations of a specific SingleText Template Check`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Templates_Checks_SingleText_UpdateTranslations_V1_UpdateSingleTextTemplateCheckTranslationsRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "put",
    path: "/templates/checks/yes-no/translations",
    description: `Update the translations of a specific YesNo Template Check`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Templates_Checks_YesNo_UpdateTranslations_V1_UpdateYesNoTemplateCheckTranslationsRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
]);

export const Template_ChecksApi = new Zodios(Template_ChecksEndpoints);

export const CommentsEndpoints = makeApi([
  {
    method: "post",
    path: "/checklists/groups/checks/comments/photos",
    description: `Add a set of photos to a specific comment. This set can also consist out of a single photo.`,
    requestFormat: "form-data",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: Checklist_Check_Comment_Photo_Create_Body,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_Inspections_Checks_Comments_Photos_Create_V1_CreateCommentPhotosResponse,
  },
  {
    method: "delete",
    path: "/checklists/:checklistUid/groups/:groupUid/checks/:checkUid/comments/:commentUid/photos/:photoUid",
    description: `Delete a photo that belongs to a comment`,
    requestFormat: "json",
    parameters: [
      {
        name: "checklistUid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "groupUid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "checkUid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "commentUid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "photoUid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
]);

export const CommentsApi = new Zodios(CommentsEndpoints);

export const LanguagesEndpoints = makeApi([
  {
    method: "post",
    path: "/languages",
    description: `Create a new language`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Languages_Create_V1_CreateLanguageRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: z.object({ id: z.number().int() }).partial(),
  },
  {
    method: "put",
    path: "/languages",
    description: `Update a Language`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Languages_Update_V1_UpdateLanguageRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: z.void(),
  },
  {
    method: "delete",
    path: "/languages/:id",
    description: `Delete a Language`,
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: z.number().int(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "get",
    path: "/languages/:id",
    description: `Get a Language by its ID`,
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: z.number().int(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_Languages_GetById_V1_GetLanguageByIdResponse,
  },
  {
    method: "get",
    path: "/languages/code/:code",
    description: `Get a Language by its code`,
    requestFormat: "json",
    parameters: [
      {
        name: "code",
        type: "Path",
        schema: z.string().min(1).max(20),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_Languages_GetByCode_V1_GetLanguageByCodeResponse,
  },
  {
    method: "get",
    path: "/languages/search",
    description: `Search existing Languages`,
    requestFormat: "json",
    parameters: [
      {
        name: "ids",
        type: "Query",
        schema: z.array(z.number()).optional(),
      },
      {
        name: "name",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "codes",
        type: "Query",
        schema: z.array(z.string()).optional(),
      },
      {
        name: "page",
        type: "Query",
        schema: z.number().int().gte(1).lte(2147483647).default(1),
      },
      {
        name: "size",
        type: "Query",
        schema: z.number().int().gte(1).lte(100).optional().default(25),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_Languages_Search_V1_SearchLanguageResponse,
  },
]);

export const LanguagesApi = new Zodios(LanguagesEndpoints);

export const LocationsEndpoints = makeApi([
  {
    method: "post",
    path: "/locations",
    description: `Create a new location`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Locations_Create_V1_CreateLocationRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: z.object({ locationId: z.number().int() }).partial(),
  },
  {
    method: "get",
    path: "/locations",
    description: `Return location in a paginated manner `,
    requestFormat: "json",
    parameters: [
      {
        name: "name",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "description",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "page",
        type: "Query",
        schema: z.number().int().gte(1).lte(2147483647).default(1),
      },
      {
        name: "size",
        type: "Query",
        schema: z.number().int().gte(1).lte(100).optional().default(25),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_Locations_GetAll_V1_GetAllLocationsResponse,
  },
  {
    method: "put",
    path: "/locations",
    description: `Update a specific location`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Locations_Update_V1_UpdateLocationRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "get",
    path: "/locations/:locationId",
    description: `Get a specific location`,
    requestFormat: "json",
    parameters: [
      {
        name: "locationId",
        type: "Path",
        schema: z.number().int(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_Locations_GetById_V1_GetLocationByIdResponse,
  },
  {
    method: "delete",
    path: "/locations/:locationId",
    description: `Delete a specific location`,
    requestFormat: "json",
    parameters: [
      {
        name: "locationId",
        type: "Path",
        schema: z.number().int(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "put",
    path: "/templates/checks/locations",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Templates_Checks_Locations_Update_V1_UpdateTemplateCheckLocationsRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
]);

export const LocationsApi = new Zodios(LocationsEndpoints);

export const Template_ChecklistsEndpoints = makeApi([
  {
    method: "post",
    path: "/templates/checklists",
    description: `Create a new template checklist`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Templates_Checklists_Create_V1_CreateTemplateChecklistRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: z.object({ templateChecklistId: z.number().int() }).partial(),
  },
  {
    method: "get",
    path: "/templates/checklists",
    description: `Retrieve all template checklists`,
    requestFormat: "json",
    parameters: [
      {
        name: "title",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "productLabel",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "page",
        type: "Query",
        schema: z.number().int().gte(1).lte(2147483647).default(1),
      },
      {
        name: "size",
        type: "Query",
        schema: z.number().int().gte(1).lte(100).optional().default(25),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_Templates_Checklists_GetAll_V1_GetAllTemplateChecklistsResponse,
  },
  {
    method: "put",
    path: "/templates/checklists",
    description: `Update a specific template checklist`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Templates_Checklists_Update_V1_UpdateTemplateChecklistRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "delete",
    path: "/templates/checklists/:templateChecklistId",
    description: `Delete a specific template checklist`,
    requestFormat: "json",
    parameters: [
      {
        name: "templateChecklistId",
        type: "Path",
        schema: z.number().int(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "get",
    path: "/templates/checklists/:templateChecklistId",
    description: `Get a specific Template checklist`,
    requestFormat: "json",
    parameters: [
      {
        name: "templateChecklistId",
        type: "Path",
        schema: z.number().int(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_Templates_Checklists_GetById_V1_GetTemplateChecklistByIdResponse,
  },
  {
    method: "get",
    path: "/templates/checklists/groups/:templateChecklistId",
    description: `Get the groups overview of a specific Template checklist. This does not include any of the checks and subproperties. Only the group information and the amount of checks that this group contains`,
    requestFormat: "json",
    parameters: [
      {
        name: "templateChecklistId",
        type: "Path",
        schema: z.number().int(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_Templates_Checklists_GetAllGroupsById_V1_GetAllTemplateChecklistGroupsByIdResponse,
  },
  {
    method: "get",
    path: "/templates/checklists/by-product-label/:productLabel",
    description: `Get a list of Template checklists associated with the given product label`,
    requestFormat: "json",
    parameters: [
      {
        name: "productLabel",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_Templates_Checklists_GetByProductLabel_V1_GetTemplateChecklistsByProductLabelResponse,
  },
  {
    method: "get",
    path: "/templates/checklists/ids",
    description: `Get search metadata fo all template checklists that match the ids given`,
    requestFormat: "json",
    parameters: [
      {
        name: "ids",
        type: "Query",
        schema: z.array(z.number()),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_Templates_Checklists_GetSearchByIdRange_V1_GetTemplateChecklistSearchByIdRangeResponse,
  },
  {
    method: "get",
    path: "/templates/checklists/:id/translations",
    description: `Get all translatable fields for a specific Template Checklist`,
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: z.number().int(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_Templates_Checklists_GetTranslationsById_V1_GetTemplateChecklistTranslationsByIdResponse,
  },
  {
    method: "put",
    path: "/templates/checklists/translations",
    description: `Update the translations of a specific template checklist`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Templates_Checklists_UpdateTranslations_V1_UpdateTemplateChecklistTranslationsRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
]);

export const Template_ChecklistsApi = new Zodios(Template_ChecklistsEndpoints);

export const Template_GroupsEndpoints = makeApi([
  {
    method: "post",
    path: "/templates/groups",
    description: `Create a new group`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Templates_Groups_Create_V1_CreateTemplateGroupRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: z.object({ groupId: z.number().int() }).partial(),
  },
  {
    method: "get",
    path: "/templates/groups",
    description: `Retrieve all template groups`,
    requestFormat: "json",
    parameters: [
      {
        name: "title",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "displayTitle",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "alias",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "page",
        type: "Query",
        schema: z.number().int().gte(1).lte(2147483647).default(1),
      },
      {
        name: "size",
        type: "Query",
        schema: z.number().int().gte(1).lte(100).optional().default(25),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_Templates_Groups_GetAll_V1_GetAllTemplateGroupsResponse,
  },
  {
    method: "put",
    path: "/templates/groups",
    description: `Update a specific template group`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Templates_Groups_Update_V1_UpdateTemplateGroupRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "delete",
    path: "/templates/groups/:groupId",
    description: `Delete a specific template group`,
    requestFormat: "json",
    parameters: [
      {
        name: "groupId",
        type: "Path",
        schema: z.number().int(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "get",
    path: "/templates/groups/:groupId",
    description: `Get a specific Template Group`,
    requestFormat: "json",
    parameters: [
      {
        name: "groupId",
        type: "Path",
        schema: z.number().int(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_Templates_Groups_GetById_V1_GetTemplateGroupByIdResponse,
  },
  {
    method: "get",
    path: "/templates/groups/:id/translations",
    description: `Get all translatable fields for a specific template group`,
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: z.number().int(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_Templates_Groups_GetTranslationsById_V1_GetTemplateGroupTranslationsResponse,
  },
  {
    method: "put",
    path: "/templates/groups/checks-order",
    description: `Update the order of checks within a template group`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Templates_Groups_UpdateChecksOrder_V1_UpdateTemplateGroupChecksOrderRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "put",
    path: "/templates/groups/translations",
    description: `Update the translations of a specific template group`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Templates_Groups_UpdateTranslations_V1_UpdateTemplateGroupTranslationsRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
]);

export const Template_GroupsApi = new Zodios(Template_GroupsEndpoints);

export const TagsEndpoints = makeApi([
  {
    method: "post",
    path: "/templates/tags",
    description: `Create a new tag`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Templates_Tags_Create_V1_CreateTemplateTagRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: z.object({ templateTagId: z.number().int() }).partial(),
  },
  {
    method: "get",
    path: "/templates/tags",
    description: `Retrieve all template tags`,
    requestFormat: "json",
    parameters: [
      {
        name: "value",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "typeName",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "exactValue",
        type: "Query",
        schema: z.boolean().optional(),
      },
      {
        name: "exactTypeName",
        type: "Query",
        schema: z.boolean().optional(),
      },
      {
        name: "page",
        type: "Query",
        schema: z.number().int().gte(1).lte(2147483647).default(1),
      },
      {
        name: "size",
        type: "Query",
        schema: z.number().int().gte(1).lte(100).optional().default(25),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_Templates_Tags_GetAll_V1_GetAllTemplateTagsResponse,
  },
  {
    method: "put",
    path: "/templates/tags",
    description: `Update a specific template tag`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Templates_Tags_Update_V1_UpdateTemplateTagRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "delete",
    path: "/templates/tags/:tagId",
    description: `Remove a specific template tag, this will remove it from all checks using it`,
    requestFormat: "json",
    parameters: [
      {
        name: "tagId",
        type: "Path",
        schema: z.number().int(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "put",
    path: "/templates/checks/tags",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Templates_Checks_Tags_Update_V1_UpdateTemplateCheckTagsRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
]);

export const TagsApi = new Zodios(TagsEndpoints);

export const TagTypesEndpoints = makeApi([
  {
    method: "post",
    path: "/templates/tagtypes",
    description: `Create a new TagType`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({ name: z.string().min(1) }),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "get",
    path: "/templates/tagtypes",
    description: `Retrieve all tag types`,
    requestFormat: "json",
    parameters: [
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_Templates_TagTypes_GetAll_V1_GetAllTemplateTagTypesResponse,
  },
  {
    method: "delete",
    path: "/templates/tagtypes/:id",
    description: `Delete a specific Template Tag Type`,
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: z.number().int(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
]);

export const TagTypesApi = new Zodios(TagTypesEndpoints);

export const Work_InstructionsEndpoints = makeApi([
  {
    method: "post",
    path: "/workinstructions",
    description: `Create a new work instruction`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_WorkInstructions_Create_V1_CreateWorkInstructionRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: z.object({ workInstructionId: z.number().int() }).partial(),
  },
  {
    method: "get",
    path: "/workinstructions",
    description: `Get a paginated set of work instructions filtered on containing search title and description`,
    requestFormat: "json",
    parameters: [
      {
        name: "title",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "description",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "page",
        type: "Query",
        schema: z.number().int().gte(1).lte(2147483647).default(1),
      },
      {
        name: "size",
        type: "Query",
        schema: z.number().int().gte(1).lte(100).optional().default(25),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_WorkInstructions_GetAll_V1_GetAllWorkInstructionsResponse,
  },
  {
    method: "put",
    path: "/workinstructions",
    description: `Update the properties of a specific work instruction`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_WorkInstructions_Update_V1_UpdateWorkInstructionRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "post",
    path: "/workinstructions/files",
    requestFormat: "form-data",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: WorkInstructions_Files_Create_Body,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: z.object({ fileUri: z.string().nullable() }).partial(),
  },
  {
    method: "delete",
    path: "/workinstructions/:workInstructionId",
    description: `Delete a specific work instruction`,
    requestFormat: "json",
    parameters: [
      {
        name: "workInstructionId",
        type: "Path",
        schema: z.number().int(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "get",
    path: "/workinstructions/:workInstructionId",
    description: `Get a specific work instruction by its id`,
    requestFormat: "json",
    parameters: [
      {
        name: "workInstructionId",
        type: "Path",
        schema: z.number().int(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_WorkInstructions_GetById_V1_GetWorkInstructionByIdResponse,
  },
  {
    method: "delete",
    path: "/workinstructions/:workInstructionId/files/:fileId",
    description: `Delete a specific file from a work instruction and the external storage`,
    requestFormat: "json",
    parameters: [
      {
        name: "workInstructionId",
        type: "Path",
        schema: z.number().int(),
      },
      {
        name: "fileId",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "put",
    path: "/workinstructions/:workInstructionId/files/:fileId",
    description: `Update a specific file from a work instruction and the external storage`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({ title: z.string().max(200).nullable() }).partial(),
      },
      {
        name: "workInstructionId",
        type: "Path",
        schema: z.number().int(),
      },
      {
        name: "fileId",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "put",
    path: "/templates/checks/workinstructions",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Checklab_WebApi_Features_Templates_Checks_WorkInstructions_V1_UpdateTemplateCheckWorkInstructionRequest,
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
]);

export const Work_InstructionsApi = new Zodios(Work_InstructionsEndpoints);

export const WorkspacesEndpoints = makeApi([
  {
    method: "post",
    path: "/workspaces",
    description: `Create a new workspace using a Clerk Organisation ID`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({ clerkOrganisationId: z.string().min(1) }),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: z.object({ workspaceId: z.number().int() }).partial(),
  },
  {
    method: "delete",
    path: "/workspaces/clerk-id/:clerkId",
    description: `Delete an existing workspace by its corresponding Clerk Organisation ID`,
    requestFormat: "json",
    parameters: [
      {
        name: "clerkId",
        type: "Path",
        schema: z.string().min(1),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "delete",
    path: "/workspaces/id/:id",
    description: `Delete an existing workspace by its ID`,
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: z.number().int(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: DiscEdge_WebApi_Responses_DetailedResponse,
  },
  {
    method: "get",
    path: "/workspaces/id/:id",
    description: `Get a workspace by its corresponding Id`,
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: z.number().int(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_Workspaces_Get_ById_V1_GetWorkspaceByIdResponse,
  },
  {
    method: "get",
    path: "/workspaces/all",
    description: `Get all existing workspaces in a paginated fashion`,
    requestFormat: "json",
    parameters: [
      {
        name: "page",
        type: "Query",
        schema: z.number().int().gte(1).lte(2147483647).default(1),
      },
      {
        name: "size",
        type: "Query",
        schema: z.number().int().gte(1).lte(100).optional().default(25),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_Workspaces_Get_Getall_V1_GetAllWorkspacesResponse,
  },
  {
    method: "get",
    path: "/workspaces/clerk/:clerkId",
    description: `Get a workspace by its corresponding Clerk Organisation Id`,
    requestFormat: "json",
    parameters: [
      {
        name: "clerkId",
        type: "Path",
        schema: z.string().min(1),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_Workspaces_Get_ByClerkId_V1_GetWorkspaceByClerkIdResponse,
  },
]);

export const WorkspacesApi = new Zodios(WorkspacesEndpoints);

export const InspectionsEndpoints = makeApi([
  {
    method: "get",
    path: "/inspections/recent",
    alias: "Inspections.Recent",
    description: `Get a paginated response from the most recent updated inspections`,
    requestFormat: "json",
    parameters: [
      {
        name: "page",
        type: "Query",
        schema: z.number().int().gte(1).lte(2147483647).default(1),
      },
      {
        name: "size",
        type: "Query",
        schema: z.number().int().gte(1).lte(100).optional().default(25),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_Inspections_Get_Recent_V1_RecentInspectionsResponse,
  },
  {
    method: "get",
    path: "/inspections/indices",
    description: `Get State Indices for all Inspections`,
    requestFormat: "json",
    parameters: [
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_Inspections_Get_StateIndices_V1_GetAllInspectionStateIndicesResponse,
  },
  {
    method: "get",
    path: "/inspections/search",
    description: `Search existing Inspections`,
    requestFormat: "json",
    parameters: [
      {
        name: "state",
        type: "Query",
        schema: z
          .union([
            z.literal(1),
            z.literal(2),
            z.literal(3),
            z.literal(4),
            z.literal(5),
          ])
          .optional(),
      },
      {
        name: "serialNumber",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "title",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "acceptedAt.from",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "acceptedAt.to",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "createdAt.from",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "createdAt.to",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "createdBy",
        type: "Query",
        schema: z.array(z.string()).optional(),
      },
      {
        name: "acceptedBy",
        type: "Query",
        schema: z.array(z.string()).optional(),
      },
      {
        name: "fromTemplates",
        type: "Query",
        schema: z.array(z.number()).optional(),
      },
      {
        name: "orderBy",
        type: "Query",
        schema: z.union([
          z.literal(0),
          z.literal(1),
          z.literal(2),
          z.literal(3),
          z.literal(4),
          z.literal(5),
          z.literal(6),
          z.literal(7),
          z.literal(8),
          z.literal(9),
          z.literal(10),
        ]),
      },
      {
        name: "page",
        type: "Query",
        schema: z.number().int().gte(1).lte(2147483647).default(1),
      },
      {
        name: "size",
        type: "Query",
        schema: z.number().int().gte(1).lte(100).optional().default(25),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_Inspections_Get_Search_V1_SearchInspectionsResponse,
  },
]);

export const InspectionsApi = new Zodios(InspectionsEndpoints);

export const PublicationsEndpoints = makeApi([
  {
    method: "get",
    path: "/publications/inspections/zip",
    description: `Get a number of inspection pdfs in a zip`,
    requestFormat: "json",
    parameters: [
      {
        name: "inspectionIdRange",
        type: "Query",
        schema: z.array(z.string()),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: z.object({}).partial(),
  },
  {
    method: "get",
    path: "/publications/checklists/:checklistUid/:accessToken",
    description: `Get a Checklist Publication By a ChecklistId and Access Token`,
    requestFormat: "json",
    parameters: [
      {
        name: "checklistUid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "accessToken",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_Publications_Checklists_GetByChecklistIdAndAccessToken_V1_GetChecklistPublicationByChecklistIdAndAccessTokenResponse,
  },
  {
    method: "get",
    path: "/publications/checklists/:checklistUid",
    description: `Get a Checklist Publication By a ChecklistId`,
    requestFormat: "json",
    parameters: [
      {
        name: "checklistUid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_Publications_Checklists_GetByChecklistId_V1_GetChecklistPublicationByChecklistIdResponse,
  },
  {
    method: "get",
    path: "/publications/inspections/:accessToken",
    description: `Get a Checklist Publication By an Access Token`,
    requestFormat: "json",
    parameters: [
      {
        name: "accessToken",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_Publications_Inspections_GetByAccessToken_V1_GetInspectionPublicationByAccessTokenResponse,
  },
  {
    method: "get",
    path: "/publications/inspections/:inspectionUid",
    description: `Get an Inspection Publication By an InspectionId`,
    requestFormat: "json",
    parameters: [
      {
        name: "inspectionUid",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_Publications_Inspections_GetByInspectionId_V1_GetInspectionPublicationByInspectionIdResponse,
  },
  {
    method: "get",
    path: "/publications/projects/zip/:accessToken",
    description: `Get a Project Publication as a zip by an Access Token`,
    requestFormat: "json",
    parameters: [
      {
        name: "accessToken",
        type: "Path",
        schema: z.string().min(21).max(21),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: z.object({}).partial(),
  },
  {
    method: "get",
    path: "/publications/projects/:projectId",
    description: `Get a Project Publication by a Project Id`,
    requestFormat: "json",
    parameters: [
      {
        name: "projectId",
        type: "Path",
        schema: z.number().int(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_Publications_Projects_GetByProjectId_V1_GetProjectPublicationByProjectIdResponse,
  },
  {
    method: "put",
    path: "/publications/projects",
    description: `Reset the Access Token of a Project Publication`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({ projectId: z.number().int() }).partial(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: z.object({ accessToken: z.string().nullable() }).partial(),
  },
  {
    method: "post",
    path: "/publications/checklists/access-token",
    description: `Reset the Access Token of a Checklist Publication`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({ checklistUid: z.string().uuid() }).partial(),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: z.object({ newAccessToken: z.string().uuid() }).partial(),
  },
]);

export const PublicationsApi = new Zodios(PublicationsEndpoints);

export const AnalysisEndpoints = makeApi([
  {
    method: "get",
    path: "/analysis/checks/comments/ids",
    description: `Get comments, photos and detailed information for all reporting checks that match the ids given`,
    requestFormat: "json",
    parameters: [
      {
        name: "ids",
        type: "Query",
        schema: z.array(z.number()),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response:
      Checklab_WebApi_Features_Reporting_Comments_V1_GetReportingCommentRangeResponse,
  },
  {
    method: "get",
    path: "/analysis/checks/search",
    requestFormat: "json",
    parameters: [
      {
        name: "productLabels",
        type: "Query",
        schema: z.array(z.string()).optional(),
      },
      {
        name: "checkTitle",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "startDateTime",
        type: "Query",
        schema: z.string(),
      },
      {
        name: "endDateTime",
        type: "Query",
        schema: z.string(),
      },
      {
        name: "tags",
        type: "Query",
        schema: z.array(z.string()).optional(),
      },
      {
        name: "checkPriorities",
        type: "Query",
        schema: z
          .array(
            z.union([z.literal(1), z.literal(2), z.literal(3), z.literal(4)]),
          )
          .optional(),
      },
      {
        name: "checkTypes",
        type: "Query",
        schema: z
          .array(
            z.union([
              z.literal(1),
              z.literal(2),
              z.literal(3),
              z.literal(4),
              z.literal(5),
              z.literal(6),
              z.literal(7),
              z.literal(8),
              z.literal(9),
            ]),
          )
          .optional(),
      },
      {
        name: "checkStates",
        type: "Query",
        schema: z
          .array(
            z.union([
              z.literal(1),
              z.literal(2),
              z.literal(3),
              z.literal(4),
              z.literal(6),
            ]),
          )
          .optional(),
      },
      {
        name: "templateIds",
        type: "Query",
        schema: z.array(z.number()).optional(),
      },
      {
        name: "templateTitle",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "checklistIds",
        type: "Query",
        schema: z.array(z.string()).optional(),
      },
      {
        name: "checklistTitle",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "checklistSerialNumbers",
        type: "Query",
        schema: z.array(z.string()).optional(),
      },
      {
        name: "sort",
        type: "Query",
        schema: z
          .union([
            z.literal(0),
            z.literal(1),
            z.literal(2),
            z.literal(3),
            z.literal(4),
            z.literal(5),
            z.literal(6),
            z.literal(7),
            z.literal(8),
            z.literal(9),
            z.literal(10),
            z.literal(11),
            z.literal(12),
            z.literal(13),
            z.literal(14),
          ])
          .optional()
          .default(9),
      },
      {
        name: "page",
        type: "Query",
        schema: z.number().int().gte(1).lte(2147483647).default(1),
      },
      {
        name: "size",
        type: "Query",
        schema: z.number().int().gte(1).lte(100).optional().default(25),
      },
      {
        name: "api-version",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: Checklab_WebApi_Features_Reporting_Search_V1_GetSearchResponse,
  },
]);

export const AnalysisApi = new Zodios(AnalysisEndpoints);
