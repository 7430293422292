import { CheckBaseEntity, ICheckBaseEntity } from "./CheckBaseEntity";

export interface ICheckInputNumberEntity extends ICheckBaseEntity {
  lowerFailure?: number;
  lowerTolerance?: number;
  upperFailure?: number;
  upperTolerance?: number;

  number: number | null;
}

export class CheckInputNumberEntity
  extends CheckBaseEntity
  implements ICheckInputNumberEntity
{
  public readonly lowerFailureThreshold?: number;
  public readonly lowerToleranceThreshold?: number;
  public readonly upperFailureThreshold?: number;
  public readonly upperToleranceThreshold?: number;

  public readonly number: number | null;

  protected constructor(check: ICheckInputNumberEntity) {
    super(check);

    console.log("CHECK:::", (check as any).upperFailure);

    this.lowerFailureThreshold = check.lowerFailure;
    this.lowerToleranceThreshold = check.lowerTolerance;
    this.upperFailureThreshold = check.upperFailure;
    this.upperToleranceThreshold = check.upperTolerance;

    this.number = check.number;
  }

  public static new(
    check: Omit<ICheckInputNumberEntity, "uid">,
  ): CheckInputNumberEntity {
    return new CheckInputNumberEntity({ uid: "non-existing", ...check });
  }

  public static existing(
    check: ICheckInputNumberEntity,
  ): CheckInputNumberEntity {
    return new CheckInputNumberEntity(check);
  }
}
