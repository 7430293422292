import { makeObservable, observable, runInAction } from "mobx";
import { CheckInputNumberEntity } from "../../core/domain/entities/checklist/check/CheckInputNumberEntity";
import { CheckState } from "../../infrastructure/api/common/types";
import { CheckBaseViewModel, ICheckBaseViewModel } from "./CheckBaseViewModel";

export interface ICheckInputNumberViewModel extends ICheckBaseViewModel {
  lowerFailureThreshold?: number;
  lowerToleranceThreshold?: number;
  upperFailureThreshold?: number;
  upperToleranceThreshold?: number;

  number: number | null;

  store: (data: number, state: number) => Promise<void>;
  reset: () => Promise<void>;
}

interface X {
  number: number;
}

export class CheckInputNumberViewModel
  extends CheckBaseViewModel
  implements ICheckInputNumberViewModel
{
  public lowerFailureThreshold?: number;
  public lowerToleranceThreshold?: number;
  public upperFailureThreshold?: number;
  public upperToleranceThreshold?: number;

  public number: number | null = null;

  constructor(
    check: CheckInputNumberEntity,
    checklistUid: string,
    groupUid: string,
  ) {
    super(check, checklistUid, groupUid);

    this.lowerFailureThreshold = check.lowerFailureThreshold;
    this.lowerToleranceThreshold = check.lowerToleranceThreshold;
    this.upperFailureThreshold = check.upperFailureThreshold;
    this.upperToleranceThreshold = check.upperToleranceThreshold;

    this.number = check.number;

    this.finished = this.isFinished();

    makeObservable(this, {
      number: observable,
    });
  }

  public async store(data: number, state: number): Promise<void> {
    // const tempState = this.number;
    // await this.update<ISetCheckValueNumberRequest>(
    //   {
    //     number: data,
    //   },
    //   () => {
    //     this.number = data;
    //   },
    //   () => {
    //     this.number = tempState;
    //   },
    // );

    runInAction(() => {
      this.number = data;
      this.state = state;
      this.finished = this.isFinished();

      const x = { state: { selectedUser: { id: "" } } };

      const userId = //@ts-ignore
        window.Clerk.user.id === process.env.REACT_APP_ADMIN_USER_ID
          ? JSON.parse(
              localStorage.getItem("user-storage") || JSON.stringify(x),
            ).state.loggedInUser?.userId
          : //@ts-ignore
            window.Clerk.user.id;

      this.lastTouchedBy = {
        value: userId,
        type: 1,
      };
      this.lastTouchedAt = new Date();
    });
  }

  public async reset(): Promise<void> {
    // const tempState = this.number;
    // await this.update<ISetCheckValueNumberRequest>(
    //   {
    //     number: null,
    //   },
    //   () => {
    //     this.number = null;
    //   },
    //   () => {
    //     this.number = tempState;
    //   },
    // );

    runInAction(() => {
      this.number = null;
      this.finished = false;
      this.lastTouchedBy = null;
      this.state = CheckState.Empty;
    });
  }
}
