import { ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

/**
 * Check if an array contains a value, parses falsy value to boolean value.
 * @param arr array to check for values.
 * @returns boolean
 */
export function arrayHasValue(arr?: unknown[] | null) {
  return !!arr?.length;
}
