import { Circle, Pencil } from "lucide-react";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactSignatureCanvas, {
  default as SignatureCanvas,
} from "react-signature-canvas";
import { checksHooks2 } from "../../../api";
import { ICheckSignatureViewModel } from "../../../application/viewModels/CheckSignatureViewModel";
import { CheckState } from "../../../infrastructure/api/common/types";
import { Badge } from "../../ui/badge";
import { Button } from "../../ui/button";
import CheckStateCard, {
  AnswerBadge,
  AnswerContainer,
  SecondaryActionButton,
} from "./CheckStateCard";
import CheckPopupBase from "./index";

interface ICheckPopupSignatureProps {
  check: ICheckSignatureViewModel;
  onFinish: () => void;
  signed: boolean;
  sectionTitle: string;
  checklistId: number;
  groupUid: string;
}

export const CheckPopupSignature = observer(
  ({
    check,
    onFinish,
    signed,
    sectionTitle,
    checklistId,
    groupUid,
  }: ICheckPopupSignatureProps): JSX.Element => {
    const { mutate, isLoading: isLoadingValue } = checksHooks2.usePut(
      "/checklists/groups/checks/signature/value",
    );

    const { mutate: resetSignatureValue, isLoading: isLoadingReset } =
      checksHooks2.usePut("/checklists/groups/checks/signature/value/reset");

    const { mutate: mutateInApplicable, isLoading: isLoadingInApplicable } =
      checksHooks2.usePost("/checklists/groups/checks/signature/in-applicable");

    const handleSign = async () => {
      const dataUrl = ref.current?.getCanvas().toDataURL();

      if (dataUrl === undefined) {
        return;
      }

      const response = await fetch(dataUrl);
      const blob = await response.blob();
      const file = new File([blob], "temp-file", { type: "image/png" });

      mutate(
        {
          signatureFile: file,
          checklistId,
          checkUid: check.uid,
          groupUid,
        },
        {
          onSuccess: () => {
            check.sign();
            onFinish();
            // TODO: setting the file uri fixes the image not being visible when moving between checks and not refetching the actual checklist.
            // In the future we will use react-query for fetching the checklist and we can invalidate the query after setting the signature.
            check.fileUri = response.url ?? "";
            setSignature(response.url);
          },
        },
      );
    };

    const handleUnsign = async () => {
      resetSignatureValue(
        {
          checklistId,
          checkUid: check.uid,
          groupUid,
        },
        {
          onSuccess: () => {
            check.reset();
            onFinish();
          },
        },
      );
    };

    const ref = useRef<ReactSignatureCanvas | null>(null);
    const containerRef = useRef<null | HTMLDivElement>(null);
    const [width, setWidth] = useState(0);
    const [signature, setSignature] = useState(check.fileUri);

    useEffect(() => {
      setWidth(containerRef.current?.clientWidth ?? 0);
    }, [ref, check.signed]);

    const { t } = useTranslation();

    function handleCheckIsInApplicable() {
      mutateInApplicable(
        {
          checklistId: checklistId,
          checkUid: check.uid,
          groupUid,
        },
        {
          onSuccess: async () => {
            // await check.accept();
            check.handleInApplicable();

            onFinish();
          },
        },
      );
    }

    return (
      <CheckPopupBase
        activities={check.activities}
        instructionId={check.instructionId}
        signed={signed}
        check={check}
      >
        <CheckStateCard
          signed={signed}
          check={check}
          sectionTitle={sectionTitle}
          isLoading={isLoadingValue || isLoadingInApplicable || isLoadingReset}
        >
          {!check.signed && check.state !== CheckState.InApplicable && (
            <div className="w-full">
              <div className={`mt-5 w-full gap-x-3 px-4 pb-4`}>
                <div className="bg-white rounded-lg w-full" ref={containerRef}>
                  <SignatureCanvas
                    backgroundColor="rgb(255,255,255)"
                    canvasProps={{
                      width,
                      height: 164,
                    }}
                    ref={ref}
                  />
                </div>
                <Button
                  className="mt-4 bg-[#cac4d0] rounded-full w-full text-base"
                  onClick={handleSign}
                  disabled={check.loading}
                >
                  {t("inspectionPage.checkPopup.signatureCheck.saveButton")}
                </Button>
              </div>

              {check.canBeInApplicable && (
                <div className="px-4 pb-4">
                  <div className="border-t-2 border-dashed pt-4">
                    <SecondaryActionButton
                      onClick={handleCheckIsInApplicable}
                      disabled={check.loading}
                    >
                      {t("inspectionPage.checkPopup.inApplicableCheckButton")}
                    </SecondaryActionButton>
                  </div>
                </div>
              )}
            </div>
          )}

          {check.state === CheckState.InApplicable && (
            <AnswerContainer>
              <AnswerBadge
                lastTouchedBy={check.lastTouchedBy}
                lastTouchedAt={check.lastTouchedAt}
                checklistAccepted={signed}
                onReset={handleUnsign}
              >
                <Circle className="w-5 h-5 mr-2" />
                {t("inspectionPage.checkPopup.inApplicableCheckButton")}
              </AnswerBadge>
            </AnswerContainer>
          )}

          {check.signed === true && (
            <div className="mt-4 1mb-3 w-full">
              {signature && (
                <div className="px-4 pb-6">
                  <div className="bg-white h-[164px] rounded-lg">
                    <img src={signature} />
                  </div>
                </div>
              )}
              <Badge className="flex flex-col text-base relative rounded-t-none rounded-b-lg pl-4 pr-2 py-1.5 bg-[#cac4d0] w-full">
                <div className="flex items-center w-full">
                  <img
                    style={{
                      position: "absolute",
                      left: 17,
                      top: -14,
                      zIndex: 99999,
                      width: 20,
                      transform: "rotate(0deg)",
                    }}
                    src="/Polygon 2.svg"
                  />
                  <Pencil className="w-5 h-5 mr-2" />
                  {t("inspectionPage.checkPopup.signatureCheck.signature")}
                  <Button
                    onClick={handleUnsign}
                    size={"sm"}
                    variant={"outline"}
                    className="ml-auto 1rounded-lg rounded-full border-2"
                  >
                    {t("inspectionPage.checkPopup.signatureCheck.delete")}
                  </Button>
                </div>
              </Badge>
            </div>
          )}
        </CheckStateCard>
      </CheckPopupBase>
    );
  },
);
