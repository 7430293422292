import { makeObservable, observable, runInAction } from "mobx";
import { CheckInputSingleLineEntity } from "../../core/domain/entities/checklist/check/CheckInputSingleLineEntity";
import { CheckState } from "../../infrastructure/api/common/types";
import { CheckBaseViewModel, ICheckBaseViewModel } from "./CheckBaseViewModel";

export interface ICheckInputSingleLineViewModel extends ICheckBaseViewModel {
  text: string | null;

  store: (data: string) => Promise<void>;
  reset: () => Promise<void>;
}

export class CheckInputSingleLineViewModel
  extends CheckBaseViewModel
  implements ICheckInputSingleLineViewModel
{
  public text: string | null = null;

  constructor(
    check: CheckInputSingleLineEntity,
    checklistUid: string,
    groupUid: string,
  ) {
    super(check, checklistUid, groupUid);

    this.text = check.text;

    this.finished = this.isFinished();

    makeObservable(this, {
      text: observable,
    });
  }

  public async store(data: string): Promise<void> {
    // const tempState = this.text;
    // await this.update<ISetCheckValueSingleTextRequest>(
    //   {
    //     text: data,
    //   },
    //   () => {
    //     this.text = data;
    //   },
    //   () => {
    //     this.text = tempState;
    //   },
    // );

    runInAction(() => {
      this.text = data;
      this.finished = this.isFinished();
      this.state = CheckState.Good;

      const x = { state: { selectedUser: { id: "" } } };

      const userId = //@ts-ignore
        window.Clerk.user.id === process.env.REACT_APP_ADMIN_USER_ID
          ? JSON.parse(
              localStorage.getItem("user-storage") || JSON.stringify(x),
            ).state.loggedInUser?.userId
          : //@ts-ignore
            window.Clerk.user.id;

      this.lastTouchedBy = {
        value: userId,
        type: 1,
      };
      this.lastTouchedAt = new Date();
    });
  }

  public async reset(): Promise<void> {
    // const tempState = this.text;
    // await this.update<ISetCheckValueSingleTextRequest>(
    //   {
    //     text: null,
    //   },
    //   () => {
    //     this.text = null;
    //   },
    //   () => {
    //     this.text = tempState;
    //   },
    // );

    runInAction(() => {
      this.text = null;
      this.finished = false;
      this.lastTouchedBy = null;
      this.state = CheckState.Empty;
    });
  }
}
