import { Circle, ThumbsDown, ThumbsUp } from "lucide-react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { checksHooks } from "../../../api";
import { ICheckYesNoViewModel } from "../../../application/viewModels/CheckYesNoViewModel";
import { CheckState } from "../../../infrastructure/api/common/types";
import CheckStateCard, {
  ActionContainer,
  AnswerBadge,
  AnswerContainer,
  PrimaryActionButton,
  SecondaryActionButton,
} from "./CheckStateCard";
import CheckPopupBase from "./index";

interface ICheckPopupYesNoProps {
  check: ICheckYesNoViewModel;
  onFinish: () => void;
  signed: boolean;
  sectionTitle: string;
  checklistId: number;
  groupUid: string;
}

export const CheckPopupYesNo = observer(
  ({
    check,
    onFinish,
    signed,
    sectionTitle,
    checklistId,
    groupUid,
  }: ICheckPopupYesNoProps): JSX.Element => {
    const { mutate, isLoading: isLoadingValue } = checksHooks.usePut(
      "/checklists/groups/checks/yes-no/value",
    );
    const { mutate: mutateReset, isLoading: isLoadingReset } =
      checksHooks.usePut("/checklists/groups/checks/yes-no/value/reset");
    const { mutate: mutateInApplicable, isLoading: isLoadingInApplicable } =
      checksHooks.usePost("/checklists/groups/checks/yes-no/in-applicable");
    const { t } = useTranslation();

    const handleYes = async () => {
      let value: null | string = null;

      if (check.commentRequiredOnYes) {
        value = prompt(
          check.commentOnYesTitle ??
            t("inspectionPage.checkPopup.yesNoCheck.onYesTitle"),
        );
      }

      if (!value && check.commentRequiredOnYes) {
        return;
      }

      mutate(
        {
          answer: true,
          commentValue: value ?? undefined,
          checklistId,
          checkUid: check.uid,
          groupUid,
        },
        {
          onSuccess: () => {
            check.yes(value ?? undefined);
            onFinish();
          },
        },
      );
    };

    const handleNo = async () => {
      let value: null | string = null;

      if (check.commentRequiredOnNo) {
        value = prompt(
          check.commentOnNoTitle ??
            t("inspectionPage.checkPopup.yesNoCheck.onNoTitle"),
        );
      }

      if (!value && check.commentRequiredOnNo) {
        return;
      }

      mutate(
        {
          answer: false,
          commentValue: value ?? undefined,
          checklistId,
          checkUid: check.uid,
          groupUid,
        },
        {
          onSuccess: () => {
            check.no(value ?? undefined);
            onFinish();
          },
        },
      );
    };

    const handleReset = async () => {
      mutateReset(
        {
          checklistId,
          checkUid: check.uid,
          groupUid,
        },
        {
          onSuccess: () => {
            check.reset();
            onFinish();
          },
        },
      );
    };

    function handleCheckIsInApplicable() {
      mutateInApplicable(
        {
          checklistId: checklistId,
          checkUid: check.uid,
          groupUid,
        },
        {
          onSuccess: async () => {
            check.handleInApplicable();

            onFinish();
          },
        },
      );
    }

    return (
      <CheckPopupBase
        activities={check.activities}
        instructionId={check.instructionId}
        signed={signed}
        check={check}
      >
        <CheckStateCard
          signed={signed}
          check={check}
          sectionTitle={sectionTitle}
          isLoading={isLoadingValue || isLoadingInApplicable || isLoadingReset}
        >
          {check.state === CheckState.InApplicable && (
            <AnswerContainer>
              <AnswerBadge
                lastTouchedBy={check.lastTouchedBy}
                lastTouchedAt={check.lastTouchedAt}
                checklistAccepted={signed}
                onReset={handleReset}
              >
                <Circle className="w-5 h-5 mr-2" />
                {t("inspectionPage.checkPopup.inApplicableCheckButton")}
              </AnswerBadge>
            </AnswerContainer>
          )}

          {!signed &&
            check.answer === null &&
            check.state !== CheckState.InApplicable && (
              <div className="w-full">
                <ActionContainer>
                  <PrimaryActionButton
                    onClick={handleYes}
                    disabled={check.loading}
                  >
                    {t("inspectionPage.checkPopup.yesNoCheck.yesButton")}
                  </PrimaryActionButton>
                  <SecondaryActionButton
                    onClick={handleNo}
                    disabled={check.loading}
                  >
                    {t("inspectionPage.checkPopup.yesNoCheck.noButton")}
                  </SecondaryActionButton>
                </ActionContainer>
                {check.canBeInApplicable && (
                  <div className="px-4 pb-4">
                    <div className="border-t-2 border-dashed pt-4">
                      <SecondaryActionButton
                        onClick={handleCheckIsInApplicable}
                        disabled={check.loading}
                      >
                        {t("inspectionPage.checkPopup.inApplicableCheckButton")}
                      </SecondaryActionButton>
                    </div>
                  </div>
                )}
              </div>
            )}

          {check.answer !== null && (
            <AnswerContainer>
              {check.answer && (
                <AnswerBadge
                  lastTouchedBy={check.lastTouchedBy}
                  lastTouchedAt={check.lastTouchedAt}
                  checklistAccepted={signed}
                  onReset={handleReset}
                >
                  <ThumbsUp className="w-5 h-5 mr-2" />
                  {t("inspectionPage.checkPopup.yesNoCheck.yesButton")}
                  {`${
                    check.comment !== null ? `, ${check.comment?.value}` : ""
                  }`}
                </AnswerBadge>
              )}

              {check.state === CheckState.InApplicable && (
                <AnswerBadge
                  lastTouchedBy={check.lastTouchedBy}
                  lastTouchedAt={check.lastTouchedAt}
                  checklistAccepted={signed}
                  onReset={handleReset}
                >
                  <Circle className="w-5 h-5 mr-2" />
                  {t("inspectionPage.checkPopup.inApplicableCheckButton")}
                </AnswerBadge>
              )}

              {check.answer === false && (
                <AnswerBadge
                  lastTouchedBy={check.lastTouchedBy}
                  lastTouchedAt={check.lastTouchedAt}
                  checklistAccepted={signed}
                  onReset={handleReset}
                >
                  <ThumbsDown className="w-5 h-5 mr-2" />
                  {t("inspectionPage.checkPopup.yesNoCheck.noButton")}
                  {`${
                    check.comment !== null ? `, ${check.comment?.value}` : ""
                  }`}
                </AnswerBadge>
              )}
            </AnswerContainer>
          )}
        </CheckStateCard>
      </CheckPopupBase>
    );
  },
);
