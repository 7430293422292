import { Zodios } from "@zodios/core";
import { pluginToken } from "@zodios/plugins";
import { ZodiosHooks } from "@zodios/react";
import { instance } from "./axios";
import {
  Action_WorkflowsEndpoints,
  AnalysisEndpoints,
  ChecklistsEndpoints,
  ChecksEndpoints,
  ChecksEndpoints2,
  DashboardEndpoints,
  InspectionsEndpoints,
  IotEndpoints,
  LanguagesEndpoints,
  LocationsEndpoints,
  ProjectsEndpoints,
  PublicationsEndpoints,
  TagTypesEndpoints,
  TagsEndpoints,
  Template_ChecklistsEndpoints,
  Template_ChecksEndpoints,
  Template_GroupsEndpoints,
  Work_InstructionsEndpoints,
  WorkspacesEndpoints,
} from "./schema";

export const appRouter = {
  iot: new Zodios(process.env.REACT_APP_API_URL ?? "", IotEndpoints, {
    axiosInstance: instance,
  }),
  templateChecklists: new Zodios(
    process.env.REACT_APP_API_URL ?? "",
    Template_ChecklistsEndpoints,
    {
      axiosInstance: instance,
    },
  ),
  templateGroups: new Zodios(
    process.env.REACT_APP_API_URL ?? "",
    Template_GroupsEndpoints,
    {
      axiosInstance: instance,
    },
  ),
  templateChecks: new Zodios(
    process.env.REACT_APP_API_URL ?? "",
    Template_ChecksEndpoints,
    {
      axiosInstance: instance,
    },
  ),
  templateLocations: new Zodios(
    process.env.REACT_APP_API_URL ?? "",
    LocationsEndpoints,
    {
      axiosInstance: instance,
    },
  ),
  templateWorkInstructions: new Zodios(
    process.env.REACT_APP_API_URL ?? "",
    Work_InstructionsEndpoints,
    {
      axiosInstance: instance,
    },
  ),
  dashboard: new Zodios(
    process.env.REACT_APP_API_URL ?? "",
    DashboardEndpoints,
    {
      axiosInstance: instance,
    },
  ),
  checklists: new Zodios(
    process.env.REACT_APP_API_URL ?? "",
    ChecklistsEndpoints,
    {
      axiosInstance: instance,
    },
  ),
  analysis: new Zodios(process.env.REACT_APP_API_URL ?? "", AnalysisEndpoints, {
    axiosInstance: instance,
  }),
  tagTypes: new Zodios(process.env.REACT_APP_API_URL ?? "", TagTypesEndpoints, {
    axiosInstance: instance,
  }),
  tags: new Zodios(process.env.REACT_APP_API_URL ?? "", TagsEndpoints, {
    axiosInstance: instance,
  }),
  workspaces: new Zodios(
    process.env.REACT_APP_API_URL ?? "",
    WorkspacesEndpoints,
    {
      axiosInstance: instance,
    },
  ),
  actionWorkflows: new Zodios(
    process.env.REACT_APP_API_URL ?? "",
    Action_WorkflowsEndpoints,
    {
      axiosInstance: instance,
    },
  ),
  publications: new Zodios(
    process.env.REACT_APP_API_URL ?? "",
    PublicationsEndpoints,
    {
      axiosInstance: instance,
    },
  ),
  projects: new Zodios(process.env.REACT_APP_API_URL ?? "", ProjectsEndpoints, {
    axiosInstance: instance,
  }),
  inspections: new Zodios(
    process.env.REACT_APP_API_URL ?? "",
    InspectionsEndpoints,
    {
      axiosInstance: instance,
    },
  ),
  checks: new Zodios(process.env.REACT_APP_API_URL ?? "", ChecksEndpoints, {
    axiosInstance: instance,
  }),
  checks2: new Zodios(process.env.REACT_APP_API_URL ?? "", ChecksEndpoints2, {
    axiosInstance: instance,
  }),
  languages: new Zodios(
    process.env.REACT_APP_API_URL ?? "",
    LanguagesEndpoints,
    {
      axiosInstance: instance,
    },
  ),
};

export function authenticate(getToken: () => Promise<string | null>) {
  appRouter.iot.use(
    pluginToken({
      getToken: async () => {
        return (await getToken()) ?? "no-token";
      },
      renewToken: async () => {
        return (await getToken()) ?? "no-token";
      },
    }),
  );
  appRouter.templateChecklists.use(
    pluginToken({
      getToken: async () => {
        return (await getToken()) ?? "no-token";
      },
      renewToken: async () => {
        return (await getToken()) ?? "no-token";
      },
    }),
  );
  appRouter.templateGroups.use(
    pluginToken({
      getToken: async () => {
        return (await getToken()) ?? "no-token";
      },
      renewToken: async () => {
        return (await getToken()) ?? "no-token";
      },
    }),
  );
  appRouter.templateChecks.use(
    pluginToken({
      getToken: async () => {
        return (await getToken()) ?? "no-token";
      },
      renewToken: async () => {
        return (await getToken()) ?? "no-token";
      },
    }),
  );
  appRouter.templateLocations.use(
    pluginToken({
      getToken: async () => {
        return (await getToken()) ?? "no-token";
      },
      renewToken: async () => {
        return (await getToken()) ?? "no-token";
      },
    }),
  );
  appRouter.templateWorkInstructions.use(
    pluginToken({
      getToken: async () => {
        return (await getToken()) ?? "no-token";
      },
      renewToken: async () => {
        return (await getToken()) ?? "no-token";
      },
    }),
  );
  appRouter.dashboard.use(
    pluginToken({
      getToken: async () => {
        return (await getToken()) ?? "no-token";
      },
      renewToken: async () => {
        return (await getToken()) ?? "no-token";
      },
    }),
  );
  appRouter.checklists.use(
    pluginToken({
      getToken: async () => {
        return (await getToken()) ?? "no-token";
      },
      renewToken: async () => {
        return (await getToken()) ?? "no-token";
      },
    }),
  );
  appRouter.analysis.use(
    pluginToken({
      getToken: async () => {
        return (await getToken()) ?? "no-token";
      },
      renewToken: async () => {
        return (await getToken()) ?? "no-token";
      },
    }),
  );
  appRouter.tagTypes.use(
    pluginToken({
      getToken: async () => {
        return (await getToken()) ?? "no-token";
      },
      renewToken: async () => {
        return (await getToken()) ?? "no-token";
      },
    }),
  );
  appRouter.tags.use(
    pluginToken({
      getToken: async () => {
        return (await getToken()) ?? "no-token";
      },
      renewToken: async () => {
        return (await getToken()) ?? "no-token";
      },
    }),
  );
  appRouter.workspaces.use(
    pluginToken({
      getToken: async () => {
        return (await getToken()) ?? "no-token";
      },
      renewToken: async () => {
        return (await getToken()) ?? "no-token";
      },
    }),
  );
  appRouter.actionWorkflows.use(
    pluginToken({
      getToken: async () => {
        return (await getToken()) ?? "no-token";
      },
      renewToken: async () => {
        return (await getToken()) ?? "no-token";
      },
    }),
  );
  appRouter.publications.use(
    pluginToken({
      getToken: async () => {
        return (await getToken()) ?? "no-token";
      },
      renewToken: async () => {
        return (await getToken()) ?? "no-token";
      },
    }),
  );
  appRouter.projects.use(
    pluginToken({
      getToken: async () => {
        return (await getToken()) ?? "no-token";
      },
      renewToken: async () => {
        return (await getToken()) ?? "no-token";
      },
    }),
  );
  appRouter.inspections.use(
    pluginToken({
      getToken: async () => {
        return (await getToken()) ?? "no-token";
      },
      renewToken: async () => {
        return (await getToken()) ?? "no-token";
      },
    }),
  );
  appRouter.checks.use(
    pluginToken({
      getToken: async () => {
        return (await getToken()) ?? "no-token";
      },
      renewToken: async () => {
        return (await getToken()) ?? "no-token";
      },
    }),
  );
  appRouter.checks2.use(
    pluginToken({
      getToken: async () => {
        return (await getToken()) ?? "no-token";
      },
      renewToken: async () => {
        return (await getToken()) ?? "no-token";
      },
    }),
  );
  appRouter.languages.use(
    pluginToken({
      getToken: async () => {
        return (await getToken()) ?? "no-token";
      },
      renewToken: async () => {
        return (await getToken()) ?? "no-token";
      },
    }),
  );
}

export default appRouter;

export const templateChecklistsHooks = new ZodiosHooks(
  "templateChecklists",
  appRouter.templateChecklists,
);

export const templateSectionsHooks = new ZodiosHooks(
  "templateSections",
  appRouter.templateGroups,
);

export const templateChecksHooks = new ZodiosHooks(
  "templateChecks",
  appRouter.templateChecks,
);

export const templateLocationsHooks = new ZodiosHooks(
  "templateLocations",
  appRouter.templateLocations,
);

export const templateWorkInstructionsHooks = new ZodiosHooks(
  "templateWorkInstructions",
  appRouter.templateWorkInstructions,
);

export const dashboardHooks = new ZodiosHooks("dashboard", appRouter.dashboard);

export const checklists = new ZodiosHooks(
  "templateLocations",
  appRouter.checklists,
);

export const analysisHooks = new ZodiosHooks("analysis", appRouter.analysis);

export const tagTypesHooks = new ZodiosHooks("tagTypes", appRouter.tagTypes);
export const tagsHooks = new ZodiosHooks("tags", appRouter.tags);
export const workspacesHooks = new ZodiosHooks(
  "workspaces",
  appRouter.workspaces,
);
export const workflowsHooks = new ZodiosHooks(
  "workflows",
  appRouter.actionWorkflows,
);
export const publicationsHooks = new ZodiosHooks(
  "publications",
  appRouter.publications,
);
export const projectsHooks = new ZodiosHooks("projects", appRouter.projects);
export const inpectionsHooks = new ZodiosHooks(
  "inspections",
  appRouter.inspections,
);

export const checksHooks = new ZodiosHooks("checks", appRouter.checks);
export const checksHooks2 = new ZodiosHooks("checks2", appRouter.checks2);

export const checklistsHooks = new ZodiosHooks(
  "checklists",
  appRouter.checklists,
);
export const languagesHooks = new ZodiosHooks("languages", appRouter.languages);
export const iotHooks = new ZodiosHooks("iot", appRouter.iot);
