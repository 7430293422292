import { mdiCommentOutline, mdiLoading } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { checksHooks } from "../../../api";
import { ICheckBaseViewModel } from "../../../application/viewModels/CheckBaseViewModel";
import {
  CheckTypes,
  ICheckOkNotOkViewModel,
} from "../../../application/viewModels/CheckOkNotOkViewModel";
import { OperatorRoutePath } from "../../../data";
import {
  CheckState,
  CommentType,
} from "../../../infrastructure/api/common/types";
import Grid from "../../atoms/Grid";
import TypographyBodyMedium from "../../atoms/TypographyBodyMedium";
import { ChecklistContext } from "../../context/ChecklistManager";
import { useCheck } from "../../hooks/useCheck";
import Button from "../../molecules/Button";
import Label from "../../molecules/Label";
import PageLayout from "../../molecules/PageLayout";
import PhotoInput from "../../molecules/PhotoInput";
import Select from "../../molecules/Select";
import Textarea from "../../molecules/Textarea";

interface IAddActivityTemplateProps {
  checkViewModel: ICheckBaseViewModel;
}

export const AddActivityTemplate: React.FC<IAddActivityTemplateProps> = ({
  checkViewModel,
}) => {
  const { serialNumber, checkUid, type } = useParams<{
    serialNumber: string;
    checkUid: string;
    type: string;
  }>();
  const checklistContext = useContext(ChecklistContext);
  const [searchParams] = useSearchParams();
  const commentType: CommentType = Number(type) as CommentType;
  const check = useCheck(checkViewModel);
  const navigation = useNavigate();
  const [activityPhotos, setActivityPhotos] = useState<File[]>([]);
  const [activityDescription, setActivityDescription] = useState("");
  const [selectedRejectionMessage, setSelectedRejectionMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { mutate } = checksHooks.usePut(
    "/checklists/groups/checks/ok-nok/value",
  );

  const commentTypeLabels = {
    [CommentType.Info]: t("addActivityPage.commentTypeLabelsInfo"),
    [CommentType.Rejected]: t("addActivityPage.commentTypeLabelsRejected"),
    [CommentType.Recovered]: t("addActivityPage.commentTypeLabelsRecovered"),
  };

  const handleAddActivity = async () => {
    setLoading(true);
    if (commentType === CommentType.Info) {
      await check.addActivity(
        checklistContext.checklist.id,
        activityDescription,
        activityPhotos,
      );

      navigation(
        `/${OperatorRoutePath.ChecklistBase}/${serialNumber}/${
          OperatorRoutePath.CheckBase
        }/${checkUid}/?projectId=${searchParams.get(
          "projectId",
        )}&check-popup=open`,
      );
    }

    if (commentType === CommentType.Rejected) {
      reject();
    }

    if (commentType === CommentType.Recovered) {
      // await (check as ICheckOkNotOkViewModel).recover(
      //   activityDescription,
      //   activityPhotos,
      // );
      recover();
    }
    setLoading(false);
  };

  async function reject() {
    const check1 = check as ICheckOkNotOkViewModel;

    const message =
      activityDescription === ""
        ? selectedRejectionMessage
        : activityDescription;

    //TODO-translations: this should be cleaner, message is now compared message should actually be the key.
    const rejectionMessageIndex = Object.values(
      check.rejectionMessages,
    ).findIndex((x) => x === message);

    const rejectionMessageKey = Object.keys(check.rejectionMessages)[
      rejectionMessageIndex
    ];

    const groupUid =
      checklistContext.checklist.groups.find((g) =>
        g.checks.find((c) => c.uid === checkUid),
      )?.uid ?? "";

    mutate(
      {
        checkState: CheckState.Bad,
        checklistId: checklistContext.checklist.id,
        checkUid: check1.uid,
        groupUid,
        message: !rejectionMessageKey ? message : undefined,
        selectedRejectionMessage: rejectionMessageKey
          ? rejectionMessageKey
          : undefined,
      },
      {
        onSuccess: async (data) => {
          console.log(data);

          //@ts-ignore
          const commentUid = (data as any).commentUid;

          console.log(data, commentUid, activityPhotos);

          await check1.reject(
            commentUid,
            checklistContext.checklist.id,
            message,
            activityPhotos,
          );

          navigation(
            `/${OperatorRoutePath.ChecklistBase}/${serialNumber}/${
              OperatorRoutePath.CheckBase
            }/${checkUid}/?projectId=${searchParams.get(
              "projectId",
            )}&check-popup=open`,
          );
        },
      },
    );
  }

  async function recover() {
    const check1 = check as ICheckOkNotOkViewModel;

    const message =
      activityDescription === ""
        ? selectedRejectionMessage
        : activityDescription;

    const groupUid =
      checklistContext.checklist.groups.find((g) =>
        g.checks.find((c) => c.uid === checkUid),
      )?.uid ?? "";

    mutate(
      {
        checkState: CheckState.Recovered,
        checklistId: checklistContext.checklist.id,
        checkUid: check1.uid,
        groupUid,
        message: activityDescription,
      },
      {
        onSuccess: async (data) => {
          console.log(data);

          //@ts-ignore
          const commentUid = (data as any).commentUid;

          console.log(data, commentUid, activityPhotos);

          await check1.recover(
            commentUid,
            checklistContext.checklist.id,
            message,
            activityPhotos,
          );

          navigation(
            `/${OperatorRoutePath.ChecklistBase}/${serialNumber}/${
              OperatorRoutePath.CheckBase
            }/${checkUid}/?projectId=${searchParams.get(
              "projectId",
            )}&check-popup=open`,
          );
        },
      },
    );

    // await check1.recover(message, activityPhotos);
  }

  const infoComment = commentType === CommentType.Info;
  const recoverComment = commentType === CommentType.Recovered;
  const hasRejectionMessages =
    Object.keys((check as ICheckOkNotOkViewModel)["rejectionMessages"] ?? {})
      ?.length > 0;
  const manualRejectionMessagesAllowed = (check as ICheckOkNotOkViewModel)[
    "manualRejectionMessagesAllowed"
  ];

  let rejectionMessages: { label: string }[] = [];

  if (check.type === CheckTypes.okNotOk) {
    rejectionMessages = [
      ...Object.values(
        (check as ICheckOkNotOkViewModel)["rejectionMessages"],
      ).map((r) => ({
        label: r,
      })),
    ];
  }

  if (manualRejectionMessagesAllowed) {
    rejectionMessages = [
      { label: t("addActivityPage.other") },
      ...rejectionMessages,
    ];
  }

  const formValid =
    (selectedRejectionMessage === t("addActivityPage.other") &&
      activityDescription !== "") ||
    activityDescription ||
    (selectedRejectionMessage !== "" &&
      selectedRejectionMessage !== t("addActivityPage.other"));
  const [photosLoading, setPhotosLoading] = useState(false);

  return (
    <div
      className="bg-surface-container rounded-xl h-screen overflow-scroll pb-4"
      style={{ height: "calc(100vh - 16px)" }}
    >
      <PageLayout
        top
        title={t("addActivityPage.title")}
        subTitle={check.title}
        prevRoute={`/${OperatorRoutePath.ChecklistBase}/${serialNumber}/${OperatorRoutePath.CheckBase}/${checkUid}`}
        showBackButton
      >
        <div className="mb-[86px] px-6 grid gap-[36px] grid-cols-1">
          <Grid item>
            <TypographyBodyMedium>
              {t("addActivityPage.activityType")}
            </TypographyBodyMedium>
            <Label
              text={commentTypeLabels[commentType]}
              icon={mdiCommentOutline}
            />
          </Grid>
          <Grid item spacing={2}>
            {!infoComment && !recoverComment && hasRejectionMessages && (
              <Select
                disabled={loading}
                label={t("addActivityPage.rejectionPoint")}
                drawerLabel={"Selecteer een afkeurpunt"}
                items={rejectionMessages}
                onChangeValue={(v: string) => setSelectedRejectionMessage(v)}
              />
            )}

            {!infoComment &&
              !recoverComment &&
              hasRejectionMessages &&
              manualRejectionMessagesAllowed && (
                <Textarea
                  label={t("addActivityPage.other")}
                  minRows={1}
                  maxRows={4}
                  onChangeValue={(v: string) => setActivityDescription(v)}
                  disabled={
                    selectedRejectionMessage === "" ||
                    selectedRejectionMessage !== t("addActivityPage.other") ||
                    loading
                  }
                />
              )}

            {(infoComment || !hasRejectionMessages || recoverComment) && (
              <Textarea
                disabled={loading}
                maxLength={250}
                label={t("addActivityPage.description")}
                minRows={1}
                maxRows={4}
                onChangeValue={(v: string) => setActivityDescription(v)}
              />
            )}
            <PhotoInput
              disabled={loading}
              onPhotoLoading={() => setPhotosLoading(true)}
              onPhotoFinished={() => setPhotosLoading(false)}
              onChange={(photos: File[]) => setActivityPhotos(photos)}
            />
          </Grid>
          <Button
            disabled={!formValid || photosLoading || loading}
            onClick={handleAddActivity}
          >
            <div className="flex items-center justify-center">
              {loading && (
                <Icon
                  path={mdiLoading}
                  size={0.8}
                  className="animate-spin mr-3"
                />
              )}
              {t("addActivityPage.saveButton")}
            </div>
          </Button>
        </div>
      </PageLayout>
    </div>
  );
};
