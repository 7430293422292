import { mdiDeleteOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { Circle } from "lucide-react";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactSignatureCanvas from "react-signature-canvas";
import { checksHooks, checksHooks2 } from "../../../api";
import { ICheckPhotoViewModel } from "../../../application/viewModels/CheckPhotoViewModel";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../components/ui/dialog";
import { CheckState } from "../../../infrastructure/api/common/types";
import { getToken } from "../../../infrastructure/services/authentication/AuthenticationService";
import PhotoInput from "../../molecules/PhotoInput";
import { Button } from "../../ui/button";
import CheckStateCard, {
  AnswerBadge,
  AnswerContainer,
  SecondaryActionButton,
} from "./CheckStateCard";
import CheckPopupBase from "./index";

interface ICheckPopupPhotoProps {
  check: ICheckPhotoViewModel;
  onFinish: () => void;
  signed: boolean;
  sectionTitle: string;
  checklistUid: string;
  groupUid: string;
  checklistId: number;
}

function Image({
  uid,
  uri,
  checklistId,
  groupUid,
  checkUid,
  onDelete,
}: {
  checklistId: number;
  groupUid: string;
  uid: string;
  uri: string;
  checkUid: string;
  onDelete: ({ state }: { state: CheckState }) => void;
}) {
  const { mutate } = checksHooks.usePut(
    "/checklists/:checklistId/groups/:groupUid/checks/:checkUid/photo/:photoUid",
    {
      params: {
        checkUid: checkUid,
        photoUid: uid,
        checklistId: checklistId,
        groupUid: groupUid,
      },
    },
    {
      onSuccess: (data) => {
        // const key2 = inpectionsHooks.getKeyByPath("get", "/inspections/search");
        // queryClient.invalidateQueries(key2);
        onDelete({ state: data.state ?? 1 });
      },
    },
  );
  const { t } = useTranslation();

  function handleDelete() {
    if (confirm("Weer je zeker dat je deze foto wilt verwijderen?")) {
      mutate({} as never);
    }
  }
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
      <div className="relative">
        <div
          onClick={handleDelete}
          className="bg-surface-low cursor-pointer absolute right-[-12px] bottom-[-12px] w-fit aspect-square h-8 border-2 rounded-full flex items-center justify-center"
        >
          <Icon size={0.8} path={mdiDeleteOutline} />
        </div>
        <DialogTrigger>
          <div
            className="rounded-md border-2 w-20 h-20 bg-cover bg-no-repeat bg-center"
            key={uid}
            style={{
              backgroundImage: `url(${uri})`,
            }}
          />
        </DialogTrigger>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>
              {t("inspectionPage.checkPopup.photoCheck.photo")}
            </DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 py-4 w-full">
            <img src={uri} />
          </div>
          <DialogFooter></DialogFooter>
        </DialogContent>
      </div>
    </Dialog>
  );
}

export const CheckPopupPhoto = observer(
  ({
    check,
    onFinish,
    signed,
    sectionTitle,
    checklistUid,
    checklistId,
    groupUid,
  }: ICheckPopupPhotoProps): JSX.Element => {
    const { mutate } = checksHooks.usePost("/checklists/groups/checks/photo");
    const ref = useRef<ReactSignatureCanvas | null>(null);
    const containerRef = useRef<null | HTMLDivElement>(null);
    const [width, setWidth] = useState(0);
    const [photosLoading, setPhotosLoading] = useState(false);
    const [activityPhotos, setActivityPhotos] = useState<File[]>([]);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    // const { mutate: resetPhotoValue } = checksHooks.usePut(
    //   "/checklists/groups/checks/photo/value/reset",
    // );

    const handleSign = async () => {
      const formData = new FormData();

      formData.append("checklistId", `${checklistId}`);
      formData.append("groupUid", groupUid);
      formData.append("checkUid", check.uid);

      for (const photo of activityPhotos) {
        formData.append("photos", photo);
      }

      const token = await getToken();

      const result = await fetch(
        `${process.env.REACT_APP_API_URL}/checklists/groups/checks/photo`,
        {
          method: "POST",
          body: formData,
          headers: { authorization: `Bearer ${token.content}` },
        },
      );

      const data = await result.json();

      check.save((data as any).content.results, (data as any).content.state);
      onFinish();

      setActivityPhotos([]);

      // api.checks.post("/checklists/groups/checks/photo", {
      //   photos: activityPhotos,
      //   checklistUid,
      //   checkUid: check.uid,
      //   groupUid,
      // });

      // console.log({
      //   photos: activityPhotos,
      //   checklistUid,
      //   checkUid: check.uid,
      //   groupUid,
      // });

      // mutate(
      //   {
      //     photos: activityPhotos,
      //     checklistUid,
      //     checkUid: check.uid,
      //     groupUid,
      //   },
      //   {
      //     onSuccess: () => {
      //       check.sign();
      //       onFinish();
      //       // TODO: setting the file uri fixes the image not being visible when moving between checks and not refetching the actual checklist.
      //       // In the future we will use react-query for fetching the checklist and we can invalidate the query after setting the signature.
      //       // check.fileUri = response.url ?? "";
      //       // setSignature(response.url);
      //     },
      //   },
      // );
    };

    const handleUnsign = async () => {
      // resetSignatureValue(
      //   {
      //     checklistUid,
      //     checkUid: check.uid,
      //     groupUid,
      //   },
      //   {
      //     onSuccess: () => {
      //       check.reset();
      //       onFinish();
      //     },
      //   },
      // );
    };

    useEffect(() => {
      setWidth(containerRef.current?.clientWidth ?? 0);
    }, [ref, check.signed]);

    const { mutate: mutateInApplicable, isLoading: isLoadingInApplicable } =
      checksHooks2.usePost("/checklists/groups/checks/photo/in-applicable");
    const { mutate: mutateReset, isLoading: isLoadingReset } =
      checksHooks2.usePut("/checklists/groups/checks/photo/value/reset");

    function handleCheckIsInApplicable() {
      mutateInApplicable(
        {
          checklistId: checklistId,
          checkUid: check.uid,
          groupUid,
        },

        {
          onSuccess: async () => {
            // await check.accept();
            check.handleInApplicable();

            onFinish();
          },
        },
      );
    }

    const handleReset = async () => {
      mutateReset(
        {
          checklistId,
          checkUid: check.uid,
          groupUid,
        },
        {
          onSuccess: () => {
            check.reset();
            onFinish();
          },
        },
      );
    };

    return (
      <CheckPopupBase
        activities={check.activities}
        instructionId={check.instructionId}
        signed={signed}
        check={check}
      >
        <CheckStateCard
          signed={signed}
          check={check}
          sectionTitle={sectionTitle}
          isLoading={isLoadingInApplicable || isLoadingReset}
        >
          {(check.maxPhotoCount === 0 ||
            (check.photos?.length ?? 0) < (check.maxPhotoCount ?? 0)) &&
            check.state !== CheckState.InApplicable && (
              <div className={`mt-5 w-full gap-x-3 px-4 pb-4`}>
                <div className="w-full" ref={containerRef}>
                  <div className="mb-4 border p-2 rounded-md">
                    <span className="block text-sm font-bold">
                      {t("inspectionPage.checkPopup.photoCheck.requirements")}
                    </span>
                    <span className="block text-sm text-muted-foreground font-medium">
                      {t("inspectionPage.checkPopup.photoCheck.minLabel")}:{" "}
                      {check.minPhotoCount}
                    </span>
                    <span className="block text-sm text-muted-foreground font-medium">
                      {t("inspectionPage.checkPopup.photoCheck.maxLabel")}:{" "}
                      {check.maxPhotoCount === 0
                        ? t("inspectionPage.checkPopup.photoCheck.noLimit")
                        : check.maxPhotoCount}
                    </span>
                  </div>
                  <PhotoInput
                    previews={activityPhotos}
                    disabled={loading}
                    onPhotoLoading={() => setPhotosLoading(true)}
                    onPhotoFinished={() => setPhotosLoading(false)}
                    onChange={(photos: File[]) => setActivityPhotos(photos)}
                  />
                </div>
                <Button
                  className="mt-4 bg-[#cac4d0] rounded-full w-full text-base"
                  onClick={handleSign}
                  disabled={check.loading || (activityPhotos?.length ?? 0) < 1}
                >
                  {t("inspectionPage.checkPopup.photoCheck.saveButton")}
                </Button>
                {check.canBeInApplicable && check.photos?.length === 0 && (
                  <div className="pt-4">
                    <div className="border-t-2 border-dashed pt-4">
                      <SecondaryActionButton
                        onClick={handleCheckIsInApplicable}
                        disabled={check.loading}
                      >
                        {t("inspectionPage.checkPopup.inApplicableCheckButton")}
                      </SecondaryActionButton>
                    </div>
                  </div>
                )}
              </div>
            )}

          {check.state === CheckState.InApplicable && (
            <AnswerContainer>
              <AnswerBadge
                lastTouchedBy={check.lastTouchedBy}
                lastTouchedAt={check.lastTouchedAt}
                checklistAccepted={signed}
                onReset={handleReset}
              >
                <Circle className="w-5 h-5 mr-2" />
                {t("inspectionPage.checkPopup.inApplicableCheckButton")}
              </AnswerBadge>
            </AnswerContainer>
          )}

          {(check.photos?.length ?? 0) > 0 && (
            <div
              className={`flex flex-wrap gap-4 px-4 pb-6 ${
                (check.photos?.length ?? 0) >= (check.maxPhotoCount ?? 0)
                  ? "pt-4"
                  : ""
              }`}
            >
              {check.photos?.map((result) => (
                <Image
                  onDelete={(data) => check.removePhoto(result.uid, data.state)}
                  key={result.uid}
                  uid={result.uid}
                  uri={result.uri}
                  checklistId={checklistId}
                  groupUid={groupUid}
                  checkUid={check.uid}
                />
              ))}
            </div>
          )}
        </CheckStateCard>
      </CheckPopupBase>
    );
  },
);
