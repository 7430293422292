export const OperatorRoutePath = {
  SignInSilent: "sign-in-silent",
  InspectionsRealtime: "inspections-realtime",
  LoginCallback: "login-callback",
  Settings: "settings",
  ChecklistBase: "checklist",
  Checklist: "checklist/:serialNumber",
  CheckBase: "check",
  Check: "check/:checkUid",
  CheckActivityBase: "activity",
  CheckActivity: "activity/:type",
  CheckInstructions: "instructions",
  ProductChecklist: "product-checklist",
  ProductChecklistSelect: "product-checklist-select",
  AddProductChecklistToProjectPL: "product-checklist/:projectId/:productLabel",
  AddProductChecklistToProject: "product-checklist/:projectId",
  AddProjectChecklistToProject: "project-checklist/:projectId",
  StartProject: "start-project",
  AddChecklistToProject: "add-checklist-to-project/:projectId",
  Project: "project/:projectId",
};
