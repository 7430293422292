import { makeObservable, observable, runInAction } from "mobx";
import { CheckScanEntity } from "../../core/domain/entities/checklist/check/CheckScanEntity";
import { CheckState } from "../../infrastructure/api/common/types";
import { CheckBaseViewModel, ICheckBaseViewModel } from "./CheckBaseViewModel";

export interface ICheckScanViewModel extends ICheckBaseViewModel {
  barcode: string | null;

  scan: (data: string, state: number) => Promise<void>;
  reset: () => Promise<void>;
}

export class CheckScanViewModel
  extends CheckBaseViewModel
  implements ICheckScanViewModel
{
  public barcode: string | null = null;

  constructor(check: CheckScanEntity, checklistUid: string, groupUid: string) {
    super(check, checklistUid, groupUid);

    this.barcode = check.barcode;
    this.finished = this.isFinished();

    makeObservable(this, {
      barcode: observable,
    });
  }

  public async scan(data: string, state: number): Promise<void> {
    runInAction(() => {
      this.barcode = data;
      this.finished = this.isFinished();
      this.state = state;

      const x = { state: { selectedUser: { id: "" } } };

      const userId = //@ts-ignore
        window.Clerk.user.id === process.env.REACT_APP_ADMIN_USER_ID
          ? JSON.parse(
              localStorage.getItem("user-storage") || JSON.stringify(x),
            ).state.loggedInUser?.userId
          : //@ts-ignore
            window.Clerk.user.id;

      this.lastTouchedBy = {
        value: userId,
        type: 1,
      };
      this.lastTouchedAt = new Date();
    });
  }

  public async reset(): Promise<void> {
    runInAction(() => {
      this.barcode = null;
      this.finished = false;
      this.lastTouchedBy = null;
      this.state = CheckState.Empty;
    });
  }
}
