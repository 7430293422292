import { AlignLeft, Circle } from "lucide-react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { checksHooks, checksHooks2 } from "../../../api";
import { ICheckSingleOptionViewModel } from "../../../application/viewModels/CheckSingleOptionViewModel";
import { CheckState } from "../../../infrastructure/api/common/types";
import { Checkbox } from "../../ui/checkbox";
import CheckStateCard, {
  ActionContainer,
  AnswerBadge,
  AnswerContainer,
  SecondaryActionButton,
} from "./CheckStateCard";
import CheckPopupBase from "./index";

interface ICheckPopupYesNoProps {
  check: ICheckSingleOptionViewModel;
  onFinish: () => void;
  signed: boolean;
  sectionTitle: string;
  checklistId: number;
  groupUid: string;
}

export const CheckPopupSingleOption = observer(
  ({
    check,
    onFinish,
    signed,
    sectionTitle,
    checklistId,
    groupUid,
  }: ICheckPopupYesNoProps): JSX.Element => {
    const { mutate, isLoading: isLoadingValue } = checksHooks.usePut(
      "/checklists/groups/checks/single-choice-list/value",
    );
    const { mutate: mutateReset, isLoading: isLoadingReset } =
      checksHooks.usePut(
        "/checklists/groups/checks/single-choice-list/value/reset",
      );
    const { mutate: mutateInApplicable, isLoading: isLoadingInApplicable } =
      checksHooks2.usePost(
        "/checklists/groups/checks/single-choice-list/in-applicable",
      );

    const handleAnswer = async (option: string) => {
      mutate(
        {
          //TODO-translations: update option to be the selected item key.
          selectedItemKey: option,
          checklistId,
          checkUid: check.uid,
          groupUid,
        },
        {
          onSuccess: () => {
            check.answer(option);
            onFinish();
          },
        },
      );
    };

    const handleReset = async () => {
      mutateReset(
        {
          checklistId,
          checkUid: check.uid,
          groupUid,
        },
        {
          onSuccess: () => {
            check.reset();
            onFinish();
          },
        },
      );
    };

    const { t } = useTranslation();

    function handleCheckIsInApplicable() {
      mutateInApplicable(
        {
          checklistId: checklistId,
          checkUid: check.uid,
          groupUid,
        },
        {
          onSuccess: async () => {
            check.handleInApplicable();

            onFinish();
          },
        },
      );
    }

    return (
      <CheckPopupBase
        activities={check.activities}
        instructionId={check.instructionId}
        signed={signed}
        check={check}
      >
        <CheckStateCard
          signed={signed}
          check={check}
          sectionTitle={sectionTitle}
          isLoading={isLoadingValue || isLoadingInApplicable || isLoadingReset}
        >
          {!signed &&
            check.selectedChoice === null &&
            check.state !== CheckState.InApplicable && (
              <div className="w-full">
                <ActionContainer>
                  <div className="space-y-2 w-full">
                    {/* TODO-translations: fix this... */}
                    {Object.keys(check.choices || {})?.map((key, idx) => (
                      <label
                        key={key}
                        htmlFor={key}
                        className="block cursor-pointer hover:bg-surface-high duration-100 ease-out rounded-md border-2"
                      >
                        <span className="flex justify-between py-2 px-3 w-full items-center text-sm font-medium text-muted-foreground">
                          {check && check.choices && check.choices[key]}
                          <Checkbox
                            id={key}
                            onCheckedChange={(incoming) => {
                              handleAnswer(key);
                            }}
                          />
                        </span>
                      </label>
                    ))}
                  </div>
                </ActionContainer>
                {check.canBeInApplicable && (
                  <div className="px-4 pb-4">
                    <div className="border-t-2 border-dashed pt-4">
                      <SecondaryActionButton
                        onClick={handleCheckIsInApplicable}
                        disabled={check.loading}
                      >
                        {t("inspectionPage.checkPopup.inApplicableCheckButton")}
                      </SecondaryActionButton>
                    </div>
                  </div>
                )}
              </div>
            )}

          {check.selectedChoice !== null &&
            check.state !== CheckState.InApplicable && (
              <AnswerContainer>
                <AnswerBadge
                  lastTouchedBy={check.lastTouchedBy}
                  lastTouchedAt={check.lastTouchedAt}
                  checklistAccepted={signed}
                  onReset={handleReset}
                >
                  <AlignLeft className="w-5 h-5 mr-2" />
                  {check.selectedChoice}
                </AnswerBadge>
              </AnswerContainer>
            )}

          {check.state === CheckState.InApplicable && (
            <AnswerContainer>
              <AnswerBadge
                lastTouchedBy={check.lastTouchedBy}
                lastTouchedAt={check.lastTouchedAt}
                checklistAccepted={signed}
                onReset={handleReset}
              >
                <Circle className="w-5 h-5 mr-2" />
                {t("inspectionPage.checkPopup.inApplicableCheckButton")}
              </AnswerBadge>
            </AnswerContainer>
          )}
        </CheckStateCard>
      </CheckPopupBase>
    );
  },
);
