import {
  AlertCircle,
  BadgeCheck,
  CheckCircle2,
  CircleDashed,
  Clock3,
  Info,
  Loader,
  Loader2,
  Plus,
  RotateCw,
} from "lucide-react";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";
import {
  checklistsHooks,
  projectsHooks,
  templateChecklistsHooks,
} from "../../../api";
import { schemas } from "../../../api/schema";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../components/ui/dialog";
import { useProjectStore } from "../../../stores/project-store";
import { SettingsContext } from "../../context/SettingsManager";
import RelativeTimestamp from "../../molecules/RelativeTimestamp";
import LayoutV2 from "../../molecules/layout-v2/layout-v2";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../ui/accordion";
import { Badge } from "../../ui/badge";
import { Input } from "../../ui/input";

export const ProjectPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { projectId } = useParams<{ projectId: string }>();
  const projectIdNumber = Number(projectId);
  const { activeLocationIds } = useContext(SettingsContext);

  const { data, isLoading } = projectsHooks.useGet("/projects/:projectId", {
    params: { projectId: projectIdNumber },
  });

  const { data: checklistsMetaData } = checklistsHooks.useGet(
    "/checklists/uids",
    {
      queries: {
        uids: data?.checklists?.map((c) => c.checklistId ?? "") ?? [],
      },
    },
    {
      enabled: data?.checklists && data?.checklists?.length > 0 ? true : false,
    },
  );

  const {
    data: templateChecklistsMetaData,
    isLoading: templateChecklistsMetaDataIsLoading,
  } = templateChecklistsHooks.useGet(
    "/templates/checklists/ids",
    {
      queries: {
        ids: data?.checklistRules?.map((c) => c.templateChecklistId ?? 0) ?? [
          0,
        ],
      },
    },
    {
      enabled:
        data?.checklistRules && data?.checklistRules?.length > 0 ? true : false,
    },
  );

  //"inspections" | "suggestions"
  const [activeTab, setActiveTab] = useState<string>(
    process.env.REACT_APP_PROJECT_PAGE_DEFAULT_TAB ?? "suggestions",
  );

  const { updateSelectedProject } = useProjectStore();

  useEffect(() => {
    if (data) {
      updateSelectedProject(data);
      if (data.checklistRules && data.checklistRules?.length < 1) {
        setActiveTab("inspections");
      }
    }
  }, [data]);

  const checklistCount = (checklistsMetaData?.results ?? []).length;

  const totalPercentage =
    (checklistsMetaData?.results ?? [])
      .map((c) => c.percentage ?? 0)
      .reduce((a, b) => a + b, 0) / checklistCount;

  const acceptedChecklistCount = (checklistsMetaData?.results ?? []).filter(
    (c) => c.state === 1,
  ).length;

  return (
    <LayoutV2>
      <div className="container1 sm:pl-0 pl-2 pr-2 pt-2">
        <div className="mb-2 bg-surface-container rounded-xl overflow-hidden">
          <div className="w-full flex justify-between items-start">
            <div className="px-6 1mb-4 flex items-center">
              <span className="text-xl font-medium py-4 block whitespace-nowrap">
                <span className="text-muted-foreground">
                  {t("projectPage.projects")} /
                </span>{" "}
                {data?.code}
              </span>
            </div>
            <div className="p-2 items-center hidden sm:flex">
              <div className="flex gap-x-3 px-4 bg-surface-low dark:bg-surface-high rounded-lg py-2">
                <div className="flex flex-col">
                  <div className="flex items-center">
                    <Clock3 className="w-3.5 h-3.5 mr-1 text-muted-foreground/60" />
                    <span className="text-sm text-muted-foreground/60 font-medium">
                      {t("projectPage.created")}{" "}
                    </span>
                  </div>
                  <span className="ml-1 text-sm font-medium text-muted-foreground">
                    {data && data.createdAt && (
                      <RelativeTimestamp timestamp={new Date(data.createdAt)} />
                    )}
                  </span>
                </div>
                <div className="flex flex-col">
                  <div className="flex items-center">
                    <RotateCw className="w-3.5 h-3.5 mr-1 text-muted-foreground/60" />
                    <span className="text-sm text-muted-foreground/60 font-medium">
                      {t("projectPage.lastUpdated")}{" "}
                    </span>
                  </div>
                  <span className="ml-1 text-sm font-medium text-muted-foreground">
                    {data && data.updatedAt && (
                      <RelativeTimestamp timestamp={new Date(data.updatedAt)} />
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>

          {data?.metadata && data?.metadata?.length > 0 && (
            <Accordion type="multiple" className="">
              <AccordionItem
                value="metadata-accordion-item"
                className="border-b-0"
              >
                <AccordionTrigger className="px-6 pb-4 pt-2 hover:no-underline 1text-muted-foreground">
                  <div className="flex items-center">
                    <Info className="w-4 h-4 mr-2 text-muted-foreground/60" />
                    <span className="text-sm text-muted-foreground">
                      {t("projectPage.projectInformation")}
                    </span>
                  </div>
                </AccordionTrigger>
                <AccordionContent className="border-b-0">
                  <div className="px-6 pb-4 space-y-2 sticky top-0 bg-surface-container z-20">
                    {data?.metadata?.map((m) => (
                      <div
                        key={m.key}
                        className="flex justify-between items-center w-full"
                      >
                        <span className="block text-muted-foreground text-sm">
                          {m.key}
                        </span>
                        {m.value?.includes("https://") ? (
                          <a
                            className="block text-sm cursor-pointer text-blue-400"
                            href={m.value}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {m.value}
                          </a>
                        ) : (
                          <span className="block text-sm">{m.value}</span>
                        )}
                      </div>
                    ))}
                  </div>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          )}
        </div>
        <div
          className={`${
            data?.metadata && data?.metadata?.length > 0
              ? "md:h-[calc(100vh-139px)] h-[calc(100vh-192px)]"
              : "md:h-[calc(100vh-95px)] h-[calc(100vh-143px)]"
          } hide-scrollbar bg-surface-container rounded-xl overflow-auto`}
        >
          <div className="w-full flex justify-between mb-4 pt-6">
            <div className="px-6 flex gap-2">
              <Badge
                className={`h-fit text-sm py-1.5 px-3.5 cursor-pointer rounded-lg ${
                  activeTab === "suggestions"
                    ? ""
                    : "bg-surface-low dark:bg-surface-high"
                }`}
                onClick={() => setActiveTab("suggestions")}
                variant={activeTab === "suggestions" ? "default" : "secondary"}
              >
                {t("projectPage.activities")}{" "}
                <span className="ml-2 px-1.5 1border rounded-full text-xs text-white bg-red-400">
                  {data?.checklistRules?.reduce(
                    (a, b) => a + (b.minOccurrence ?? 0),
                    0,
                  )}
                </span>
              </Badge>
              <Badge
                className={`h-fit text-sm py-1.5 px-3.5 cursor-pointer rounded-lg ${
                  activeTab === "inspections"
                    ? ""
                    : "bg-surface-low dark:bg-surface-high"
                }`}
                onClick={() => setActiveTab("inspections")}
                variant={activeTab === "inspections" ? "default" : "secondary"}
              >
                {t("projectPage.allInspections")}
              </Badge>
            </div>
          </div>

          {activeTab === "inspections" && (
            <>
              {data?.checklists && data.checklists.length > 0 && (
                <div className="px-6 flex flex-col mb-6">
                  <div className="flex justify-between">
                    <span className="text-sm mb-2 text-muted-foreground font-medium">
                      {t("projectPage.projectProgress")}{" "}
                      {totalPercentage.toFixed()}%
                    </span>
                    <span className="text-sm mb-2 text-muted-foreground font-medium">
                      {t("projectPage.signed")} {acceptedChecklistCount}/
                      {checklistCount}
                    </span>
                  </div>
                  <div className="w-full h-4 rounded-full block bg-muted relative">
                    <div
                      className={`h-4 rounded-full block absolute left-0 top-0 1bg-emerald-400 bg-[#cac4d0]`}
                      style={{ width: `${totalPercentage}%` }}
                    ></div>
                  </div>
                </div>
              )}

              <div className="px-4 flex flex-col pb-4 space-y-2">
                <div className="flex items-center mb-1">
                  <span className="text-primary pl-2 font-semibold">
                    {t("projectPage.inspections")}
                  </span>
                  <Badge className="ml-2" variant={"secondary"}>
                    {data?.checklists?.length}
                  </Badge>
                </div>
                {checklistsMetaData?.results?.map((c) => (
                  <Checklist {...c} projectId={projectIdNumber} key={c.id} />
                ))}
              </div>
            </>
          )}

          {data?.checklists &&
            activeTab === "inspections" &&
            data?.checklists.length < 1 && (
              <div className="px-6 py-2">
                <div className="flex-col gap-y-3 border flex items-center justify-center px-6 h-36 rounded-lg">
                  <span className="text-sm text-muted-foreground/60 font-medium">
                    {t("projectPage.noChecklistsForThisProject")}
                  </span>
                </div>
              </div>
            )}

          {data?.checklistRules &&
            activeTab === "suggestions" &&
            data?.checklistRules.length < 1 && (
              <div className="px-6 py-2">
                <div className="border flex items-center justify-center px-6 h-36 rounded-lg">
                  <span className="text-sm text-muted-foreground/60 font-medium">
                    {t("projectPage.noSuggestionsForThisProject")}
                  </span>
                </div>
              </div>
            )}

          {!templateChecklistsMetaDataIsLoading &&
            activeTab === "suggestions" && (
              <Accordion
                type="multiple"
                defaultValue={templateChecklistsMetaData?.results?.map(
                  (t) => `${t.id}`,
                )}
              >
                <div className="px-4 flex flex-col pb-4">
                  <div className="flex items-center mb-4">
                    <span className="pl-2 text-primary font-semibold">
                      Checklists
                    </span>
                    <Badge
                      className="ml-2 dark:bg-surface-high bg-surface-low"
                      variant={"secondary"}
                    >
                      {templateChecklistsMetaData?.results?.length}
                    </Badge>
                  </div>
                  {checklistsMetaData?.results?.some((c) =>
                    c.title?.toLowerCase().includes("klacht"),
                  ) && (
                    <div className="w-full mb-3 flex items-center text-sm font-medium rounded-md h-12 px-3 bg-red-500 text-white">
                      <AlertCircle className="mr-1.5 w-4 h-4" />
                      <span>Let op, dit project bevat een klacht!</span>
                    </div>
                  )}
                  <div className="space-y-3">
                    {templateChecklistsMetaData?.results
                      ?.sort((a, b) => {
                        const order =
                          data?.checklistRules?.map(
                            (r) => r.templateChecklistId,
                          ) ?? [];

                        return order.indexOf(a.id) - order.indexOf(b.id);
                      })
                      ?.filter((c) => {
                        if (activeLocationIds.length) {
                          return activeLocationIds.find((activeLocationId) =>
                            c.locationIds?.includes(activeLocationId),
                          );
                        } else {
                          return true;
                        }
                      })
                      .map((checklistTemplate) => (
                        <TemplateChecklist
                          projectCode={data?.code ?? ""}
                          projectId={projectIdNumber}
                          checklists={
                            checklistsMetaData?.results?.filter(
                              (checklistMetaData) =>
                                data?.checklists
                                  ?.filter(
                                    (checklistMetaData) =>
                                      checklistMetaData.templateChecklistId ===
                                      checklistTemplate.id,
                                  )
                                  .map((x) => x.checklistId)
                                  .includes(checklistMetaData.uid),
                            ) ?? []
                          }
                          templateChecklist={checklistTemplate}
                          checklistRule={data?.checklistRules?.find(
                            (checklistRule) =>
                              checklistRule.templateChecklistId ===
                              checklistTemplate.id,
                          )}
                          key={checklistTemplate.id}
                        />
                      ))}
                  </div>
                </div>
              </Accordion>
            )}
        </div>
      </div>
    </LayoutV2>
  );
};

function Checklist({
  projectId,
  id,
  uid,
  serialNumber,
  title,
  percentage,
  state,
}: z.infer<
  typeof schemas.Checklab_WebApi_Features_Inspections_Dtos_ChecklistSearchDto
> & { projectId: number }) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="">
      <div
        onClick={() => {
          navigate(`/checklist/${id}?projectId=${projectId}`);
        }}
        className={`w-full p-2 hover:outline outline-primary/10 duration-75 ease-out z-10 dark:bg-surface-highest 1border bg-surface-lowest first:mt-0 1border 1border-card rounded-lg h-fit flex items-center 1bg-card opacity-100 cursor-pointer`}
      >
        <div>
          <div className="dark:bg-surface-high bg-surface-low 1border mr-2 h-12 w-12 flex justify-center items-center rounded-lg">
            <div className="pt-0.5">
              <span className="flex items-center text-sm text-muted-foreground font-medium">
                {state === 1 && (
                  <div className="">
                    <CheckCircle2 className="h-4 w-4 dark:text-emerald-400 text-emerald-500" />
                  </div>
                )}
                {state === 2 && (
                  <CheckCircle2 className="h-4 w-4 1text-muted-foreground dark:text-emerald-400 text-green-500" />
                )}
                {state === 3 && (
                  <span className="relative flex items-center justify-center text-yellow-500">
                    <div className="border-[3px] dark:border-surface-high border-surface-low w-[14px] h-[14px] absolute bg-yellow-500 rounded-full" />
                    <Loader2 className="animate-[spin_3s_linear_infinite] z-10 w-5 h-5 text-yellow-500/50" />
                  </span>
                )}
              </span>
            </div>
          </div>
        </div>
        <div>
          <span className="leading-none block text-sm font-semibold text-card-foreground">
            {serialNumber}{" "}
          </span>
          <span className="leading-none mt-1.5 block text-xs text-muted-foreground/60 font-normal">
            {title}
          </span>
        </div>
        <div className="flex flex-col h-full items-center w-32 ml-auto">
          <span className="ml-auto mb-1.5 text-xs text-[#cac4d0] font-medium pt-0.5">
            {percentage?.toFixed()}%
          </span>

          {state !== 1 && (
            <div className="w-full h-2 rounded-full block dark:bg-surface-high bg-surface-low relative">
              <div
                className={`h-2 rounded-full block absolute right-0 top-0 1bg-emerald-400 bg-[#cac4d0]`}
                style={{ width: `${percentage?.toFixed()}%` }}
              ></div>
            </div>
          )}

          {state === 1 && (
            <Badge className="ml-auto flex py-0.5 pr-1 1dark:bg-emerald-400 1bg-emerald-500 bg-[#cac4d0] pl-1.5 dark:text-black text-white">
              {t("projectPage.signed")}
              <BadgeCheck className="w-4 h-4 ml-1" />
            </Badge>
          )}
        </div>
      </div>
    </div>
  );
}

function TemplateChecklist({
  projectCode,
  projectId,
  templateChecklist,
  checklistRule,
  checklists,
}: {
  projectCode: string;
  projectId: number;
  templateChecklist: z.infer<
    typeof schemas.Checklab_WebApi_Features_Templates_Dtos_TemplateChecklistSearchDto
  >;
  checklistRule?: z.infer<
    typeof schemas.Checklab_WebApi_Features_Projects_Dtos_ProjectChecklistRuleDto
  >;
  checklists: z.infer<
    typeof schemas.Checklab_WebApi_Features_Inspections_Dtos_ChecklistSearchDto
  >[];
}) {
  const navigate = useNavigate();

  const minOccurrence = checklistRule?.minOccurrence ?? 0;
  const maxOccurrence = checklistRule?.maxOccurrence ?? 0;

  const { mutate: postChecklist, isLoading } =
    checklistsHooks.usePost("/checklists");

  function handleChecklistTemplateClick() {
    if (templateChecklist.specialFunctions?.includes("ask_for_input_text")) {
      setDialogOpen(true);

      return;
    }

    handleSubmit();
  }

  function handleSubmit(code?: string) {
    postChecklist(
      {
        serialNumber: code ? code : projectCode,
        productLabel: "plabel",
        templateChecklistId: templateChecklist.id,
        projectId: projectId,
      },
      {
        onSuccess: (data) => {
          navigate(`/checklist/${data.checklistId}`);
        },
      },
    );
  }

  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [codeInput, setCodeInput] = useState<string | undefined>("");

  return (
    <>
      <Dialog
        open={dialogOpen}
        onOpenChange={setDialogOpen}
        // onOpenChange={(incoming) => {

        // }}
      >
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>
              {templateChecklist.specialFunctions?.split('"')[1]}
            </DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 py-4 w-full grid-cols-1">
            <form
              onSubmit={(e) => {
                e.preventDefault();

                if (codeInput === undefined) {
                  return;
                }

                handleSubmit(codeInput);

                setCodeInput(undefined);
                setDialogOpen(false);
              }}
            >
              <Input
                autoFocus
                className="w-full"
                // tabIndex={-1}
                // placeholder={"Enter value"}
                type="text"
                value={codeInput}
                onChange={(e) => setCodeInput(e.target.value)}
                step={"any"}
              />
              <div>
                <button
                  disabled={!codeInput}
                  className={`mt-3 text-sm border h-10 text-primary rounded-md w-full ${
                    !codeInput ? "opacity-50" : ""
                  }`}
                  type="submit"
                >
                  Checklist starten
                </button>
              </div>
            </form>
          </div>
          {/* <DialogFooter>
            <Button
              disabled={codeInput.length < 1}
              onClick={() => handleSubmit(codeInput)}
            >
              Checklist starten
            </Button>
          </DialogFooter> */}
        </DialogContent>
      </Dialog>

      {isLoading && (
        <div className="fixed top-0 left-0 w-full h-full bg-black/30 z-40 flex items-center justify-center animate-in">
          <div className="flex items-center flex-col gap-y-2">
            <Loader className="w-6 h-6 text-white animate-spin" />
            <span className="text-sm font-medium">
              Checklist wordt gestart...
            </span>
          </div>
        </div>
      )}
      <AccordionItem value={`${templateChecklist.id}`} className="border-b-0">
        <AccordionTrigger
          className={`[&[data-state=open]]:rounded-b-none [&[data-state=open]]:1border 1border hover:no-underline w-full 1border rounded-lg px-4 py-4 h-fit flex items-start bg-surface-low dark:bg-surface-high opacity-100 cursor-pointer`}
        >
          <div className="">
            <span className="flex items-center text-sm text-muted-foreground font-medium">
              {checklistRule &&
                checklists.filter((c) => c.state === 1).length <
                  (checklistRule.minOccurrence ?? 0) && (
                  <CircleDashed className="animate-[spin_7s_linear_infinite] w-4 h-4 text-muted-foreground mr-2" />
                )}
              {checklistRule &&
                checklists.filter((c) => c.state === 1).length >=
                  (checklistRule.minOccurrence ?? 0) && (
                  <CheckCircle2 className="h-4 w-4 dark:text-emerald-400 text-emerald-500 mr-2" />
                )}
            </span>
          </div>
          <div className="w-full text-left">
            <span className="leading-none block text-base font-semibold text-card-foreground">
              {templateChecklist.title}
            </span>
            {checklistRule && (
              <span className="leading-none mt-1.5 block text-sm text-muted-foreground/60 font-normal">
                {checklists.filter((c) => c.state === 1).length}/
                {checklistRule.minOccurrence}{" "}
                {t("projectPage.finishedInspections")}
              </span>
            )}
          </div>
        </AccordionTrigger>
        <AccordionContent className="1mb-4 1mt-[-22px] dark:bg-surface-high bg-surface-low rounded-b-lg">
          <div className="flex flex-col z-10 mt-1 1border-2 border-t-0 rounded-t-none border-surface-low dark:border-surface-high rounded-lg pb-3 space-y-3 px-3 1mt-2">
            {checklists.map((checklist) => (
              <Checklist
                uid={checklist.uid}
                key={checklist.id}
                projectId={projectId}
                id={checklist.id}
                serialNumber={checklist.serialNumber}
                title={checklist.title}
                percentage={checklist.percentage}
                state={checklist.state}
              />
            ))}
            {Array.from(
              Array(
                (checklistRule?.minOccurrence ?? 0) - checklists.length < 0
                  ? 0
                  : (checklistRule?.minOccurrence ?? 0) - checklists.length,
              ).keys(),
            ).map((_, idx) => (
              <div
                onClick={() => {
                  handleChecklistTemplateClick();
                }}
                key={idx}
                className={`w-full first:mt-0 mt-2 hover:outline outline-primary/10 duration-75 ease-out hover:border-transparent border border-dashed 1bg-surface-container rounded-lg px-2 py-2 h-fit flex items-center opacity-100 cursor-pointer`}
              >
                <div className="dark:bg-surface-highest bg-surface-lowest mr-2 flex items-center justify-center rounded-lg h-12 w-12">
                  <span className="flex items-center text-sm text-muted-foreground font-medium">
                    <Plus className="h-4 w-4 text-muted-foreground 1text-blue-400" />
                  </span>
                </div>
                <div>
                  <span className="leading-none block text-sm font-medium text-muted-foreground">
                    {t("projectPage.startChecklist")}
                  </span>
                  <span className="leading-none mt-1.5 block text-xs font-normal text-red-300">
                    {t("projectPage.required")}
                  </span>
                </div>
              </div>
            ))}

            {((maxOccurrence === 0 && minOccurrence === 0) ||
              (checklists.length >= minOccurrence &&
                minOccurrence !== maxOccurrence)) && (
              <div
                onClick={() => {
                  handleChecklistTemplateClick();
                }}
                className={`w-full first:mt-0 mt-2 hover:outline outline-primary/10 duration-75 ease-out hover:border-transparent border border-dashed 1bg-surface-container rounded-lg px-2 py-2 h-fit flex items-center opacity-100 cursor-pointer`}
              >
                <div className="dark:bg-surface-highest bg-surface-lowest mr-2 flex items-center justify-center rounded-lg h-12 w-12">
                  <span className="flex items-center text-sm text-muted-foreground font-medium">
                    <Plus className="h-4 w-4 text-muted-foreground 1text-blue-400" />
                  </span>
                </div>
                <div>
                  <span className="text-sm leading-none font-medium text-muted-foreground flex items-center">
                    {t("projectPage.startChecklist")}{" "}
                  </span>
                  <span className="leading-none mt-1.5 block text-xs text-muted-foreground/60 font-normal">
                    {t("projectPage.optional")}
                  </span>
                </div>
              </div>
            )}
          </div>
        </AccordionContent>
      </AccordionItem>
    </>
  );
}
